.portal-tooltip {
  position: fixed;
  z-index: 99999999;

  &.top::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: var(--black-0) transparent transparent transparent;
  }

  &.bottom::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent var(--black-0) transparent;
  }

  &.left::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent transparent var(--black-0);
  }

  &.right::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent var(--black-0) transparent transparent;
  }
}

.TooltipPortal-text {
  text-align: center;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: var(--fSize13);

  &.light {
    background-color: var(--white-0);
    color: var(--gray-0);
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  &.dark {
    background-color: var(--black-0);
    color: var(--white-0);
  }
}
