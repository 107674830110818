.JobOverview {
  padding: 32px;
  gap: 24px;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr 384px;

  & > *:first-child {
    border-right: 1px solid var(--gray-100);
    padding-right: 32px;
  }

  &-section {
    margin-bottom: 50px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }

  &-details {
    margin-bottom: 24px;

    .ContactBlock {
      box-shadow: var(--bottom-line);
    }

    &.isContent {
      padding-bottom: 24px;
      border-bottom: 1px solid var(--gray-100);

      .TextComponent.uppercase {
        margin-bottom: 4px;
      }
    }

    .notes {
      margin-top: 24px;
    }
  }
}
