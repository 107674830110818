.ManageTagsList {
  max-height: calc(100vh - 160px);
  overflow: hidden;
  display: grid;
  grid-auto-rows: auto 1fr auto;

  &-title {
    font-family: var(--helvetica700);
    text-align: center;
    text-transform: uppercase;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white-40);
    box-shadow: var(--bottom-line);
  }

  &-list {
    padding: 1.14rem 1.71rem 0;
    overflow-y: auto;

    &-item {
      display: flex;
      justify-content: space-between;
      text-align: left;
      padding: 0.71rem 0;
      width: 100%;
      &:not(:nth-last-of-type(1)) {
        box-shadow: var(--bottom-line);
      }

      &:hover {
        .ManageTagsList-list-actions {
          opacity: 1;
        }
      }
    }

    &-actions {
      transition: var(--transition);
      margin-left: 0.57rem;
      padding-right: 0.37rem;
      opacity: 0;
      display: flex;

      .Button {
        &:nth-of-type(1) {
          margin-right: 0.75rem;
        }

        color: var(--gray-10);

        &:hover {
          color: var(--black-10);
        }
      }

      &.icon-IconEdit,
      &.icon-IconClose {
        font-size: var(--fSize12);
      }
    }
  }

  &-footer {
    border-top: 1px solid var(--gray-40);
    padding: 1.14rem 1.71rem;

    .Button {
      width: 100%;
    }
  }

  &-modal {
    .container {
      max-width: 334px;
    }

    .content {
      padding: 0;
    }
  }
}
