.JobTasksHeader {
  min-height: var(--heightJobDetailsInnerPageHeader);
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: var(--white-0);
  box-shadow: var(--bottom-line);
  padding: 0 var(--32px);

  display: flex;
  align-items: center;

  & > .title {
    flex-grow: 1;
  }

  & > .addGroupBtn {
    margin: 0 var(--8px);

    &:hover,
    &:focus {
      & > .icon {
        color: var(--white-0);

        &::before {
          color: var(--white-0);
        }
      }
    }
  }

  &-dropdown {
    .DropdownMenu-body {
      & > .Button {
        width: 100%;
        justify-content: flex-start;

        &:hover {
          background-color: var(--gray-40);
        }
      }
    }
  }
}
