.CallSheetSenderForm {
  &-form {
    display: flex;
    flex-wrap: wrap;
    gap: 0.57rem;
  }

  &-label {
    font-size: var(--fSize13);
    font-weight: 700;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    margin-bottom: 0.57rem;
  }

  &-team {
    width: 50%;
  }

  &-method {
    width: 48%;
  }

  &-message {
    width: 100%;
    margin-top: 2.28rem;
  }

  &-date {
    display: flex;
    align-items: flex-end;
    width: 100%;
    gap: 0.57rem;
    position: relative;
    & > div {
      width: 50%;
    }
    .TimeField {
      margin-bottom: 1.14rem;
      padding-left: 0.85rem;
    }
    .TimeField .Select {
      width: unset;
    }
    .TimeField .InputField {
      width: unset;
    }
    .TimeField-hours .input {
      max-width: 1.37rem;
    }
    .Select-input {
      width: unset;
    }
    .TimeField-minutes {
      max-width: 1.32rem;
    }
  }

  &-schedule {
    width: 100%;
    margin-top: 2.28rem;
    display: flex;
    align-items: center;
    gap: 1.17rem;
    h5 {
      margin-bottom: 0;
    }
  }

  .tox.tox-tinymce {
    max-height: 270px;
    border-radius: 3px;
    border: 1px solid var(--gray-30);
  }
}
