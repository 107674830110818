@import "src/assets/styles/placeholders/label";

.LocationDynamicInput {
  &-labelRow {
    display: flex;
    gap: 0 8px;
  }
  &-label {
    width: 50%;
    @extend %label;
  }
}
