.AvatarGroup {
  position: relative;
  display: flex;
  align-items: center;

  &-item {
    width: 28px;
    height: 28px;
    font-size: var(--fSize12);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:first-child) {
      margin-left: -12px;
    }

    &-withImg {
      border: 1px solid var(--gray-40);
    }

    &-withLetters {
      border: 1px solid var(--white-0);
      background-color: var(--gray-40);
      color: var(--gray-10);
      text-transform: uppercase;
      font-weight: 700;
    }

    &-counting {
      border: 1px solid var(--gray-40);
      background-color: var(--white-0);
      color: var(--black-10);
      font-size: var(--fSize10);
    }
  }
}