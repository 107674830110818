.CreateNewCallSheet {
  & > .TextComponent {
    margin-bottom: 8px;
  }

  &-twoColumnSection {
    display: flex;
    gap: 8px;

    & > * {
      flex: 1 0 45%;
    }
  }

  &-locations {
    & > .DynamicInputsGroup {
      gap: var(--8px);

      .InputField {
        flex: 1 0 calc(50% - var(--8px) / 2);
      }

      .Button.sm {
        padding: 0.7rem 0.1rem;
      }
    }
  }

  .InputField:last-child {
    margin-bottom: 0;
  }
}
