@import "src/assets/styles/partials/tag";
@import "src/assets/styles/placeholders/label";
@import "src/assets/styles/placeholders/input";

.TagsField {
  margin-bottom: 1.14rem;
  width: 100%;

  &.error {
    .label {
      color: var(--red-0);
    }
    .TagsField-select__control {
      border-color: var(--red-0);
      &:hover {
        border-color: var(--red-0);
      }
    }
  }
  &-error-message {
    color: var(--red-0);
    padding-top: 0.25rem;
    font-size: var(--fSize13);
  }

  &-label {
    @extend %label;
  }

  .TagsField-select__indicators {
    display: none;
  }
  .TagsField-select__control {
    @extend %input;
    padding: 0;
    box-shadow: none;
    text-align: left;
    &:hover {
      border: 1px solid var(--gray-20);
    }
  }

  .TagsField-select__multi-value__label {
    @extend .tag;
    @extend .tag__md;
    border-right: none;
    padding-right: 0;
  }
  .TagsField-select__multi-value__remove {
    @extend .tag;
    border-left: none;
    padding-left: 0;
    &:hover {
      background-color: var(--white-10);
      color: inherit;
    }
  }
  .TagsField-select__option {
    cursor: pointer;
    text-align: left;
    z-index: 12;
    &--is-focused {
      background: var(--gray-40);
    }
    &:active {
      background-color: var(--gray-40);
    }
  }

  .TagsField-select__menu-list {
    z-index: 4;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 250px;
  }
}
