.BudgetTypeCostsList {
  padding-bottom: 1.14rem;
  &-title {
    padding: 0.71rem 0.57rem;
  }

  .ButtonDelete {
    color: var(--gray-20);
    margin-top: 2.71rem;
  }
}
