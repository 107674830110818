.EstimateConfirmation {
  &-text {
    margin-bottom: 1.14rem;
    font-size: var(--fSize13);
  }
  &-signBox {
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;
    border: 1px solid var(--gray-10);
    & > div {
      padding: 0.57rem;
    }
    .sign {
      flex: 1 0 100%;
      &-title {
        margin-bottom: 2.85rem;
      }
    }
    .name,
    .date {
      padding-bottom: 2rem;
      border-top: 1px solid var(--gray-10);
    }
    .name {
      flex: 1;
      border-right: 1px solid var(--gray-10);
    }
    .date {
      flex: 1;
    }
  }
}
