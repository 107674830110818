.TooltipPortal {
  position: fixed;
  inset: 0;
  height: 0;
  width: 0;

  &-body {
    position: fixed;
    width: fit-content;
    height: fit-content;
    max-width: calc(100% - 32px);
    max-height: calc(100% - 32px);
    outline: 0;
    border-radius: 3px;
    opacity: 1;
    padding: 0.5rem;
    pointer-events: none;
    & > * {
      pointer-events: none;
      &:after,
      &:before {
        pointer-events: none;
      }
    }

    .text {
      max-width: 189px;
      text-align: center;
      padding: 4px 12px;
      border-radius: 4px;
      font-size: var(--fSize13);
      font-weight: 500;
      background-color: var(--black-0);
      color: var(--white-0);
    }
  }

  &-wrappedElement {
    display: block;
    position: relative;
    z-index: 2;
    & > * {
      pointer-events: none;
      &:after,
      &:before {
        pointer-events: none;
      }
    }
  }

  .arrow {
    &-left::after {
      content: "";
      position: absolute;
      top: 50%;
      right: calc(100% - 0.5rem);
      transform: translateY(-50%);
      border-width: 6px;
      border-style: solid;
      border-color: transparent var(--black-0) transparent transparent;
    }

    &-right::after {
      content: "";
      position: absolute;
      top: 50%;
      left: calc(100% - 0.5rem);
      transform: translateY(-50%);
      border-width: 6px;
      border-style: solid;
      border-color: transparent transparent transparent var(--black-0);
    }

    &-top::after {
      content: "";
      position: absolute;
      bottom: calc(100% - 0.5rem);
      left: 50%;
      transform: translateX(-50%);
      border-width: 6px;
      border-style: solid;
      border-color: transparent transparent var(--black-0) transparent;
    }

    &-bottom::after {
      content: "";
      position: absolute;
      top: calc(100% - 0.5rem);
      left: 50%;
      transform: translateX(-50%);
      border-width: 6px;
      border-style: solid;
      border-color: var(--black-0) transparent transparent transparent;
    }
  }
}
