.DateCell {
  position: relative;

  &.emptyClass {
    .Datepicker-input {
      opacity: 0;
    }

    &:hover {
      .Datepicker-input {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }
  }

  &.withTime {
    .Datepicker {
      .react-datepicker__month-container {
        padding-bottom: 0;
      }
    }
  }

  .Datepicker {
    margin-bottom: 0;
    height: 100%;

    & > .react-datepicker-wrapper {
      height: 100%;

      & *:nth-child(-n + 1) {
        height: 100%;
      }
    }

    .Datepicker .react-datepicker__input-container input,
    .Datepicker-input {
      border: none;
      font-family: var(--helvetica500);
      font-weight: 500;
      font-size: var(--fSize10);
      text-align: center;
      background-color: transparent;

      &:hover,
      &:focus {
        border: none;
      }
    }

    .react-datepicker__input-time-container {
      margin-left: 0;
      width: 100%;

      .react-datepicker-time__caption {
        display: none;
      }

      .react-datepicker-time__input {
        height: 40px;

        & > input {
          padding: 12px 10px;
          color: var(--black-0);
          border: 1px solid var(--gray-20);
          border-radius: 3px;

          font-size: var(--fSize14);
          font-family: var(--helvetica400);
          font-weight: 400;

          &:focus,
          &:active {
            outline: none;
          }
        }
      }
    }
  }
}
