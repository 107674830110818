.EstimateGroupRow {
  padding: 0 48px 0 32px;
  height: 88px;
  display: grid;
  grid-template-columns: 1fr auto 80px;
  align-items: center;
  box-shadow: var(--bottom-line);
  gap: 24px;

  scroll-margin-top: 24px;

  & > .Tooltip {
    margin-right: 0;

    .InputField {
      margin-bottom: 0;
    }
  }

  & > .toggler {
    display: flex;
    align-items: center;
    margin-right: auto;
  }
}
