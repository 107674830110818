.OverviewPipelineColumn {
  flex: 1;

  &.collapsed {
    max-width: 4rem;

    & > .OverviewPipelineColumn-title {
      .collapseToggler {
        display: none;
      }
    }
  }

  &-title {
    display: flex;
    justify-content: space-between;
    font-family: var(--helvetica700);
    font-size: var(--fSize17);
    text-transform: uppercase;
    padding-right: 0.2rem;
    min-height: 34px;
    .collapseToggler {
      display: none;
    }
    &:hover {
      .collapseToggler {
        display: block;
      }
    }

    &.gray {
      color: var(--gray-20);
    }
    &.yellow {
      color: var(--yellow-0);
    }
    &.blue {
      color: var(--blue-10);
    }
    &.green {
      color: var(--green-10);
    }

    border-bottom: 7px solid;
    margin-bottom: 0.57rem;
  }

  &-narrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--white-10);
    border: 1px solid var(--gray-40);
    justify-content: center;
    padding: 1rem;
    height: calc(100% - 44px);

    & > .title {
      line-height: 12px;
      text-transform: uppercase;
      text-align: center;
      color: var(--gray-10);
      font-family: var(--helvetica500);
      font-size: var(--fSize10);
    }

    & > .expandBtn {
      text-transform: uppercase;
      color: var(--gray-20);
      font-family: var(--helvetica500);
      font-size: var(--fSize10);
      margin-top: 1rem;
    }
  }
}
