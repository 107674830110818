.Account {
  display: flex;

  &-modal {
    --radius: 0.36rem;

    .content {
      padding: 0;
      box-shadow: var(--dark-shadow);
      border-radius: var(--radius);
      outline: none;
      max-height: calc(100vh - 80px);
      overflow-y: auto;
    }

    .AccountSidebar {
      border-top-left-radius: var(--radius);
      border-bottom-left-radius: var(--radius);
    }

    .AccountLayoutTab-header {
      border-top-right-radius: var(--radius);
    }
  }
}
