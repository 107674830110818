.CurrInputCell {
  padding: 0 !important;
  height: 40px;

  .InputCell-input {
    text-align: right;
  }

  .InputCell-text {
    justify-content: flex-end;
  }

  .InputCell-placeholder {
    justify-content: flex-end;
  }
}
