.AssigneeCell {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &-showMenuBtn {
    height: 100%;

    &.empty {
      cursor: default;
      &:active {
        transform: none;
      }
    }
  }

  &.emptyImg {
    cursor: default;

    &:hover {
      .image {
        filter: brightness(82%);
        transition: filter 0.3s;
      }
    }
  }

  img {
    height: 32px;
    width: 32px;
    border: 1px solid var(--gray-100);
    border-radius: 50%;
  }

  .LetterAvatar,
  img {
    &:not(:first-child) {
      margin-left: -20px;
    }
  }
}

.AssigneeCellDropdown {
  .DropdownMenu-body {
    padding: 8px 12px;
    width: 194px;
  }
}
