@import "src/assets/styles/partials/tag";
@import "src/assets/styles/placeholders/label";
@import "src/assets/styles/placeholders/input";

.TagsFieldCreating {
  margin-bottom: 1.14rem;
  width: 100%;

  &.error {
    .label {
      color: var(--red-0);
    }
    .TagsFieldCreating-select__control {
      border-color: var(--red-0);
      &:hover {
        border-color: var(--red-0);
      }
    }
  }
  &-error-message {
    color: var(--red-0);
    padding-top: 0.25rem;
    font-size: var(--fSize13);
  }

  &-label {
    @extend %label;
  }
  &-button.Button {
    font-size: var(--fSize13);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    color: var(--black-0);
    width: 100%;
    text-transform: initial;
    font-family: var(--helvetica400);
    font-weight: 400;
    &:active {
      transform: initial;
    }
  }

  .TagsFieldCreating-select__menu-notice {
    color: var(--black-0);
    background-color: var(--gray-40);
  }

  .TagsFieldCreating {
    &-select {
      &__dropdown-indicator {
        display: none;
      }
      &__indicator-separator {
        display: none;
      }
    }
  }

  .TagsFieldCreating-select__control {
    @extend %input;
    padding: 0;
    box-shadow: none;
    text-align: left;
    &:hover {
      border: 1px solid var(--gray-20);
    }
  }

  .TagsFieldCreating-select__multi-value__label {
    @extend .tag;
    @extend .tag__md;
    border-right: none;
    padding-right: 0;
  }
  .TagsFieldCreating-select__multi-value__remove {
    @extend .tag;
    border-left: none;
    padding-left: 0;
    &:hover {
      background-color: var(--white-10);
      color: inherit;
    }
  }
  .TagsFieldCreating-select__option {
    cursor: pointer;
    text-align: left;
    z-index: 12;
    &--is-focused {
      background: var(--gray-40);
    }
    &:active {
      background-color: var(--gray-40);
    }
  }

  .TagsFieldCreating-select__menu-list {
    z-index: 4;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 250px;
  }
}
