.ShowGroupBtn {
  grid-column: 1/-1;
  overflow: hidden;

  &:not(:first-child) {
    margin-top: 3.7rem;
  }

  & > .btn {
    position: relative;
    display: block;
    margin: auto;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 50vw;
      height: 1px;
      background-color: var(--gray-100);
      top: 50%;
    }

    &::before {
      left: calc(-50vw + -24px);
    }

    &::after {
      right: calc(-50vw + -24px);
    }
  }
}
