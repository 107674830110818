.rowNotes {
  padding: 0 !important;
  position: relative;
  word-break: break-word;

  & > .note {
    cursor: pointer;
    padding: 6px var(--16px);
  }
}
