.AccountLayoutTab {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;

  &-header {
    padding: 1.3rem 1.71rem;
    box-shadow: var(--bottom-line);
    background: var(--white-40);
    text-transform: uppercase;
		h2 {
			font-family: var(--helvetica700);
		}
  }

  &-body {
    overflow-y: auto;
    max-height: 500px;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.61rem 1.41rem;
    box-shadow: var(--top-line);

    .Button {
      margin-left: 1.14rem;
      min-width: 9.14rem;
    }

    &-cancel {
      color: var(--gray-20);
    }
  }
}
