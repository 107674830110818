.UnderConstruction {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  &-title {
    font-size: var(--fSize26);
    font-family: var(--helvetica700);
    margin-top: 2rem;
  }
  &-info {
    margin-top: 0.5rem;
    font-size: var(--fSize20);
    font-family: var(--helvetica300);
  }
}
