.AddJobSections {
  &-header {
    text-align: center;
    margin-bottom: 1.41rem;
    letter-spacing: 0.025rem;
  }

  .AddJobCheckbox {
    margin-bottom: 2.85rem;

    .Checkbox {
      width: 100%;
      &-label {
        width: 100%;
      }
    }
  }
}
