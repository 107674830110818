.JobDetailsNav {
  padding: 16px 32px 8px;
  background-color: var(--white-40);
  box-shadow: var(--bottom-line);

  min-height: 48px;

  display: flex;
  align-items: flex-end;
  gap: 0 3.42rem;

  position: relative;
  .AppTooltips {
    top: 115%;
    left: 44px;
    z-index: 99;
  }

  &-item {
    font-family: var(--helvetica500);
    position: relative;
    color: var(--gray-10);
    font-size: var(--fSize12);
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding: 0 0 0.5rem;
    transition: var(--transition);
    white-space: nowrap;

    &.active {
      color: var(--black-0);
      font-family: var(--helvetica700);
      &:after,
      &:before {
        width: 100%;
      }
    }
    &:hover {
      color: var(--black-0);
      &:after,
      &:before {
        width: 100%;
      }
    }
    &:after,
    &:before {
      content: "";
      position: absolute;
      height: 3px;
      width: 0;
      bottom: -0.55rem;
      background: var(--black-10);
      transition: var(--transition);
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }

  &-add {
    color: var(--gray-10);
    padding: 0 0 0.5rem;

    transition: var(--transition);
    &:hover {
      color: var(--black-0);
    }
  }
}
