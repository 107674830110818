.TabFormLayout {
  width: 100%;
  display: grid;
  grid-template-rows: 64px auto 1fr auto;
  height: calc(100vh - 160px);

  &-header {
    background: var(--white-40);
    border-radius: 5px 5px 0 0;
    text-transform: uppercase;
    padding: 0 1.71rem;
    box-shadow: var(--bottom-line);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-title {
      font-family: var(--helvetica700);

      word-break: break-word;
    }
    .Button.dark {
      &.unselected {
        background-color: #f2f2f5;
        color: #939498;
      }
    }
  }

  .tab-item {
    padding: 1.26rem 0;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.85rem 1.41rem;
    box-shadow: var(--top-line);
    bottom: 0;
    width: 100%;
    .Button {
      margin-left: 1.14rem;
      min-width: 9.14rem;
      &:not(:last-child) {
        font-family: var(--helvetica300);
        color: var(--gray-10);
      }
    }
  }
}
