%label {
  color: var(--gray-0);
  font-family: var(--helvetica400);
  margin-bottom: 0.25rem;
  font-size: var(--fSize11);
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: left;
  width: 100%;

  display: flex;
  align-items: center;

  &.error {
    color: var(--red-0);
  }
}
