.rowRate {
  padding: 0 !important;
  position: relative;

  word-break: break-word;

  & > .rate {
    cursor: pointer;
    padding: 6px var(--16px);
  }

  .RowRateInput {
    border: none;
    resize: none;
    min-height: 100%;
    outline: none;
    padding: 6px var(--16px);
    background-color: var(--gray-120);

    width: 100%;
  }
}
