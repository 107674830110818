.AccountNotifications {
  padding: 0 1.71rem 1.71rem;

  &-group {
    box-shadow: var(--bottom-line);
    padding: 1.71rem 0;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-info {
      color: var(--gray-10);
      font-size: 1rem;
      font-weight: 400;
      text-align: left;
    }

    &-body {
      display: flex;
      margin-top: 0.75rem;

      .Checkbox {
        margin-right: 0.5rem;
      }
    }

    & > .emailsEmptyError {
      padding-top: 0.5rem;
      color: var(--red-0);
    }
  }

  &-title {
    font-family: var(--helvetica700);
    display: flex;
    align-items: center;
    text-align: left;
    text-transform: uppercase;

    .icon-IconInfo {
      margin-left: 0.5rem;
    }

    .AccountNotifications-icon-info {
      margin-left: 0.5rem;
    }
  }

  &-tooltip {
    display: flex;

    &-element {
      text-align: left;
      font-size: var(--fSize13);
      text-transform: initial;

      ul {
        padding-left: 1.5rem;
      }

      li {
        list-style-type: disc;
      }
    }

    .Tooltip-text {
      width: 225px;
    }
  }

  &-task-select {
    &.SelectField-wrap {
      max-width: 290px;
      margin-bottom: 0;

      .SelectField {
        color: var(--gray-10);
        background: var(--gray-40);
      }
    }
  }

  &-daily {
    align-items: center;
    grid-gap: 1rem;

    .SelectField-wrap {
      width: auto;
      margin: 0 0.5rem 0;
    }

    &-label {
      white-space: nowrap;
    }

    &-select {
      &-time {
        max-width: 120px;
      }

      &-day {
        max-width: 150px;
      }
    }

    .Select {
      margin-bottom: 0;
    }
  }
  .AccountNotifications-task-select {
    margin-bottom: 0;
  }
}
