@import "src/assets/styles/placeholders/label";
.JobDetails {
  padding: 1.5rem;

  &-header {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 70% 30%;
    grid-template-rows: 1fr 1fr;
    gap: 0 0;
    grid-template-areas:
      "upperLeft right"
      "lowerLeft right";

    box-shadow: var(--bottom-line);
    padding-bottom: 1.14rem;
    margin-bottom: 1.14rem;

    &-tag {
      display: inline-block;
      margin: 0 4px;
    }

    &-upper {
      grid-area: upperLeft;
      display: flex;
      align-items: center;
    }

    &-lower {
      grid-area: lowerLeft;
      display: flex;
      align-items: center;
    }

    &-rightSection {
      grid-area: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .Button {
        max-height: 32px;
        min-width: 122px;
        font-size: var(--fSize12);
      }
    }

    .AvatarGroup {
      margin-left: 1.14rem;
    }
  }

  &-title {
    font-size: var(--fSize26);
    font-weight: 700;
    font-family: var(--helvetica700);
    text-transform: uppercase;
  }

  &-shortDesc {
    color: var(--gray-10);
    font-size: var(--fSize17);
    text-transform: uppercase;
    display: flex;
    gap: 0 8px;
  }

  &-body {
    &-tag {
      display: inline-block !important;
      margin: 0 0.25rem 0.25rem 0;
      white-space: nowrap;
    }
  }

  &-list {
    box-shadow: none;
    margin-bottom: 0;
  }

  .ModalInfoSection {
    grid-template-columns: 160px 3fr;

    .label {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  &-pipeline {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 32px;
    border-radius: 2px;
    text-transform: uppercase;
    font-size: var(--fSize10);
    font-weight: 500;

    &.bidding {
      background-color: var(--gray-50);
    }

    &.working {
      background-color: var(--yellow-0);
    }

    &.wrap {
      background-color: var(--blue-10);
    }

    &.done {
      background-color: var(--green-10);
    }

    &.empty-pipeline {
      background-color: var(--gray-30);
      color: var(--gray-10);
    }
  }

  &-tasks {
    &-label {
      @extend %label;
    }
  }
  &-tasksTable {
    width: 100%;
    border-collapse: collapse;
    tr {
      border-style: solid;
      border-width: 1px 1px 1px 0;
      border-color: var(--gray-40);
    }
  }
  &-tasksRow {
    &-name {
      width: 60%;
      padding-left: 4px;
    }
    &-date {
      font-weight: 500;
      font-size: var(--fSize10);
      text-align: center;
      width: 20%;
      text-transform: uppercase;
    }
    &-status {
      width: 20%;
      height: 32px;
      background-color: var(--gray-30);
      text-align: center;
      font-weight: 500;
      font-size: var(--fSize10);
      text-transform: uppercase;
      &.Done {
        background-color: var(--green-10);
      }
      &.Working {
        background-color: var(--yellow-0);
      }
      &.Stuck {
        background-color: var(--red-20);
        color: var(--white-0);
      }
    }
  }
}
