.GrandTotal {
  position: sticky;
  bottom: 0;
  z-index: 1;

  &-cell {
    cursor: pointer;

    padding: 0 12px;
    min-height: 40px;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    background-color: var(--black-0);

    &.Total {
      position: sticky;
      left: 0;
      z-index: 1;
      bottom: 0;
    }

    &.TextComponent {
      color: var(--white-0);

      &.black-10 {
        color: var(--white-0);
      }

      &.red {
        color: var(--red-0);
      }

      &.green-30 {
        color: var(--green-10);
      }
    }
  }
}
