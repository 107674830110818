.CreateNewJob {
  height: 100vh;
  overflow-y: auto;
  background-color: var(--white-0);
  display: grid;
  grid-template-rows: 7.15rem 1fr;

  & > *:last-child {
    align-self: center;
  }

  &-header {
    padding: 0 3.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3;

    position: sticky;
    top: 0;
    background-color: var(--white-0);

    .btnBackWrap {
      width: 32px;
    }

    & > .steps {
      display: flex;
      gap: 0.57rem;

      & > .stepTile {
        width: 4rem;
        height: 0.28rem;
        background-color: var(--gray-40);

        &.active {
          background-color: var(--black-10);
        }
      }
    }

    & > .close {
      width: 1.42rem;
      height: 1.42rem;

      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }
}
