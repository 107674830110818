.Notifications {
  position: absolute;
  height: 100%;
  background: var(--white-0);
  z-index: 3;
  top: 0;
  left: -320px;
  transition: var(--transition);

  &.show {
    left: 240px;
    transition: var(--transition);

    &.Notifications-attach-to-left {
      left: 52px;
    }

    .Notifications-overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      background: var(--black-0);
      opacity: 0.7;
      left: 0;
    }
  }

  &-content {
    height: 100%;
    background: var(--white-0);
    position: relative;
    width: 320px;
  }

  &-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 31px);
    position: relative;

    &-warning {
      font-weight: 500;
      color: var(--gray-10);
      text-align: center;
      margin: 1.71rem auto;
    }
  }

  &-loadingMore {
    padding: 1.24rem 1.14rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
  }
}
