.JobFilesHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.14rem 2.28rem;
  box-shadow: var(--bottom-line);

  &-title {
    text-transform: uppercase;
  }

  &-link {
    display: flex;
    align-items: center;
    font-size: var(--fSize10);
    font-weight: 700;
    padding: 4px 12px;
    border-radius: 3px;
    border: 1px solid var(--gray-30);
    text-transform: uppercase;
    height: 32px;
    &.disabled {
      pointer-events: none;
    }

    .icon {
      margin-left: 8px;
    }
  }
}
