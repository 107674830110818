.CustomFieldsForm {
  padding: 1.71rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 1.14rem 0;
  .Select,
  .Datepicker {
    max-width: 50%;
  }
}
