.PeopleModals {
  .content {
    padding: 0;
    min-height: 150px;
  }

  &.is-second-modal {
    .container {
      transform: translateX(40px);
    }
  }
}
