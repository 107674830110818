.BatchEdit {
  &-title {
    font-family: var(--helvetica700);
    font-size: var(--fSize15);
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.71rem;
  }

  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.57rem;
    margin-bottom: 1.71rem;
  }

  &-grid-item {
    border: 1px solid var(--gray-40);
    padding: 1.14rem;
    .Checkbox-label {
      font-family: var(--helvetica500);
    }
  }

  .SenderRecipientItem:nth-of-type(2) {
    margin-top: 0;
  }

  .SenderRecipientItem-dot {
    left: 2.5rem;
  }

  .FormWrap-main {
    padding-top: 0;
  }

  &-warning {
    text-align: center;
    .icon {
      margin-right: 0.57rem;
    }
  }

  .Checkbox-label {
    background-color: unset;
    border: none;
    padding: 0 0 0 2rem;
    margin-bottom: 1.14rem;
    &:after {
      left: 0.6rem;
    }
    &:before {
      left: 0;
    }
  }

  .Checkbox-input:checked ~ .Checkbox-label {
    background-color: transparent;
  }

  .SenderRecipientItem {
    .Checkbox-label {
      margin-bottom: 0;
    }
  }
  .InputField {
    margin-bottom: 0;
  }

  .TimeField {
    margin-left: 0;
  }
  .SenderRecipientToolbar {
    position: relative;
    margin-bottom: 1.71rem;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -1.71rem;
      width: calc(100% + 3.42rem);
      height: 2px;
      opacity: 0.5;
      box-shadow: var(--bottom-line);
    }
  }
  .SenderRecipientToolbar-left {
    display: flex;
    align-items: center;
    .Checkbox {
      margin-right: 0;
    }

    .Checkbox-label {
      margin-bottom: 0;
      margin-right: 0.3rem;
    }
  }

  &-fields {
    margin-bottom: 4rem;
    .SenderRecipientItem .Checkbox-label {
      font-weight: 700;
    }
  }
}
