.TemplateCalendar {
  &-modal {
    .content {
      padding-top: 56px;
    }

    .JobsSettingsCalendarTemplate-calendar .dhx_scale_holder_now {
      background-color: transparent;
    }
    .JobsSettingsCalendarTemplate-calendar .dhx_cal_event_line {
      background-color: var(--black-0);
    }
    .JobsSettingsCalendarTemplate-toolbarRow {
      justify-content: flex-end;
    }
  }
}
