.TotalCellWrap {
  justify-content: space-between;
  padding-left: 0;
  height: 100%;
  min-width: 145px;

  &-btn {
    height: 100%;
  }

  .defaultValue {
    color: var(--gray-10);
  }

  .notBill {
    text-decoration: line-through;
  }
}
