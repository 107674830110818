@import "src/assets/styles/placeholders/input";

.AddressFieldSearch {
  width: 100%;
  margin-bottom: 1.14rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .searched-text {
    font-weight: 700;
  }

  &.error {
    .AddressFieldSearch-label {
      color: var(--red-0);
    }

    .AddressFieldSearch-control__control {
      border-color: var(--red-0);
    }
  }

  &-label {
    @extend %label;
    width: unset;
  }

  &-control {
    width: 100%;
  }

  &-control__control {
    @extend %input;
    padding: 0;
    box-shadow: none;
    text-align: left;

    &--is-focused {
      box-shadow: none !important;
      border-color: var(--gray-20) !important;
    }

    &:hover {
      border: 1px solid var(--gray-20);
    }
  }

  &-control__dropdown-indicator,
  &-control__indicator-separator {
    display: none !important;
  }

  &-control__option {
    cursor: pointer !important;
    text-align: left;
    z-index: 12;
    background-color: var(--white-0);
    color: var(--black-0);

    &--is-selected {
      background-color: var(--white-0) !important;
      color: var(--black-0) !important;
    }

    &--is-focused {
      background-color: var(--gray-40) !important;
      color: var(--black-0) !important;
    }
  }

  &-control__menu-notice {
    text-align: left;
    background-color: var(--gray-40);
    color: var(--black-0);
    font-weight: 500;
  }

  &-control__menu-list {
    z-index: 4;
    padding-top: 0;
    padding-bottom: 0;
  }

  &-error-message {
    color: var(--red-0);
    padding-top: 0.25rem;
    font-size: var(--fSize13);
    text-align: left;
  }
}