%label {
  color: var(--gray-0);
  font-family: var(--helvetica400);
  margin-bottom: 0.25rem;
  font-size: var(--fSize11);
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
}

%input {
  font-family: var(--helvetica400);
  background-color: var(--white-0);
  min-height: 40px;
  border: 1px solid var(--gray-30);
  border-radius: 3px;
  outline: none;
  position: relative;
  width: 100%;
  font-size: 1rem;
  padding: 0.75rem 0.86rem;
  box-sizing: border-box;
  resize: none;
  transition: var(--animation-timing);
  color: var(--black-10);

  &::placeholder {
    color: var(--gray-10);
  }

  &:focus {
    border: 1px solid var(--gray-20);
  }

  &:disabled {
    border: 1px solid var(--gray-40);
    background: var(--gray-40);
    color: var(--gray-0);
  }

  &:hover {
    border: 1px solid var(--gray-20);
  }
}

%error {
  color: var(--red-0);
  padding-top: 0.25rem;
  font-size: var(--fSize13);
  text-align: left;
}
