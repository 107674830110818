.TableToolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.14rem;

  &-title {
    font-family: var(--helvetica700);
    font-size: var(--fSize13);
    text-transform: uppercase;
  }
}