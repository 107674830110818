.AccountSettings {
  margin: 1.71rem;
  padding-bottom: 1.71rem;
  box-shadow: var(--bottom-line);
  position: relative;

  &-group {
    box-shadow: var(--bottom-line);
    padding: 1.71rem 0;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-title {
    font-family: var(--helvetica700);
    display: flex;
    align-items: center;
    text-align: left;
    text-transform: uppercase;
  }
}
