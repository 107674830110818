.TablePipelineCell {
  width: max-content;
  white-space: nowrap;

  &-btn {
    width: 100%;
    padding: 0.71rem 0.57rem;
    font-size: var(--fSize10);
    color: var(--black-0);
    font-family: var(--helvetica500);
    text-transform: uppercase;
    text-align: left;
    letter-spacing: 0.01rem;
    border-radius: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 32px;

    &.bidding {
      background-color: var(--gray-50);
    }

    &.working {
      background-color: var(--yellow-0);
    }

    &.wrap {
      background-color: var(--blue-10);
    }

    &.done {
      background-color: var(--green-10);

      &.not_awarded {
        opacity: 0.6;
      }
    }
  }

  &-overdue {
    border-radius: 3px;
    padding: 3px;
    color: var(--red-0);
    font-size: var(--fSize8);
    text-align: center;
    text-transform: uppercase;
    background-color: var(--white-0);
    margin-left: auto;
  }
}
