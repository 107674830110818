.ExpenseRow,
.CostValueRow {
  & > td {
    &:first-child,
    .ExpenseRowName,
    .TrackWACellName {
      width: 384px;
      min-width: 384px;
    }

    &.difference {
      width: 140px;
      min-width: 140px;
    }
  }
}

.ExpenseRow {
  &:hover * {
    background-color: var(--blue-30);
  }
}
