.FileCell {
  &.empty {
    .AttachFile {
      opacity: 0;
      transition: opacity 0.3s;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    &:hover {
      .AttachFile {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }
  }

  .attachBtn {
    width: 100%;
    height: 100%;
  }
}
