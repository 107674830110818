.HeaderButtons {
  &-icon {
    transform: rotate(180deg);
    transition: transform 0.15s ease-in;
    &.active {
      transform: rotate(0);
    }
  }

  &-dropdown {
    .DropdownMenu-body {
      width: 151px;
    }
    .Button {
      padding: 0.42rem 1.14rem !important;
    }
  }
}
