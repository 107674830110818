.ListLineLayout {
  height: 40px;
  display: grid;
  grid-template-columns: 1fr repeat(4, auto);
  box-shadow: var(--bottom-line);
  overflow: hidden;

  & > *:first-child {
    word-break: break-word;
  }

  &:hover {
    & > button {
      opacity: 1;
    }
  }

  & > button {
    opacity: 0;
    width: 30px;

    &:hover {
      .icon {
        color: var(--black-0);

        svg path {
          fill: var(--black-0);
        }
      }
    }
  }
}
