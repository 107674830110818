.EstimateTotal {
  td {
    font-weight: bold;
    padding: 0.24rem 0;
    width: 50%;
    &:nth-of-type(2) {
      text-align: right;
    }
  }

  .green {
    color: var(--green-0);
  }

  .total {
    font-weight: 700;
    font-size: var(--fSize17);
    text-transform: uppercase;
    td {
      padding: 0.71rem 0;
      &:nth-of-type(2) {
        font-size: var(--fSize20);
      }
    }
  }
}
