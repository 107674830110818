.ExpenseRow {
  &-cell {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: var(--white-0);
    border-right: 1px solid var(--gray-40);

    &:not(.ExpenseRowName) {
      padding: 0 12px;
      border-bottom: 1px solid var(--gray-40);
    }
  }

  .AttachFileWrap.differenceItem {
    border-right: 1px solid var(--gray-40);
    border-bottom: 1px solid var(--gray-40);
    background-color: var(--white-0);
  }

  &.grabbing {
    .ExpenseRow-cell {
      background-color: transparent !important;
    }

    &:hover * {
      background-color: transparent !important;
    }
  }
}
