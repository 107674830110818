.PageLayout {
  height: 100vh;
  width: 100%;
  display: flex;
  position: relative;
  background-color: var(--white-0);

  &-main {
    flex: 1;
    overflow-y: auto;
  }
}
