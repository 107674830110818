.BudgetOverlayJobDetails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  gap: 1.71rem 4.57rem;
  margin-bottom: 1.71rem;

  &-clientRow {
    display: flex;
    gap: 0 2.14rem;
    & > div {
      width: 30%;
    }
  }

  &-description {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.71rem;

    .TextComponent {
      &:nth-of-type(odd) {
        flex: 1 33%;
      }
      &:nth-of-type(even) {
        flex: 1 67%;
      }
    }
  }
}
