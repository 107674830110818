.JobFilesListItem {
  display: flex;
  align-items: center;
  padding: 0.45rem 0.7rem;
  box-shadow: var(--bottom-line);
  text-transform: uppercase;
  font-size: var(--fSize16);
  font-weight: 500;
  letter-spacing: 0.015rem;
  min-height: 39px;
  position: relative;

  &-img {
    max-width: 1.75rem;
    margin-right: 8px;
  }

  &-actionButton {
    font-weight: 700;
    display: flex;
    align-items: center;
    height: 100%;
  }
  &-file {
    align-items: stretch;
    justify-content: space-between;
  }
  &-text {
    display: flex;
    align-items: center;
  }
}
