@import "src/assets/styles/placeholders/label";

.ExportModal {
  &-body {
    margin-bottom: 2.85rem;
    text-align: center;
  }
  &-fields-group {
    display: flex;
    justify-content: center;
    align-items: center;
    &-label {
      @extend %label;
      margin: 1.71rem auto 0.25rem;
      justify-content: center;
    }
    .Checkbox {
      margin: 0 0.57rem;
    }
  }
}
