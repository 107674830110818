.CustomOption {
  &-label {
    padding: 8px 12px;
    background: var(--white-0);

    &:hover {
      background: var(--gray-40);
      cursor: pointer;
    }
  }

  &-submenu {
    min-height: 36px;
    overflow: auto;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    margin: 0.5rem 1.5rem;
  }

  &-option {
    padding: 5px 12px;
    cursor: pointer;

    &:hover {
      background: var(--gray-40);
      color: var(--black-0);
    }

    &.noOption {
      &:hover {
        background: var(--white-0);
        color: var(--black-0);
        cursor: default;
      }
    }
  }
  // &-label {
  //   padding: 0px 12px;

  //   &:hover {
  //     background-color: var(--blue-50);
  //     cursor: pointer;
  //     color: var(--white-0);
  //   }
  // }

  // &-submenu {
  //   background-color: white;
  //   min-height: 36px;
  //   overflow: auto;
  //   border: 1px solid hsl(0, 0%, 80%);
  //   border-radius: 4px;
  //   color: var(--black-0);
  //   margin: 0.5rem 1.5rem;
  // }

  // &-option {
  //   padding: 0 12px;
  //   cursor: pointer;

  //   &:hover {
  //     background-color: var(--blue-50);
  //     color: var(--white-0);
  //   }
  // }
}
