.TagsGroup {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &-has-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.42rem;
  }
}