.EstimateGroupRowFooter {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 68px;
  box-shadow: var(--bottom-line);

  &.approved {
    grid-template-rows: 84px;
    & > .TextComponent {
      margin-top: 24px;
    }

    & > .Button {
      display: none;
    }
  }

  & > .Button {
    justify-self: flex-start;
    margin-left: 130px;

    &:disabled {
      background-color: transparent;
    }
  }

  & > .totalWrap {
    align-self: flex-start;
    justify-self: flex-end;

    padding: 8px 48px;

    display: flex;
    gap: 24px;
  }
}
