@import "../../../assets/styles/placeholders/label";

.DetailsTypeList {
  &-title {
    font-family: var(--helvetica700);
    text-transform: uppercase;
    margin-bottom: 0.57rem;
  }

  &-empty-value {
    color: var(--gray-20);
    margin-left: 1rem;
    font-size: var(--fSize14);
    text-transform: none;
    font-weight: normal;
  }

  &-group {
    display: flex;
    margin-bottom: 0.5rem;

    &-wrapper {
      &:nth-of-type(1) {
        margin-bottom: 1.14rem;
      }
    }
  }

  &-label {
    @extend %label;
    width: 25%;
    display: flex;
    margin-bottom: 0;
    align-items: flex-start;
  }

  &-value {
    width: 75%;
    line-height: 1;
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.25rem;
    align-items: flex-start;
    &-element {
      word-break: break-word;
    }
  }
}
