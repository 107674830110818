.EstimateRow {
  min-height: 40px;
  align-items: center;

  &.expanded {
    & > * {
      border-bottom-color: transparent;
    }
  }

  &.approved {
    cursor: default;
    & > *:not(.EstimateRowName) {
      pointer-events: none;
    }

    .EstimateRowName {
      cursor: default;

      &:hover {
        .sortIcon {
          opacity: 0;
        }

        & > *:not(.Button) {
          cursor: default;
        }
      }
    }

    & > .EstimateRowActions {
      .Button {
        display: none;
      }
    }
  }

  & > * {
    border-bottom: 1px solid var(--gray-100);
    display: flex;
    align-items: center;
    height: 100%;

    &.EstimateRowName {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 32px;
        height: 100%;
        background-color: var(--white-0);
        top: 0;
        left: 0;
        border-bottom: 1px solid var(--white-0);
      }
    }
  }

  & > .EstimateRowCurrency,
  & > .EstimateRowName,
  & > .EstimateRowAGY,
  & > .EstimateRowTotal,
  & > .EstimateRowBudget {
    border-right: 1px solid var(--gray-100);
  }

  & > .EstimateRowCurrency,
  & > .EstimateRowQty,
  & > .EstimateRowTime,
  & > .EstimateRowAGY,
  & > .EstimateRowSalesTax,
  & > .EstimateRowPayRoll {
    justify-content: center;
  }

  .EstimateRowTime,
  .EstimateRowQty {
    & > .EstimateRowINput {
      .TextComponent,
      .EstimateRowINput-input {
        text-align: center;
      }
    }
  }
}
