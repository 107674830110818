.JobFiles {
  position: relative;

  &-error {
    padding: 2.28rem;

    &-text {
      font-weight: 700;
      font-size: var(--fSize17);
      text-transform: uppercase;
      margin-top: 1.14rem;
    }
    &-back {
      font-weight: 700;
      font-size: var(--fSize15);
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }
  }

  &-emptyMessage {
    padding: 1.71rem 2rem;
    letter-spacing: 0.027rem;
  }
}
