.AppTooltips {
  --app-tooltip-width: 300px;

  width: var(--app-tooltip-width);
  padding: 0.857rem;
  text-align-last: left;
  color: var(--white-0);
  border-radius: 0.214rem;
  background-color: var(--black-0);
  cursor: default;

  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;

  .withAnimation {
    animation-name: fadeIn;
    animation-duration: 0.5s;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    width: 0.438rem;
    height: 0.438rem;
    transform: rotate(45deg);
    background-color: inherit;
  }

  &.topLeft:before {
    top: -3px;
    left: 1.063rem;
  }

  &.topRight:before {
    top: -3px;
    right: 1.063rem;
  }

  &.bottomRight:before {
    bottom: -3px;
    right: 1.063rem;
  }

  &.bottomLeft:before {
    bottom: -3px;
    left: 1.063rem;
  }

  &-header {
    margin-bottom: 0.286rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-title {
    text-align: left;
    font-family: var(--helvetica700);
    font-size: var(--fSize13);
    text-transform: uppercase;
    letter-spacing: 0.02rem;
  }

  &-closeIcon {
    color: var(--white-0);

    &:hover {
      color: var(--white-10);
    }
  }

  &-changeStepBtn.Button {
    border: 1px solid var(--gray-30);
    height: 1.714rem;
    border-radius: 2px;
  }

  &-body {
    text-align: left;
    font-family: var(--helvetica400);
    font-size: var(--fSize13);

    white-space: pre-wrap;
    text-transform: none;
  }

  &-footer {
    margin-top: 1.143rem;
    display: flex;
    align-items: center;
  }

  &-skipAllBtn {
    font-family: var(--helvetica500);
    font-size: var(--fSize10);
    letter-spacing: 0.01rem;
    text-transform: uppercase;
    color: var(--gray-20);
    margin-left: 0.571rem;
  }

  &-step {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: var(--gray-10);
    margin-right: 0.571rem;

    &.active {
      background-color: var(--white-0);
    }
  }
}
