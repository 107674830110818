.CallSheetBuildHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1.4rem 1rem;
  align-items: center;

  margin-bottom: 5.7rem;

  &-titleDate {
    background-color: var(--black-0);
    padding: 0.415rem var(--16px);
    border-radius: 0.21rem;

    & > .TextComponent {
      color: var(--white-0);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
    }

    .icon {
      font-size: 1.35rem;
    }
  }

  &-jobInfo {
    display: flex;
    .TextComponent {
      line-height: 0.8;
      padding: 0.15rem var(--8px) 0.3rem 0;
      margin-right: var(--8px);

      &:not(&:last-child) {
        border-right: 0.21rem solid var(--black-10);
      }
    }
  }
}
