.EstimateOverlay {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  // margin: 2.8rem 0;
  padding: 2.8rem 0;
  overflow: auto;

  iframe {
    width: 100%;
    max-width: 1155px;
    min-height: 670px;
    background-color: white;
  }
}
