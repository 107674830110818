@import "src/assets/styles/placeholders/label";

.CompanyForm {
  overflow: hidden;

  &-col-2 {
    display: flex;
    grid-gap: 0.5rem;
    margin-bottom: 1.14rem;

    & > div {
      flex: 1;
    }

    .InputField {
      margin-bottom: 0;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-delete {
      text-transform: uppercase;
      font-family: var(--helvetica700);
      color: var(--gray-20);
    }
  }

  &-info {
    color: var(--gray-10);
  }

  &-section {
    margin-bottom: 3.42rem;

    &-title {
      font-family: var(--helvetica700);
      text-transform: uppercase;
      text-align: left;
      margin-bottom: 1.14rem;
    }

    &-checkbox {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;

      .Checkbox {
        margin-right: 0.57rem;
      }
    }
  }

  &-next {
    width: 100%;
    font-size: var(--button-font-sm);
    padding: var(--button-size-md);
    margin-top: auto;
  }

  &-modal {
    .container {
      height: calc(100vh - 160px);
    }

    .content {
      padding: 0;
      height: 100%;
    }
  }

  .CompanyFormMain,
  .CompanyFormAddress,
  .CompanyFormOther {
    padding: 1.71rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
}
