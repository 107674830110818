%crewTableFixedGrid {
  grid-template-columns: 19rem 12.5rem 8rem 9.3rem 8rem 7.85rem 17.5rem 5.715rem 17rem 230px auto;
}

%addPaddingLeftForTable {
  content: "";
  position: absolute;
  top: 0;
  left: -1.15rem;
  background-color: var(--white-0);
  display: block;
  width: var(--16px);
  height: 100%;
  height: calc(100% + 2px); //2px border top and bottom
}

.CrewTable {
  display: grid;

  // grid-template-columns: max-content repeat(9, auto) min-content;
  @extend %crewTableFixedGrid;

  grid-auto-rows: minmax(min-content, max-content);
  align-content: flex-start;
  padding-bottom: 2rem;
  position: relative;

  overflow: auto;

  .CrewTableRowWrap {
    grid-column: 1/-1;

    display: grid;

    @extend %crewTableFixedGrid;

    grid-auto-rows: minmax(min-content, max-content);
    align-content: flex-start;

    &:hover {
      .RowName {
        & > .sortIcon {
          display: block;
        }
      }
    }
  }

  .CrewTableCell {
    min-height: 48px;
    border-bottom: 1px solid var(--gray-100);

    padding: 6px var(--16px);
    display: flex;
    align-items: center;

    overflow: visible;

    &-inactive {
      min-height: 32px;
      background-color: var(--white-40) !important;
      padding-left: 4rem !important;
      padding-top: 0;
      padding-bottom: 0;
    }

    &-agent {
      background-color: var(--white-40) !important;

      &.CrewTableCell-firstRowItem,
      & > .RowName {
        cursor: default !important;
      }
    }

    &-full {
      grid-column: 2/-1;
    }

    &.hasAgent {
      border-bottom: none;

      &.CrewTableCell-firstRowItem {
        border-right: 1px solid var(--gray-100);
        border-bottom: none;
      }
    }

    &-firstRowItem {
      position: sticky;
      padding-left: 0;
      left: var(--16px) !important;
      margin-left: var(--16px);

      z-index: 1;
      background-color: var(--white-0);
      border-bottom: 1px solid var(--gray-100);
      border-right: 1px solid var(--gray-100);

      &:before {
        @extend %addPaddingLeftForTable;
      }

      cursor: grab;
      & > .RowName {
        cursor: grab;
      }

      .sortIcon {
        display: none;
      }

      &:hover {
        .sortIcon {
          display: block;
        }
      }

      &.selecting {
        cursor: pointer;

        & > .RowName {
          cursor: pointer;
        }
      }
    }
  }
}
