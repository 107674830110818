@font-face {
  font-family: "Helvetica300";
  src: local("Helvetica300"),
    url("../fonts/HelveticaNeue/HelveticaNeue-Thin.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica400";
  src: local("Helvetica400"), url("../fonts/HelveticaNeue/HelveticaNeue.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica500";
  src: local("Helvetica500"),
    url("../fonts/HelveticaNeue/HelveticaNeue-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica700";
  src: local("Helvetica700"),
    url("../fonts/HelveticaNeue/HelveticaNeue-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
