.UploadField {
  position: relative;
  &-field {
    top: 0;
    left: 0;
    position: absolute;
    height: 2.4rem;
    opacity: 0;
    width: 160px;
  }

  &-button {
    min-width: 160px;
    margin-left: auto;
    margin-right: auto;
  }

  &-error-message {
    color: var(--red-0);
    padding-top: 0.25rem;
    font-size: var(--fSize13);
    text-align: left;
  }
}
