.LetterAvatar {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: var(--gray-40);
  color: var(--gray-10);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
