.DropdownCellWrap {
  position: relative;
  height: 100%;

  &-btn {
    padding: 0 12px;
    width: 100%;
    height: 100%;
  }
}
