.Header {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.28rem;
  box-shadow: var(--bottom-line);
  background: var(--white-0);
  height: 4rem;

  &-main {
    display: flex;
    align-items: baseline;
  }

  &-title {
    text-transform: uppercase;
    margin-right: 2.5rem;
    font-family: var(--helvetica700);
    color: var(--black-10);
  }

  &-btns-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    letter-spacing: 0.5px;
    grid-gap: 0.57rem;

    .Button {
      padding: 0.6rem 1.14rem;
      height: 100%;
      max-height: 32px;
    }

    .DropdownMenu {
      .Button {
        padding: 0.43rem 1.14rem;
        justify-content: flex-start;
      }
    }
  }

  &-tab {
    display: flex;
    align-items: flex-end;

    &-item {
      position: relative;
      margin-right: 2.5rem;
      font-size: var(--fSize12);
      text-transform: uppercase;
      font-family: var(--helvetica500);
      color: var(--gray-10);
      letter-spacing: 0.5px;

      &.active {
        font-family: var(--helvetica700);
        color: var(--black-10);

        &:after,
        &:before {
          width: 100%;
        }
      }

      &:hover {

        &:after,
        &:before {
          width: 100%;
        }
      }

      &:after,
      &:before {
        content: "";
        position: absolute;
        height: 3px;
        width: 0;
        bottom: -1rem;
        background: var(--black-10);
        transition: var(--transition);
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }
  }
}