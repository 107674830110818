.CallSheetStatusBar {
  background-color: var(--white-40);
  height: 48px;
  padding: 0 2.28rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-rightSide,
  &-leftSide {
    display: flex;
    gap: 0 8px;
  }

  &-leftSide {
    .Button {
      font-family: var(--helvetica700);
      color: var(--gray-10);
    }
  }

  &-save {
    margin-right: 1.41rem;
    position: relative;
  }
}
