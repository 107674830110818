.ManuallyEntered {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  &-inputRegular,
  &-name {
    width: 100%;
    border: none;
    outline: none;
    color: var(--black-10);

    &::placeholder {
      color: var(--gray-20);
    }

    &:focus {
      &::placeholder {
        color: transparent;
      }
    }
  }

  &-name {
    padding-left: var(--24px);
    text-transform: uppercase;
    font-family: var(--helvetica700);
    font-size: var(--fSize17);
    max-width: 180px;
    display: block;
    word-break: break-all;
    padding-top: 2px;
    padding-bottom: 2px;
    &.input {
      margin-left: var(--24px);
    }
  }

  .input {
    position: absolute;
    background-color: var(--gray-120);
    padding: 2px var(--8px);
    width: max-content;
    max-width: initial;
    z-index: 1;
    overflow: auto;
    left: -8px;
  }

  .is-empty-value {
    color: var(--gray-20);
  }
}
