.CrewTableWrap {
  overflow: auto;

  .Table {
    border: none;
    .TableHeadItem {
      position: sticky;
      top: 0;
      z-index: 7;
      background-color: var(--gray-40);

      &.withResize {
        position: sticky;

        &:not(:first-child) {
          & > .Resizer {
            right: 0;
          }
        }
      }

      &:first-child {
        left: 0;
        z-index: 8;
      }
    }
  }
}
