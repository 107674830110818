.CallSheetLogModal {
  .content {
    max-height: calc(100vh - 160px);
    overflow: hidden;
  }
}

.CallSheetLog {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  grid-template-rows: 64px auto;
  gap: 0 0;
  grid-template-areas:
    "."
    ".";

  &-header {
    font-family: var(--helvetica700);
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fSize20);
    text-transform: uppercase;

    background-color: var(--white-40);
    box-shadow: var(--bottom-line);
  }

  &-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 0;
    padding: 1.71rem;
    position: relative;
    overflow-y: auto;
    max-height: calc(100vh - 160px);
    height: 100%;
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 0;
    width: 100%;
    font-weight: 700;

    &-date {
      flex: 1 0 25%;
    }

    &-time {
      flex: 1 0 45%;
    }

    .Button {
      color: var(--gray-10);
      flex: 1 0 15%;
    }
  }
}

.CallSheetLog-deliveryDetails {
  height: 100vh;
  overflow-y: auto;
  padding: 1.71rem 0;
  align-items: unset;
  background-color: rgba(0, 0, 0, 0.65);

  &.isDownloading {
    z-index: -2;
    .content {
      background-color: white;
      padding: 0;
      box-shadow: none;
    }
    .CallSheetMsg {
      border-bottom: 2px solid black;
    }
  }

  .backdrop {
    background-color: transparent;
  }

  .content {
    background-color: transparent;
    padding: 0 0 1.71rem;
    gap: 1.71rem;
    display: flex;
    flex-direction: column;
  }

  .CallSheetMsg {
    background-color: var(--white-0);
  }

  .CallSheetMsgWrap-header {
    display: none;
  }

  .CallSheetTemplate,
  .CallSheetMsgWrap {
    box-shadow: none;
    border: none;
  }

  .SectionHeader {
    box-shadow: none;
    border-bottom: 2px solid var(--black-0);
    &.TextComponent {
      background-color: var(--white-0);
    }
  }

  .CallSheetTemplate {
    margin: 0;
  }
}
