.PdfPage {
  position: relative;
  margin: 0 auto;
  border: 1px solid #d4d4d6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 16px;

  &-textLayer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    opacity: 0.2;
    line-height: 1;
    left: 0;

    & > span {
      color: transparent;
      position: absolute;
      white-space: pre;
      cursor: text;
      transform-origin: 0% 0%;
    }
  }
}
