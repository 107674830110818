.StatusBarLayout {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  min-height: 48px;
  padding: 0 2.28rem;
  box-shadow: var(--bottom-line);
  background-color: var(--white-10);
  gap: 0 8px;
}
