@function toRem($value) {
  $remValue: ($value / 14) + rem;
  @return $remValue;
}

$spaceamounts: (4, 8, 12, 16, 20, 24, 26, 28, 32, 36, 40);
$sides: (all, x, y, top, bottom, left, right);

@mixin make-space($property) {
  $property-prefix: #{str-slice($property, 0, 1)};

  @each $space in $spaceamounts {
    @each $side in $sides {
      @if $side == "all" {
        &.#{$property-prefix}-#{$space} {
          #{$property}: #{toRem($space)};
        }
      } @else if $side == "x" or $side == "y" {
        &.#{$property-prefix}#{$side}-#{$space} {
          @if $side == "x" {
            #{$property}-left: #{toRem($space)};
            #{$property}-right: #{toRem($space)};
          } @else {
            #{$property}-top: #{toRem($space)};
            #{$property}-bottom: #{toRem($space)};
          }
        }
      } @else {
        &.#{$property-prefix}#{str-slice($side, 0, 1)}-#{$space} {
          #{$property}-#{$side}: #{toRem($space)};
        }
      }
    }
  }
}
