.CostValueRowName {
  z-index: 1;
  position: relative;

  &.CostValueRow-cell {
    padding-right: 0;
  }

  & > div:first-child {
    min-width: 64px;
  }

  & > .descriptions {
    width: 278px;
    width: 252px;
  }

  &-addLineBtn {
    position: relative;
    width: 40px;
    height: 100%;
    margin-left: auto;

    &:hover {
      & > .icon {
        &::before {
          transition: color 0.3s;
          color: var(--gray-10);
        }
      }
    }
  }

  & > .TextComponent:last-child {
    padding-left: 5px;
    margin-left: auto;

    &:hover {
      .icon.icon-add {
        color: var(--black-0);
      }
    }
  }

  .AppTooltips {
    transform: translateX(115.5%);
  }
}
