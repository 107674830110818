.DynamicFieldsWrap {
  &-phone {
    &-emptyRowErr {
      margin-bottom: 1rem;
      color: var(--red-0);
    }

    .Button {
      &:hover,
      &:focus,
      &:active {
        & > .icon {
          color: var(--white);
        }
      }
    }

    & > .DynamicInputsGroup-phone {
      display: flex;
      gap: 0 0.57rem;

      .PhoneField {
        flex: 1 1 100%;
      }

      .Button {
        min-height: 40px;
        align-self: flex-start;
        border: none;
        color: var(--gray-30);

        &:hover {
          background-color: transparent;
          color: var(--black-10);
        }
      }
    }

    &-confirmModal {
      &-body {
        margin-bottom: 2.85rem;
        text-align: center;
      }
      &-warning {
        color: var(--red-0);
        margin-top: 1.14rem;
      }
    }
  }
}
