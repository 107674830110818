.CallSheetBuildSchedule {
  margin-bottom: 48px;

  .CallSheetSchedule {
    display: grid;
    grid-template-columns: 140px 1fr 24px 140px 1fr;

    & .input {
      border-radius: 0;
      border: none;
      font-size: var(--fSize15);

      &.time {
        font-family: var(--helvetica700);
        color: var(--black-10);
      }

      &.text {
        font-family: var(--helvetica400);
        color: var(--gray-10);
      }
    }

    & > .divider {
      background-color: var(--white-40);
    }

    & > .divider,
    & > .input {
      border-right: 1px solid var(--gray-40);
      border-bottom: 1px solid var(--gray-40);
      outline: none;

      height: 2rem;
      padding: var(--8px);
    }

    & > *:first-child {
      border-left: 1px solid var(--gray-40);
    }

    & > *:nth-child(-n + 5) {
      border-top: 1px solid var(--gray-40);
    }

    & > *:nth-child(5n + 6) {
      border-left: 1px solid var(--gray-40);
    }
  }
}
