.CategoryFooter {
  height: 64px !important;
  background-color: var(--white-0);

  & > td {
    &:first-child {
      text-align: right;
      padding-right: 8px;
      text-transform: uppercase;
    }
  }

  &-cell {
    padding: 0 12px;
    min-height: 64px;

    background-color: var(--white-0);

    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
