.Links {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    & > .Title {
      margin-bottom: 0;
    }
  }
  
  &-dropdown {
    .DropdownMenu-body {
      width: 96px;

      .Button {
        justify-content: flex-start;
        padding: 0.5rem 0.9rem;
      }
    }
  }

  &-triangle {
    font-size: 10px;
    transition: transform 0.15s ease-in;
    &.active {
      transform: rotate(180deg);
    }
  }

  &-section {
    margin-bottom: 32px;

    & > div {
      margin-bottom: 8px;
    }
  }

  // placeholder
  &-buttonWrap {
    display: flex;
    justify-content: center;
    gap: 16px;

    & > .Button {
      min-width: 112px;
    }
  }
}
