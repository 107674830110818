.ModalInfoSectionWrap {
  display: flex;

  .ModalInfoSection {
    flex: 1 0 40%;
    gap: 0.85rem 4px;
    align-content: flex-start;

    &:first-child {
      padding-right: 1rem;
      margin-right: 0;
    }

    &:nth-child(2) {
      position: relative;
      padding-left: 1rem;
      margin-left: 0;

      &:before {
        content: "";
        position: absolute;
        width: 1px;
        height: calc(100% - 1rem);
        left: 0;
        top: 0;
        z-index: 1;
        background-color: var(--gray-30);
      }
    }
  }
}
