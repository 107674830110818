.Companies {
  &Loading {
    position: relative;
    min-height: calc(100vh - 60px);

    &-form {
      position: relative;
      min-height: auto;
    }
  }
}
