.CallSheetTemplateSchedule {
  margin-bottom: 48px;

  .CallSheetSchedule {
    display: grid;
    grid-template-columns: 140px 1fr 140px 1fr;

    & > .TextComponent {
      padding: var(--8px);
    }
  }
}
