.BudgetOverlayAdvance {
  &-body {
    display: flex;
    gap: 0 4.57rem;
    align-items: flex-start;
  }

  &-leftSide,
  &-rightSide {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    flex: 1 50%;
  }

  &-rightSide {
    grid-template-areas:
      ". ."
      ". ."
      ". ."
      "lastRow lastRow";
  }

  .Exchange {
    display: flex;
    align-items: center;
    min-height: 40px;
  }

  .Exchange-button {
    position: relative;
    width: 2rem;
    height: 2rem;
  }
  .icon {
    position: absolute;

    &:nth-of-type(1) {
      top: -5%;
      left: 65%;
    }

    &:nth-of-type(2) {
      top: 25%;
      left: 15%;
    }
  }

  .DailyCost {
    grid-area: lastRow;
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--gray-30);
    padding-top: 1.71rem;
    margin-bottom: 4rem;
    .AdvanceInput {
      margin: 0 1.14rem 0 1.71rem;
    }
  }

  .AdvanceInput.InputField {
    margin-bottom: 0;
    max-width: 72px;
  }

  .ExchangeAmount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .InputField {
      max-width: 187px;
      margin-bottom: 0;
    }
  }
}
