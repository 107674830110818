.AttachFile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &-input {
    display: none;
  }

  &-placeholder {
    & > img {
      display: none;
    }
    & > span {
      display: block;
    }

    &:hover {
      & > img {
        display: block;
      }
      & > span {
        display: none;
      }
    }
  }
}
