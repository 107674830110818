.SwitchInput {
  &-input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .SwitchInput-slider {
      background-color: var(--black-10);
    }

    &:focus + .SwitchInput-slider {
      box-shadow: 0 0 1px var(--black-10);
    }

    &:checked + .SwitchInput-slider:before {
      transform: translateX(1.5rem);
    }

    &:checked + .SwitchInput-slider.disabled {
      background-color: var(--gray-20);
      cursor: not-allowed;
    }
  }
  .disabled {
    background-color: var(--gray-30);
    cursor: not-allowed;
  }

  &-label {
    cursor: pointer;
    width: 3.42rem;
    height: 1.71rem;
    position: relative;
    display: inline-block;
  }

  &-label-text {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  &-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gray-30);
    transition: var(--transition);
    border-radius: 3em;
  }

  &-slider:before {
    position: absolute;
    content: "";
    height: 1.21rem;
    width: 1.21rem;
    left: 4px;
    top: 3px;
    background-color: var(--white-0);
    transition: var(--transition);
    border-radius: 50%;
  }
}
