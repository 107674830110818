@import "src/assets/styles/placeholders/label";

.ContactFieldGroup {
  width: 100%;
  margin-bottom: 1.5rem;

  &-labelRow {
    display: flex;
    gap: 8px;
  }
  &-label {
    @extend %label;

    &:nth-of-type(1) {
      width: 40%;
    }
    &:nth-of-type(2) {
      width: 60%;
    }
  }

  &-button {
    width: 100%;
    min-height: 40px;
  }
  &-error {
    color: var(--red-0);
    padding-top: 0.25rem;
    font-size: var(--fSize13);
    text-align: left;
  }
}
