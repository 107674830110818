.CompaniesTableRow {
  box-shadow: var(--bottom-line);
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    background: var(--gray-40);
  }

  &-cell {
    padding: 0.71rem 0.5rem;
    font-size: var(--fSize13);
    font-family: var(--helvetica400);
    font-weight: 500;

    &.address {
      font-weight: 400;
    }

    .icon-favorites {
      color: var(--gray-30);

      &.favorites {
        color: var(--yellow-0);
      }
    }

    .tag,
    .tag-link {
      margin-right: 4px;
    }

    &-avatar {
      width: 2.28rem;
      height: 2.28rem;
      margin-right: 0.57rem;
      border-radius: 50%;
      vertical-align: middle;
      object-fit: cover;

      &-wrapper {
        display: flex;
        align-items: center;
      }
    }

    &-name {
      display: inline-block;
      white-space: nowrap;
      max-width: var(--text-max-width);
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: var(--helvetica500);
    }

    &-email {
      display: inline-block;
      max-width: var(--text-max-width);
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        & > .ButtonCopyClipboard {
          opacity: 1;
        }
      }
    }
  }
}
