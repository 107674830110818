.NotificationsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--light-shadow);
  padding: 0 1.14rem;
  min-height: 2.21rem;

  .Button {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--gray-10);
  }

  &-close {
    margin-left: auto;
  }
}