.RowName {
  display: flex;
  align-items: center;
  height: 100%;
  gap: var(--8px);

  position: relative;
  padding: 0 12px;
  max-width: initial;

  &-checkbox {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 6px;

    .Checkbox-input:checked ~ .Checkbox-label {
      background-color: transparent;
    }

    .Checkbox-label {
      border: none;
      padding: 0;
      color: transparent;
    }
  }

  &:hover {
    cursor: grab;
    & > .sortIcon {
      opacity: 1;
    }
  }

  & > .sortIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
    opacity: 0;
  }

  &-imgWrap {
    width: var(--32px);
    height: var(--32px);
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    align-self: center;
    margin-left: 2.1rem;

    border: 1px solid var(--gray-40);

    & > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &.RowNameAgent {
    padding-left: 42px;

    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -6px; //6px padding top for row
      left: 4rem;
      width: 1.143rem;
      height: calc(50% + 6px); //6px padding top for row
      z-index: 0;
      border-left: 1px solid var(--gray-30);
      border-bottom: 1px solid var(--gray-30);
      border-bottom-left-radius: 3px;
    }

    &:hover {
      cursor: default;
    }

    .RowName-imgWrap {
      width: 2.143rem;
      height: 2.143rem;
    }
  }
}
