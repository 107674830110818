.BudgetTypesEditLine {
  display: flex;
  align-items: center;
  padding: 0.71rem 0.57rem;
  max-height: 40px;
  box-shadow: var(--bottom-line);
  cursor: pointer;

  &:hover {
    .BudgetTypesEditLine-actions {
      opacity: 1;
    }
  }

  &-code {
    min-width: 72px;
    font-weight: 500;
  }

  &-name {
    flex: 1 0 auto;
    font-weight: 500;
  }

  &-actions {
    display: flex;
    gap: 0 1rem;
    opacity: 0;
    min-width: 24px;
    right: 0.71rem;
    transition: var(--transition);
  }
  &-button {
  }
}
