.TableTaskRow {
  box-shadow: none;
  border-top: 1px solid var(--gray-100);

  .TableTaskRow-cell {
    height: 48px;
    border-right: 1px solid var(--gray-100);
    border-bottom: 1px solid var(--gray-100);

    & > * {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &.nameCell {
      width: 100%;
    }

    &.rowInitName {
      padding-left: 42px;
    }

    &.actions {
      width: 48px;
      min-width: 48px;
    }
  }
}
