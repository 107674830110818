.JobLocationTag {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 1.14rem;

  .TagButton {
    max-height: 40px;
    &__child {
      display: flex;
      align-items: center;
    }
  }
}
