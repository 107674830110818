.CallSheetBuildContacts {
  display: grid;
  // grid-template-columns: repeat(3, 1fr) repeat(2, max-content) 1fr min-content;
  grid-template-columns: repeat(6, 1fr) min-content;
  gap: 0 0.5rem;
  position: relative;
  align-items: center;

  & > .CallSheetBuildContactsHeader {
    grid-column: 1/-1;

    &:not(:first-child) {
      margin-top: 3.7rem;
    }

    position: relative;

    .AppTooltips {
      left: auto;
      right: 0;
      top: 90%;
    }
  }

  .CSAgent {
    &.hidden {
      .TextComponent {
        color: var(--gray-20);
      }
    }
  }
  & > .CSAgent,
  & > .CSContact,
  & > .ManuallyEntered {
    padding-bottom: 1.4rem;

    & > .TimeCell,
    .LocationCell {
      .TimeCell-input,
      .LocationCell-input {
        width: 100%;
        border: none;
        outline: none;
        color: var(--black-10);

        &::placeholder {
          color: var(--gray-20);
        }

        &:focus {
          &::placeholder {
            color: transparent;
          }
        }
      }
    }
  }

  & > .CSAgent.name {
    .TextComponent {
      padding-left: 2rem;
    }
  }

  & > .manuallyDivider {
    display: none;

    grid-column: 1/-1;

    text-align: center;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 50%;
      height: 1px;
      background-color: var(--gray-100);
      top: 50%;
    }

    &::before {
      left: -6rem;
    }

    &::after {
      right: -6rem;
    }
  }

  & > .CSContact + .manuallyDivider,
  & > .CSAgent + .manuallyDivider {
    display: block;
  }
}
