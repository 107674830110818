.Pagination {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;

  &-input {
    width: fit-content;
    margin-bottom: 0;

    & > .InputField-input {
      width: 60px;
    }
  }

  & > .Button.outline {
    padding: 0 0.9rem;
    color: var(--gray-20);

    &:disabled {
      background-color: transparent !important;
      opacity: 0.5;

      .icon {
        opacity: 0.5;
      }
    }
  }

  &-lastPage,
  &-slash {
    font-size: 1.5rem;
    color: var(--gray-0);
    align-self: center;
  }

  &-lastPage {
    font-size: 1rem;
  }
}

.PerPage {
  margin-bottom: 0;
  max-width: 85px;
}
