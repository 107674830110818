.DragAndDropWrap {
  opacity: 0.99; //INFO don't remove this, needs for correct dragging shadow

  &:hover {
    cursor: grab;

    & > div {
      cursor: grab;
    }
  }

  &.loading {
    &:hover {
      cursor: progress;

      & > div {
        cursor: progress;
      }
    }
  }

  &.grabbing {
    background-color: var(--gray-40);
    position: relative;

    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100vw;
      top: 0;
      left: 0;
      background-color: var(--gray-40);
      opacity: 0.9;
      z-index: -1;
    }

    & > div,
    & > td {
      background-color: transparent !important;
    }
  }

  &.table {
    &.grabbing {
      &::after {
        display: none;
      }
    }
  }
}
