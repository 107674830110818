.ToggleEstimateDetailsButton {
  justify-content: space-between;
  padding: 0.43rem var(--24px);
  height: var(--24px);
  letter-spacing: 1px;
  box-shadow: var(--bottom-line), var(--top-line) !important;

  &:active {
    transform: none;
  }
}
