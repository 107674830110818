@import "src/assets/styles/placeholders/label";
@import "src/assets/styles/placeholders/input";

.PasswordField {
  position: relative;
  margin-bottom: 1.14rem;

  &.error {
    .input {
      border: 1px solid var(--red-0);
    }

    .label {
      color: var(--red-0);
    }
  }

  &-label {
    @extend %label;
  }

  &-input {
    @extend %input;
    padding-right: 5.7rem;
  }

  &-control {
    position: relative;
  }

  &-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5em;
    line-height: 1;
    background: transparent;
    color: var(--gray-10);
    padding: 0.25em 0.5em;
    display: flex;
    align-items: center;

    &-text {
      font-weight: bold;
      margin-left: 0.5em;
    }

    &:active {
      transform: translateY(-50%) scale(1);
    }
  }

  &-error-message {
    color: var(--red-0);
    padding-top: 0.25rem;
    font-size: var(--fSize13);
    text-align: left;
  }

  &-note {
    margin-top: 0.25rem;
    font-size: var(--fSize13);
    color: var(--gray-10);
  }
}
