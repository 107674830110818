@import "src/assets/styles/placeholders/buttons";

.FilterButtons {
  @extend %button-group;
  align-self: flex-end;

  &-btn {
    padding: 6px 12px;
    border: 1px solid var(--gray-30);
    color: var(--black-10);
    border-radius: 0;
    text-transform: uppercase;
    font-size: var(--fSize10);
    font-family: var(--helvetica500);
    letter-spacing: 0.01em;

    &.active {
      background-color: var(--gray-40);
      color: var(--black-0);
    }
  }
}
