.PipelineCalendar {
  padding: 1rem;
  background-color: var(--white-0);
  border-radius: 5px;
  box-shadow: var(--dark-shadow);

  .Datepicker-input {
    max-width: 105px;
  }
  .react-datepicker {
    width: 100%;
    box-shadow: none;
    &-popper {
      position: relative !important;
      transform: translate(0) !important;
    }
    &__month-container {
      float: none;
      padding: 0;
    }
    &__navigation {
      top: 3px;
    }
    &__triangle {
      display: none;
    }
  }

  &-picker {
    min-height: 270px;
  }

  &-title {
    font-size: var(--fSize14);
    margin-bottom: 0.5rem;
  }

  &-buttons {
    display: flex;
    grid-gap: 0.5rem;
  }

  &-cancel,
  &-save {
    flex: 1;
  }

  &-cancel {
    border: 1px solid var(--light-gray);
    background-color: var(--white);

    &:hover {
      background-color: var(--vs-black);
    }
  }
}
