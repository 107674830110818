.CrewTableCell {
  height: 48px;
  border-bottom: 1px solid var(--gray-100);
  overflow: hidden;

  &:first-child {
    position: sticky;
    left: 0;
    z-index: 5;
    background-color: var(--white-0);
    border-right: 1px solid var(--gray-100);
  }

  &:hover {
    .RowName {
      & > .sortIcon {
        display: block;
      }
    }
  }

  /**
  * agent's cells
  */
  &.agentsCell {
    background-color: var(--white-40);
  }

  & > .agentPosition {
    padding: 0 12px;
  }
}
