.TagButton {
  display: flex;
  align-items: center;
  font-family: var(--helvetica500);
  width: max-content;
  border-radius: 12rem;
  letter-spacing: 0.01rem;
  text-transform: uppercase;
  font-size: var(--fSize10);

  &-child {
    max-width: var(--text-max-width);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-icon {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    width: 1rem;
    height: 1rem;
  }

  &.lg {
    font-size: var(--fSize11);
    padding: 1rem 1.42rem;
  }

  &.md {
    font-size: var(--fSize11);
    padding: 1rem 1.14rem;
  }

  &.sm {
    font-size: var(--fSize10);
    padding: 0.57rem 0.85rem;
  }

  &.dark {
    color: var(--white-0);
    background-color: var(--gray-60);
  }

  &.neutral {
    background-color: var(--gray-70);
  }

  &.light {
    background-color: var(--white-20);
  }

  &.outline {
    border: 1px solid var(--gray-20);
  }
}
