.CalendarPdfTemplate {
  &-header {
    display: none;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
  }

  &-calendar {
    text-transform: uppercase;
  }
}
