.EstimateManagement {
  margin-left: 0.57rem;

  & > .Button {
    max-height: 32px;
    justify-content: space-between;
  }
}

.EstimateManagement-dropdown {
  .body {
    min-width: 170px;
  }
  .Button {
    display: flex;
    justify-content: space-between;
    transition: var(--transition);
    &:hover {
      background-color: var(--gray-40);
    }
  }
}
