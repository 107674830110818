.Template {
  width: 100%;
  height: 100%;
  max-width: 1080px;
  margin: 5.7rem auto 0;
  border: 1px solid var(--gray-100);
  border-top: none;
  border-bottom: none;
  border-top-left-radius: 0.7rem;
  border-top-right-radius: 0.7rem;
  overflow: hidden;

  &-borderTop {
    height: 0.285rem;
    background-color: var(--gray-100);
  }

  &-header {
    padding: 1rem 2rem 0 2rem;

    display: flex;
    align-items: center;

    background: var(--white-40);

    & > .title {
      font-family: var(--helvetica700);
      font-size: var(--fSize31);
      text-transform: uppercase;
      color: var(--black-10);
    }

    & > .placeholders {
      margin-left: auto;
      display: flex;

      .circles {
        display: flex;

        & > .item {
          width: 2.285rem;
          height: 2.285rem;
          border-radius: 50%;
          background-color: var(--gray-40);
          border: 1px solid var(--white-0);

          &:not(:first-child) {
            margin-left: -0.7rem;
          }
        }
      }

      .rectangles {
        display: flex;
        gap: 0.57rem;
        margin-left: 1rem;

        & > .item {
          width: 2.285rem;
          height: 2.285rem;
          background-color: var(--gray-40);

          &:first-child {
            width: 11.4rem;
          }

          &:nth-child(2) {
            width: 6.9rem;
          }

          &:nth-child(3) {
            width: 2.85rem;
          }
        }
      }
    }
  }

  &-subHeader {
    padding: 0 2rem 0 2rem;
    font-size: var(--fSize17);
    font-family: var(--helvetica700);
    text-transform: uppercase;
    color: var(--black-10);
    background: var(--white-40);
  }

  &-navigation {
    padding: 1.57rem 2rem 1rem 2rem;
    box-shadow: inset 0px -1px 0px var(--gray-100);
    display: flex;
    gap: 48px;
    background: var(--white-40);

    & > .item {
      font-size: var(--fSize12);
      font-family: var(--helvetica500);
      text-transform: uppercase;
      color: var(--gray-10);

      &:first-child {
        font-family: var(--helvetica700);
        color: var(--black-10);
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 2rem;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: var(--black-10);
        }
      }
    }
  }

  &-body {
    padding: 2rem 2rem 0 2rem;
    display: flex;
    gap: 4rem;
    height: 100%;

    & > div {
      background-color: var(--white-10);
      min-height: 4rem;
      height: 100%;

      &:first-child {
        width: 60%;
      }

      &:last-child {
        width: 40%;
      }
    }
  }
}
