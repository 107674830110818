.Advance {
}

.AdvanceInvoiceBuild {
}

.AdvanceInvoicePreview {
  .PdfViewer {
    &-page {
      margin-bottom: 0;
      box-shadow: none;
    }
    canvas {
      margin-bottom: 1.71rem;
      box-shadow: var(--light-shadow);
    }
  }
}
