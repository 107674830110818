@import "../EstimateTable.scss";

.EstimateTableHeader {
  &-cell {
    border-bottom: 1px solid var(--gray-100);
    background-color: var(--white-40);

    min-height: 32px;
    display: flex;
    align-items: center;
    z-index: 1;

    &:first-child {
      padding-left: 32px;
    }

    & > .btn {
      padding: 0;
      color: var(--gray-10);
    }

    &:not(:first-child) {
      justify-content: center;
    }

    &.unit {
      justify-content: flex-start;
    }
  }
}
