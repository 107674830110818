:root {
  --estimateSidebarWidth: 25.715rem;
}

.EstimateBuild {
  position: relative;
  display: grid;
  grid-template-columns: var(--estimateSidebarWidth) 1fr;
  height: 100%;
}
