.ExpenseListFilter {
  box-shadow: var(--dark-shadow);
  border-radius: 5px;
  background: var(--white-0);
  padding: 1.71rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 340px;

  &-label {
    font-size: var(--fSize10);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.01em;
  }

  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
    gap: 8px;

    & > div {
      &:nth-of-type(1) {
        width: 30%;
      }

      &:nth-of-type(2) {
        width: 70%;

        .RadioField-label {
          width: 95px;
        }
      }
    }

    .TagsField {
      margin-bottom: 0;
    }
  }

  &-button {
    width: 100%;
    margin-top: 8px;
  }
}
