@import "src/assets/styles/placeholders/label";

.AddressField {
  width: 100%;
  position: relative;

  &-selectOption {
    color: var(--gray-10);
    font-size: var(--fSize10);
    text-transform: uppercase;
    margin-left: auto;
    display: block;
  }

  &-manual {
    &:before {
      content: "";
      background-color: var(--white-10);
      position: absolute;
      width: 100%;
      padding: 0 12px;
      height: calc(100% - 2.5rem);
      top: 2.5rem;
      left: -12px;
      z-index: -1;
    }
  }

  &-apartment,
  &-region {
    display: grid;
    gap: 8px;
  }

  &-apartment {
    grid-template-columns: 1fr 188px;
  }

  &-region {
    grid-template-columns: 2fr 1fr 188px;
  }

  .Select-input.input,
  .InputField-input.input {
    height: 40px;
  }
}
