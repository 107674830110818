.SearchClient {
  position: relative;

  & > .icon {
    position: absolute;
    left: 18px;
    top: 0;
    z-index: 1;
  }

  .InputField-input {
    padding-left: 3.7rem;
    min-height: 3.42rem;
  }

  &-dropdown {
    border: 1px solid var(--gray-30);
    border-radius: 0.21rem;
    position: absolute;
    max-height: 13.5rem;
    width: 100%;
    left: 0;
    top: 100%;
    z-index: 1;
    margin-top: 0.1rem;
    background-color: var(--white-0);
    box-shadow: var(--light-shadow);
  }

  &-list {
    overflow-y: auto;
    max-height: 7.5rem;
    padding-left: 1.14rem;
  }

  &-listItem {
    color: var(--black-10);
    font-family: var(--helvetica400);
    font-size: var(--fSize15);
    padding: 0.57rem 0;
    cursor: pointer;
  }

  &-button {
    color: var(--gray-10);
    padding: 0.8rem 1.14rem;
  }
}
