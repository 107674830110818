/**
  default font size 14px
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

//FIXME This domain is not registered with Tiny Cloud.  Please review https://www.tiny.cloud/my-account/domains
.tox-notifications-container {
  display: none;
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
  font-size: 14px;
  font-family: var(--helvetica400);
  line-height: 1.38;
  color: var(--black-0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: var(--black-0);
  overflow: hidden;
}

html,
body,
a {
  text-decoration: none;
}

a,
button {
  cursor: pointer;
  text-decoration: inherit;
  color: unset;
  background: inherit;
}

button {
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border: none;

  &:active {
    transform: scale(0.97);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--gray-70);
    color: var(--black-0);

    &:active {
      animation-name: click-animation;
      animation-duration: 0.15s;
      animation-iteration-count: 1;
      animation-timing-function: linear;
    }
  }
}

textarea,
input {
  font-size: 14px;
}

ul {
  list-style-type: none;
}

select {
  font-family: var(--helvetica400);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--helvetica400);
  font-weight: bold;
}

h1 {
  font-size: var(--fSize26);
}
h2 {
  font-size: var(--fSize20);
}
h3 {
  font-size: var(--fSize17);
}
h4 {
  font-size: var(--fSize15);
}
h5 {
  font-size: var(--fSize13);
}

th {
  //font-family: inherit;
  //font-weight: inherit;
}

#root {
  height: 100%;
  min-height: 100%;
}

#root {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App {
  display: flex;
  flex: 1 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.content-padding {
  padding: 1.14rem var(--content-padding) var(--content-padding) var(--content-padding);
}

.mb-40 {
  margin-bottom: 40px;
}

.capitalize {
  text-transform: capitalize;
}

.noWrap {
  white-space: nowrap;
}

.posRelative {
  position: relative;
}

.hideScroll {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}
