.useApiListNotification {
  position: relative;
  height: 55px;

  & > .info {
    font-size: 1.5rem;
    padding: 1rem 0.5rem;
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;

    &.err {
      color: var(--red-0);
    }
  }
}
