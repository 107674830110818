$default-color: var(--gray-50);
$production-color: var(--orange-0);
$post-color: var(--purple-0);
$equipment-color: var(--blue-0);

.JobDetailsHeader {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 0 0;
  grid-template-areas:
    "BackButton Title Controls"
    ". Info Info";
  position: relative;
  padding: 1.14rem 2.28rem 0.57rem 1.71rem;
  background-color: var(--white-40);
  border-top: 4px solid $default-color;

  &.Production {
    border-top: 4px solid $production-color;
    .id {
      color: $production-color;
    }
  }
  &.Post {
    border-top: 4px solid $post-color;
    .id {
      color: $post-color;
    }
  }
  &.Equipment {
    border-top: 4px solid $equipment-color;
    .id {
      color: $equipment-color;
    }
  }

  &-backButton {
    grid-area: BackButton;
    display: flex;
    align-items: center;
  }
  &-title {
    font-family: var(--helvetica700);
    grid-area: Title;
    display: flex;
    align-items: center;
    margin-left: 8px;
    font-size: var(--fSize30);
    text-transform: uppercase;
  }
  &-info {
    grid-area: Info;
    margin-top: 4px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    gap: 0 16px;
    &Item {
      font-family: var(--helvetica700);
      font-size: var(--fSize17);
      text-transform: uppercase;

      &Separator {
        width: 1px;
        border: 1px solid var(--black-0);
        height: 20px;
      }

      &.empty {
        font-family: var(--helvetica400);
        color: var(--gray-20);
      }
    }
  }

  &-controls {
    grid-area: Controls;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0 8px;

    .Popover {
      z-index: 5;
    }

    .Button {
      max-height: 32px;
    }
    .pipelines {
      font-weight: 500;
      min-width: 160px;
      &.empty-pipeline {
        background-color: var(--gray-30);
        color: var(--gray-10);
      }
    }
    .bidding {
      background-color: var(--gray-50);
    }
    .working {
      background-color: var(--yellow-0);
    }
    .wrap {
      background-color: var(--blue-10);
    }
    .done {
      background-color: var(--green-10);
    }
  }
}
