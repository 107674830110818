.AccountPassword {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-self: center;
  max-width: 290px;

  .PasswordField {
    width: 100%;

    .PasswordField-button {
      &-text {
        font-size: var(--fSize12);
      }
      .icon {
        &::before {
          font-size: var(--fSize18);
        }
      }
    }
  }
}
