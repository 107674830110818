@import "src/assets/styles/placeholders/_input.scss";

.CreatingLine {
  position: relative;
  box-shadow: var(--bottom-line);
  padding-bottom: 0.57rem;

  &-row {
    display: flex;
    padding: 0.71rem 0.57rem;
    cursor: pointer;
    gap: 0 4px;
  }

  &-code {
    display: flex;
    align-items: center;
    min-width: 72px;
    font-weight: 500;
    height: 40px;
  }

  &-input {
    flex: 1 0 auto;
    .InputField-error-message {
      display: none;
    }
    &.code {
      max-width: 62px;
    }
    &.error {
      color: var(--red-0);
      font-size: var(--fSize13);
      text-align: left;
      padding: 0 0.57rem;
      margin-bottom: 0.14rem;
    }
  }
  &-actions {
    display: flex;
    gap: 0 8px;
    margin-left: 1.41rem;
    height: 40px;
    button {
      min-width: 24px;
    }
  }

  .InputField {
    flex: 1 0 auto;
    margin-bottom: 0;
  }
}
