.pipe {
  width: 100%;
  font-family: var(--helvetica500);
  font-size: var(--fSize10);
  color: var(--black-0);
  border-radius: 2px;
  padding: 4px 6px;
  text-transform: uppercase;

  &__md {
    padding: 8px 10px;
  }

  &__lg {
    padding: 10px 12px;
  }

  &__gray {
    background: var(--gray-50);
  }

  &__yellow {
    background: var(--yellow-0);
  }

  &__blue {
    background: var(--blue-10);
  }

  &__green {
    background: var(--green-10);
  }
}
