.LocationsTableRow {
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    background: var(--gray-40);
  }

  &-tagsRow {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 4px;
  }

  &-cell {
    padding: 0.71rem 0.5rem;
    font-size: var(--fSize13);
    font-weight: 500;
    position: relative;

    &:nth-of-type(1) {
      width: 25%;
    }

    &-name {
      font-family: var(--helvetica500);
    }

    &-address {
      font-weight: 400;

      .button-clipboard-copy {
        position: absolute;
        right: 0;
        transition: var(--transition);
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
      }

      &:hover {
        .button-clipboard-copy {
          opacity: 1;
          color: var(--gray-10);
          background-color: var(--gray-40);
          font-weight: 700;
        }
      }
    }
  }

  &-map {
    min-height: 350px;
    max-height: 90%;

    &-title {
      margin-bottom: 0.75rem;
    }

    &-button {
      margin: 1.71rem 0 0 auto;
    }
  }

  .Tag,
  .TagButton {
    //margin-right: 6px;
    //margin-bottom: 4px;

    .Tooltip {
      margin-right: 0;
    }
  }
}
