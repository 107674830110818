.BudgetTypeCategoryList {
  padding-bottom: 4rem;

  &-slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: var(--bottom-line);
    padding: 0.71rem 0.57rem 1.41rem;
    position: relative;
  }

  &-warning {
    margin: 1.14rem auto 1.71rem;
  }
}
