.JobBlock {
  display: grid;
  gap: 0.5rem;
  align-items: center;
  grid-template-columns: 1fr auto auto 26px;

  padding: 8px 12px;
  border: 1px solid var(--gray-40);

  font-family: var(--helvetica400);
  font-size: var(--fSize14);
  letter-spacing: 0.0025em;
  color: var(--black-10);
  font-weight: 400;

  &-id {
    color: var(--gray-10);
  }

  &-close {
    &:hover .icon {
      &:before {
        color: var(--black-0);
      }
    }
  }
}
