.CrewTableHeaderT {
  &-cell {
    &:first-child {
      z-index: 2;
      min-width: 205px;
    }

    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;

    min-height: 32px;

    white-space: nowrap;
    background: var(--gray-40);
    border-bottom: 1px solid var(--gray-100);
    padding: 0.5rem var(--16px);
  }
}
