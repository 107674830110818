.ManageFieldsForm {
  & > .loaderWrap {
    position: relative;
  }

  &-body {
    margin-bottom: 1.71rem;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background-color: var(--white-40);
      top: 2.75rem;
      height: calc(100% - 1.5rem);
      width: calc(100% + 16px);
      left: -8px;
      z-index: -1;
      border-radius: 3px;
    }
  }
  &-note {
    margin-bottom: 1.71rem;
  }
  .content {
    padding: 0;
  }
  .ConfirmWindow-content.content {
    max-height: calc(100vh - 80px);
    overflow: auto;
    padding: 42px 1.71rem 1.71rem;
  }
}
