.LoginPageLayout {
  background: var(--black-0);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 4fr 1fr;
  gap: 0 0;
  grid-template-areas:
    "."
    "."
    ".";

  @media screen and (max-height: 590px) {
    grid-template-rows: auto;
  }

  &-header {
    margin-top: auto;
    color: var(--white-0);

    .logo-text {
      width: 100%;
      max-height: 3.57rem;
    }

    @media screen and (max-height: 768px) {
      margin-bottom: auto;
    }
  }

  &-body {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }

  &-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 2rem;
    @media screen and (max-height: 768px) {
      margin-bottom: 1rem;
    }
  }

  &-note {
    color: var(--gray-20);
  }
}
