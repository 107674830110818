.JobBudgetStatusBarLayout {
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 48px;
  padding: 0 2.28rem;
  box-shadow: var(--bottom-line);
  background-color: var(--white-10);

  &-right,
  &-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 0.57rem;
    .Button {
      max-height: 32px;
    }
  }
}
