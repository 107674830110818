.ViewerToolbar {
  height: 56px;
  display: flex;
  align-items: center;
  background-color: var(--white-10);
  box-shadow: var(--bottom-line);
  gap: 0 8px;
  padding: 0 1.14rem;
  position: relative;

  & > div {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    &:last-child {
      margin-left: auto;
    }
  }

  button {
    height: 32px;
    width: 36px;
    background-color: var(--white-0);
    border-radius: 3px;
    border: 1px solid var(--gray-30);
  }
}
