.SenderRecipientItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 0.57rem;
  position: relative;

  &:nth-of-type(2) {
    margin-top: 1.14rem;
  }

  .Checkbox-label {
    font-family: var(--helvetica700);
    border: none;
    text-transform: uppercase;
    padding-left: 3rem;
    min-height: 1.57rem;
  }

  .Checkbox-input:checked ~ .Checkbox-label {
    background-color: unset;
  }

  &.Agent {
    .Checkbox-label {
      padding-left: 4rem;
    }
  }

  &-dot {
    position: absolute;
    left: 3rem;
    top: 50%;
    transform: translateY(-50%);
    height: 3px;
    width: 3px;
    border-radius: 50%;
    background-color: var(--black-0);
  }
}
