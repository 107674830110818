.CallSheetBuild {
  width: 1184px;
  margin: 1rem auto;
  padding: 80px;
  position: relative;

  border: 1px solid var(--gray-30);
  box-shadow: var(--light-shadow);

  &.empty {
    box-shadow: none;
    border: none;
  }
}
