.JobDetailsForm {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 480px;

  & > .title {
    font-family: var(--helvetica700);
    font-size: var(--fSize26);
    text-align: center;
    text-transform: uppercase;
    color: var(--black-10);
  }

  & > .subTitle {
    font-size: var(--fSize20);
    font-family: var(--helvetica300);
    text-align: center;
    color: var(--black-10);
    margin: 1.142rem 0 3.28rem;
  }

  &-label {
    font-family: var(--helvetica400);
    font-size: var(--fSize11);
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    color: var(--gray-0);
    margin-bottom: 0.42rem;
  }

  & > .InputField {
    margin-bottom: 2.85rem;

    & > .InputField-input {
      min-height: 3.42rem;
    }
  }

  & > .Button {
    width: 100%;
    margin: 4rem 0;
  }
}
