.GridHeader {
  min-height: 56px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  gap: 8px;
  box-shadow: var(--bottom-line);

  & > .SelectGrid {
    margin-left: auto;
  }

  .SelectGrid-btn {
    min-width: 194px;
    display: flex;
    justify-content: space-between;
    transition: var(--transition);
    position: relative;
    max-height: 32px;
  }

  .EstimateSelection-dropdown {
    .body {
      min-width: 194px;
      overflow: visible;
    }
  }
}

.MenuGrid {
  &-dropdown {
    .DropdownMenu-body {
      overflow-y: hidden;
    }
  }
}
