@import "src/assets/styles/placeholders/_label";

.DynamicFieldsWrap {
  &-email {
    &-emptyRowErr {
      margin-bottom: 1rem;
      color: var(--red-0);
    }

    .Button {
      &:hover,
      &:focus,
      &:active {
        & > .icon {
          color: var(--white);
        }
      }
    }

    & > .DynamicInputsGroup {
      gap: 0;
      &.email {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .subtitle {
          @extend %label;
          width: 100%;
        }
        .row {
          width: 100%;
          display: flex;
          gap: 8px;
        }

        &.emails {
          &:first-child .Select {
            margin-top: 18px;
          }
        }

        .PhoneField {
          flex: 1 1 100%;
        }

        .Button {
          min-height: 40px;
          align-self: flex-start;
          border: none;
          color: var(--gray-30);

          &:hover {
            background-color: transparent;
            color: var(--black-10);
          }
        }
      }
    }

    &-confirmModal {
      &-body {
        margin-bottom: 2.85rem;
        text-align: center;
      }
      &-warning {
        color: var(--red-0);
        margin-top: 1.14rem;
      }
    }
  }
}
