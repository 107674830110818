.ManageLabels {
  &-deleteModal {
    .title {
      color: var(--red-0);
    }

    &-body {
      margin-bottom: 2.85rem;
      text-align: center;
    }

    &-warning {
      font-family: var(--helvetica700);
      margin-top: 1.14rem;
    }
  }
}
