.CallSheetConfirm {
  overflow-y: auto;
  height: 100vh;
  padding-top: 40px;

  &.isDownload {
    max-width: 1184px;
    margin: 0 auto;
    padding-top: 0;

    .CallSheetMsgWrap-header {
      display: none;
    }

    .CallSheetTemplate,
    .CallSheetMsgWrap {
      box-shadow: none;
      border: none;
    }

    overflow: visible;
    height: 100%;

    .SectionHeader {
      box-shadow: none;
      border-bottom: 2px solid var(--black-0);
      &.TextComponent {
        background-color: var(--white-0);
      }
    }
  }
}
