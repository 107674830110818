@import "src/assets/styles/placeholders/_input.scss";

.DateRangePicker {
  width: 100%;
  margin-bottom: 1.14rem;

  &-control {
    display: flex;
    align-items: flex-end;
    gap: 0 8px;
  }

  &-field {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &-label {
    @extend %label;
  }

  &-inputGroup {
    display: flex;
    width: 100%;
  }

  &-separator {
    align-self: center;
  }

  &-InputWithIcon {
    position: relative;

    .icon {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      left: 9px;
      cursor: pointer;
    }
  }

  .react-datepicker {
    border: none;
    border-radius: 3px;
    box-shadow: var(--light-shadow);
  }

  .react-datepicker__header {
    background-color: var(--white-0);
    border-bottom: none;
  }

  .react-datepicker__input-container {
    input {
      @extend %input;
      padding-left: 36px;
      cursor: pointer;
      min-width: 128px;
    }
  }

  .react-datepicker__month-container {
    padding: 1rem;
  }

  // arrows styles
  .react-datepicker__navigation {
    top: 15px;

    &--next {
      right: 15px;
    }

    &--previous {
      left: 15px;
    }

    .react-datepicker__navigation-icon--next::before,
    .react-datepicker__navigation-icon--previous::before {
      border-color: var(--gray-10);
      height: 6px;
      width: 6px;
      border-width: 2px 2px 0 0;
    }
  }

  .react-datepicker__day {
    border-radius: 50%;
    font-size: var(--fSize13);

    &-names {
      font-weight: 700;
      font-size: var(--fSize13);
      color: var(--gray-10);
    }

    &--in-selecting-range,
    &--in-range {
      background-color: rgba(237, 237, 240, 0.5);
      color: var(--black-0);
    }

    &--selecting-range-end,
    &--selecting-range-start,
    &--selected {
      background-color: var(--gray-40);
      color: var(--black-0);
      font-weight: 700;
    }
  }

  &.error .react-datepicker__input-container input {
    border-color: var(--red-0);
  }

  &-error-message {
    color: var(--red-0);
    padding-top: 0.25rem;
    font-size: var(--fSize13);
    text-align: left;
  }
}
