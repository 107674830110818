.QuickPay {
  width: 40px;
  margin-right: 0;

  .QuickPay-checkbox {
    overflow: hidden;

    .Checkbox-input:checked ~ .Checkbox-label {
      background-color: transparent;
    }

    .Checkbox-label {
      border: none;
      padding: 0;
      color: transparent;

      &::before {
        left: 0;
      }

      &::after {
        left: 0.55rem;
      }
    }
  }
}
