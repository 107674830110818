.Favorites {
  flex-direction: column;
  align-items: initial;
  position: relative;
  margin-top: 0.5rem;

  &:before {
    content: "";
    display: block;
    width: 240px;
    height: 1px;
    position: absolute;
    top: 0;
    right: 0;
    left: -0.5rem;
    background: #4c4c4d;
    z-index: 4;
  }

  .FavRow {
    font-family: var(--helvetica400);
    line-height: 18px;
    padding: 0.5rem 0 0.5rem 3.6rem;
    display: block;
  }

  .noFavorites {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: unset !important;
    gap: 0 !important;
    padding: 1.2rem 1rem 0.7rem 1rem;
    color: var(--gray-20);
    text-transform: uppercase;
    font-family: var(--helvetica700);
    border-radius: 2px !important;
    transition: var(--transition) !important;
    width: 100%;
    border: none !important;
    outline: none;
    font-size: var(--fSize15);

    &-container {
      position: sticky !important;
      top: 0;
      background-color: var(--black-0);
      z-index: 3;
      overflow: hidden;

      &.active {
        background-color: var(--gray-0);
        color: var(--white-0);
      }
    }

    &.active {
      background-color: var(--black-0);
      color: var(--white-0);
    }

    &:hover {
      color: var(--white-0);
    }

    &:active {
      transform: unset;
    }

    &-text {
      line-height: 1.42;
      margin-left: 0.85rem;
      transition: var(--transition);
    }

    &-arrow-icon {
      margin-left: auto;
      line-height: 0.1em;
      transition: transform 0.15s ease-in;

      &.up {
        transform: rotate(180deg);
      }
    }

    &-icon {
      flex-basis: 24px;
    }
  }

  &-list {
    &-loader {
      height: 60px;
      width: 100%;
      position: relative;
    }

    &-container {
      // 550px ~width sidebar without favorite list
      max-height: calc(100vh - 550px);
      &:after {
        content: "";
        display: block;
        width: 240px;
        height: 1px;
        position: absolute;
        right: 0;
        left: -0.5rem;
        background: #4c4c4d;
        z-index: 4;
        bottom: -0.5rem;
      }
    }
  }

  &-collapsedList {
    &-container {
      position: fixed;
      min-height: 150px;
      min-width: 150px;
      width: 20%;
      max-width: 250px;
      max-height: 250px;
      background-color: var(--black-0);
      left: 57px;
      top: 50%;
      z-index: 3;
      box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, 0.25);
      border-radius: 3px;

      .FavRow {
        padding: 0.5rem 1rem;
      }

      @media screen and (min-height: 700px) {
        max-height: 400px;
      }

      @media screen and (min-height: 900px) {
        max-height: 650px;
      }

      @media screen and (min-height: 1200px) {
        max-height: 750px;
      }
    }
  }

  &-collapsedList-container,
  &-list-container {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
