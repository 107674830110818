.CallSheetTemplate {
  width: 1184px;
  margin: 1rem auto;
  padding: 80px;

  position: relative;

  border: 1px solid var(--gray-30);
  background-color: var(--white-0);
  box-shadow: var(--light-shadow);
}
