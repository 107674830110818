.TrackingTable {
  margin-bottom: 2.57rem;
  .Table {
    border: none;
    &-header-row {
      & > th {
        &:nth-of-type(5) {
          text-align: center;
        }

        &.title {
          padding-left: 1.71rem;
        }
      }
    }

    tr {
      box-shadow: none;
    }
  }
}
