.SectionHeader {
  display: flex;
  align-items: center;
  gap: var(--8px);
  padding-bottom: var(--8px);
  margin-bottom: var(--24px);
  box-shadow: inset 0px -2px 0px var(--black-10);

  .TextComponent {
    flex-grow: 1;
  }
}
