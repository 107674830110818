.EstimateSelection {
  margin-left: 1.14rem;

  & > .Button {
    max-height: 32px;
    min-width: 194px;
    justify-content: space-between;
  }
}

.EstimateSelection-dropdown {
  z-index: 9999;
  .body {
    min-width: 194px;
    overflow: visible;
  }
  .Button {
    display: flex;
    justify-content: space-between;
    transition: var(--transition);
    position: relative;
    max-height: 32px;
  }
}
