.InvoiceBuildAdvance {
  .label {
    font-weight: 700;
    font-size: var(--fSize13);
    letter-spacing: 0.02rem;
    color: var(--black-0);
    max-width: 170px;
    width: 100%;
    text-transform: uppercase;
  }
  .InputField,
  .Datepicker {
    flex-direction: row;
    margin-bottom: 0.57rem;
  }
  .input {
    &:disabled {
      background-color: var(--white-0);
      border: 1px solid var(--gray-30);
      color: var(--gray-10);
    }
  }

  .TextareaField {
    align-items: flex-end;
    &-input {
      min-height: 74px;
      width: calc(100% - 170px);
      min-width: auto;
    }
  }

  .SelectWithButton {
    display: flex;
    align-items: center;
    width: 100%;
    .ReactSelect {
      width: 100%;
    }
  }

  &-viewItem {
    display: flex;
    margin-bottom: 0.28rem;
  }
}
