.CompanyDetailsJobs {
  border-collapse: separate;
  border-spacing: 0 4px;

  &-row {
    td {
      padding: 8px 24px 8px 12px;
      border-top: 1px solid var(--gray-40);
      border-bottom: 1px solid var(--gray-40);
      border-collapse: collapse;

      &:nth-of-type(1) {
        border-left: 1px solid var(--gray-40);
        border-radius: 2px;
      }

      &:nth-last-of-type(1) {
        border-right: 1px solid var(--gray-40);
        border-radius: 2px;
      }
    }
  }

  &-number {
    color: var(--gray-10);
  }
}