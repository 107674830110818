.TeamMemberHeader {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 1.14rem;
  box-shadow: var(--bottom-line);

  &-avatar,
  .LetterAvatar {
    margin-right: 1.14rem;
    width: 8.57rem;
    height: 8.57rem;
    border-radius: 50%;
    font-size: 3.5rem;
    object-fit: cover;
  }

  &-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    &-title {
      font-family: var(--helvetica700);
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 0.85rem;
    }

    &-subtitle {
      font-size: var(--fSize17);
      color: var(--gray-10);
    }
  }

  &-children {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.6rem;
  }

  .Button {
    padding: var(--button-size-sm);
    min-width: 114px;
    height: 32px;

    &:hover {
      .icon {
        color: var(--white-0);
        transition: color 0.3s;
      }
    }
  }
}
