.TemplateModal {
  width: 100%;
  position: relative;
  max-height: calc(100vh - 160px);
  overflow: hidden;
  display: grid;
  grid-auto-rows: auto 1fr auto;

  &.md {
    max-width: 528px;
  }
  &.sm {
    max-width: 334px;
  }
  &-header {
    background-color: var(--white-40);
    box-shadow: var(--bottom-line);
    //padding: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 64px;
    border-radius: 5px 5px 0 0;
    padding: 1.14rem;
    &-button {
      position: absolute;
      left: 16px;
    }
  }
  &-title {
    font-family: var(--helvetica700);
    text-transform: uppercase;
    font-size: var(--fSize20);
    font-weight: 700;
    text-align: center;
  }
  &-subTitle {
    text-transform: uppercase;
    font-size: var(--fSize10);
    font-weight: 500;
    letter-spacing: 0.01em;
    color: var(--gray-10);
  }

  &-main {
    padding: 1.71rem 1.71rem 0;
    overflow-y: auto;
  }
  &-footer {
    display: flex;
    justify-content: flex-end;
    gap: 0 8px;
    padding: 1.71rem;

    .Button {
      width: 100%;
    }
  }
}
