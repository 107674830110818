@import "src/assets/styles/index";

.JobsContactItem {
  width: 100%;
  display: grid;
  grid-template-columns: 270px auto 32px;
  gap: 0 8px;
  margin-bottom: 1.14rem;
  align-items: flex-end;
  position: relative;
  &.error {
    margin-bottom: 2.14rem;
  }

  &-error {
    color: var(--red-0);
    position: absolute;
    padding-top: 0.25rem;
    font-size: var(--fSize13);
  }

  .label {
    @extend %label;
  }

  .TagButton {
    max-height: 40px;
    width: 100%;
    justify-content: space-between;
    transform: none;
  }

  .RadioField {
    height: 40px;
  }
  .RadioField-label.label {
    border: none;

    display: flex;
  }

  .RadioField-flag {
    margin-right: 0;
  }

  .RadioField-input:checked ~ .RadioField-label {
    background-color: transparent;
  }

  .ReactSelect .ReactSelect-select__dropdown-indicator {
    display: inherit;
  }
}
