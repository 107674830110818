.ListLayout {
  max-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-header {
    flex-shrink: 0;
    background-color: var(--white-40);
    padding: 20px 16px;
    border-radius: 5px 5px 0px 0px;
    box-shadow: var(--bottom-line);
  }

  &-body {
    overflow-y: auto;
    padding: 24px 24px 16px 24px;
  }

  &-footer {
    position: sticky;
    bottom: 0;
    padding: 8px 24px 24px 24px;
  }
}
