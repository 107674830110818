.EstimateProductionCosts {
  margin-bottom: 1.14rem;
  &-table {
    td {
      letter-spacing: 0.025rem;
      &:nth-of-type(1) {
        min-width: 88px;
      }
      &:nth-of-type(2) {
        width: 100%;
        font-family: var(--helvetica500);
      }
      &:nth-of-type(3) {
        min-width: 160px;
        text-align: right;
      }
    }
  }

  &-feeRow {
    letter-spacing: 0.025rem;
    display: flex;
    justify-content: space-between;
    width: calc(50% - 2.285rem);
    margin-left: auto;
    span:nth-of-type(1) {
      font-family: var(--helvetica500);
    }
  }

  &-total {
    margin-top: 1.28rem;
    display: flex;
    justify-content: space-between;
    width: calc(50% - 2.285rem);
    margin-left: auto;
    font-weight: 700;
    text-align: right;
    font-size: var(--fSize15);
  }
}
