@import "src/assets/styles/placeholders/label";

.LocationDetails {
  overflow-y: auto;
  height: 100%;

  .ModalInfoSection {
    margin: 1.71rem 1.71rem 0;
    padding-bottom: 1.71rem;

    &.oneColumn {
      grid-template-columns: 72px 3fr;
    }

    &.twoColumn {
      grid-template-columns: 72px 1fr 72px 1fr;

      &:before {
        height: calc(100% - 1.71rem);
      }
    }

    .value {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &-header {
    width: 100%;
    height: 100%;
    max-height: 420px;
    position: relative;
    overflow: hidden;

    &-title {
      font-family: var(--helvetica700);
      color: var(--black-0);
      position: absolute;
      bottom: 1.14rem;
      left: 1.71rem;
      text-transform: uppercase;
    }

    .Button {
      position: absolute;
      top: 1.14rem;
      right: 1.71rem;
      padding: 0.65rem 1.14rem;
      min-width: 114px;
      height: 32px;
      margin-bottom: 0.57rem;
      justify-content: center;
    }

    &-contain-img {
      border-bottom: 1px solid var(--gray-40);

      .LocationDetails {
        &-image {
          object-fit: cover;
          max-height: 420px;
          height: 100%;
          width: 100%;
        }

        &-image-overlay {
          display: block;
        }

        &-header-title {
          color: var(--white-0);
        }
      }
    }
  }

  &-image {
    position: relative;
    display: block;
    max-width: 100%;
    width: 100%;
    object-fit: none;
    max-height: 100%;
    border-radius: 5px 5px 0 0;

    &-overlay {
      content: "";
      display: none;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 90%);
      position: absolute;
      width: 100%;
      height: 50%;
      left: 0;
      bottom: -5%;
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid var(--gray-30);
    }
  }

  &-map {
    margin: 0 auto 1.71rem;
    width: calc(100% - 3.42rem);
    min-height: 350px;
  }

  .Tooltip {
    display: flex;
  }

  .Tag,
  .TabButton,
  .Tooltip {
    margin-right: 4px;
    margin-bottom: 4px;
  }
}
