.TrackingTableRow {
  &.group-header {
    font-family: var(--helvetica700);
    font-size: var(--fSize15);
    text-transform: uppercase;
    td {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        bottom: 0;
        right: 0;
        border-bottom: 1px solid var(--gray-30);
        height: 3px;
      }
    }

    td:nth-of-type(1) {
      padding: 1.71rem 0 0.57rem 2.28rem;
      &:after {
        width: calc(100% - 2.28rem);
      }
    }
  }

  &.first {
    td {
      padding-top: 1.14rem;
    }
  }

  td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &-cell {
    padding: 0 0 0.57rem;

    .latestDelivery {
      display: block;
      min-width: max-content;
    }

    .Tooltip {
      display: inline;
    }

    &.rightSideTableCell {
      font-size: var(--fSize13);
      letter-spacing: 0.02rem;
    }
    &.error {
      color: var(--red-0);
    }

    &.method {
      margin-right: 0.57rem;
      text-transform: capitalize;
      .Tooltip {
        margin-right: 0;
        margin-left: 6px;
        text-transform: lowercase;
      }
    }

    &.name {
      font-family: var(--helvetica700);
      text-transform: uppercase;
      padding: 0 0 0.57rem 2.28rem;
      font-size: var(--fSize13);
    }

    &.gray {
      color: var(--gray-10);
    }

    &.resend {
      border-right: 1px solid var(--gray-40);
      text-align: center;
      button {
        vertical-align: middle;
      }
      .icon {
        cursor: pointer;
      }
    }

    &.status {
      text-transform: capitalize;
      font-weight: 500;
      span {
        display: block;
        min-width: max-content;
      }
      &.Unopened {
        color: var(--gray-30);
      }
      &.Confirmed {
        color: var(--green-0);
      }
      &.Viewed {
        color: var(--tangerine-0);
      }

      position: relative;

      .AppTooltips {
        transform: translate(7%, 0%);
      }
    }

    &.title {
      padding-left: 1.71rem;
    }

    &.emailSendErr {
      position: relative;

      .AppTooltips {
        transform: translate(10%, 0%);
      }
    }

    .agent-name {
      padding-left: 1.71rem;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: 0.8rem;
        top: 50%;
        transform: translateY(-50%);
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background-color: var(--black-0);
      }
    }
  }
}
