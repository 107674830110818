@import "src/assets/styles/index";

.SearchClientField {
  @extend %react-select;

  &-menu-footer {
    .Button {
      color: var(--gray-10);
      border: none;
      width: 100%;
      justify-content: flex-start;
      &:nth-of-type(1) {
        box-shadow: var(--top-line);
      }
      &:hover {
        .icon:before {
          color: var(--white-0);
        }
      }
    }
  }
  margin-bottom: 1.14rem;
  width: 100%;

  &.error {
    .label {
      color: var(--red-0);
    }
    .SearchField-select__control {
      border-color: var(--red-0);
      &:hover {
        border-color: var(--red-0);
      }
    }
  }
  &-error-message {
    color: var(--red-0);
    padding-top: 0.25rem;
    font-size: var(--fSize13);
  }

  &-label {
    @extend %label;
  }

  &-searchIcon {
    margin-left: 0.5rem;
  }
}
