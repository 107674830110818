.ContactBlock {
  min-height: 72px;
  padding: 12px;
  cursor: pointer;

  &.fulWidth {
    grid-column: 1/-1;
  }

  &.textCenter {
    justify-content: center;
  }

  & > .placeholder {
    font-family: var(--helvetica400);
    font-size: var(--fSize14);
    letter-spacing: 0.0025em;
    color: var(--gray-20);
    padding: 20px;
  }

  display: flex;
  gap: 0 12px;

  & > .imageWrap {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    align-self: center;
    overflow: hidden;

    & > .avatar {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > .title {
      font-family: var(--helvetica700);
      font-size: var(--fSize15);
      letter-spacing: 0.0025em;
      color: var(--black-10);
      margin-bottom: 2px;
      text-transform: uppercase;
    }

    & > .subTitle,
    & > .contact {
      font-family: var(--helvetica400);
      font-size: var(--fSize11);
      letter-spacing: 0.01em;
      color: var(--gray-0);
    }
  }
}
