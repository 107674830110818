.ImageCrop {
  &-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-start;
  }

  max-height: calc(100vh - 400px);
  margin: auto;
  position: relative;

  &-close {
    position: absolute;
    z-index: 3;
    right: -40px;
  }

  .Cropper {
    border-radius: 8px;
    border: 4px solid var(--gray-30);

    display: block;
    max-height: calc(100vh - 400px);
    z-index: 2;

    &__child-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: var(--gray-20);
    }
  }

  &-control {
    position: absolute;
    z-index: 12;
    left: 50%;
    transform: translateX(-50%);

    &-button {
      display: flex;
      justify-content: center;
      width: 100%;

      .Button {
        min-width: 90px;
        margin: 0 0.5rem;
      }
    }
  }
}
