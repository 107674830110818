.BudgetOverlayTermsBank {
  display: flex;
  grid-gap: 1.71rem 4.57rem;
  width: 100%;

  & > div {
    flex: 1;
  }

  &-textWrap {
    white-space: pre-wrap;
  }
}
