.EstimateTable {
  overflow: auto;

  //13 columns
  display: grid;
  grid-template-columns:
    408px auto 55px auto 56px 56px 64px 64px auto
    80px 89px 64px minmax(32px, auto);
  grid-auto-rows: minmax(min-content, max-content);
  align-content: flex-start;

  & > .EstimateGroupRow,
  & > .EstimateGroupRowFooter,
  & > .EstimateRowExpanded {
    grid-column: 1/-1;
    width: calc(100vw - 20px - var(--estimateSidebarWidth));

    position: sticky;
    left: 0;
  }

  .EstimateTableHeader-cell {
    position: sticky;
    top: 0;
    z-index: 3;

    &:first-child {
      left: 0;
      z-index: 4;
    }
  }

  .DragAndDropWrap {
    position: sticky;
    left: 0;
    background-color: var(--white-0);
    z-index: 3;

    &.grabbing {
      background-color: var(--gray-70) !important;

      &::after {
        width: calc(100vw - 20px - var(--estimateSidebarWidth));
      }
    }
  }
}
