.ViewersWrap {
  background-color: var(--white-0);
  height: calc(100vh - 80px);
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr auto;
  border-radius: 6px;

  &.fullHeight {
    height: 100%;
  }

  &-main {
    overflow: hidden;
  }
}
