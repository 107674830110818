.StatusBarLayout {
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 48px;
  padding: 0 2.28rem;
  box-shadow: var(--bottom-line);
  background-color: var(--white-10);
  gap: 0 8px;

  &-left {
    display: flex;
    align-items: center;
  }

  &-buttonGroup {
    display: flex;
    gap: 0 8px;
  }

  &-status {
    display: flex;
    align-items: center;
    gap: 0 0.85rem;

    .Button {
      margin-left: 1.71rem;
    }
  }
  &-sent {
    color: var(--tangerine-0);
    font-size: var(--fSize17);
    text-transform: uppercase;
    font-weight: 700;
  }
  &-paid {
    color: var(--green-0);
    font-size: var(--fSize17);
    text-transform: uppercase;
    font-weight: 700;
  }
  &-date {
    color: var(--gray-10);
    font-size: var(--fSize13);
  }

  .paid-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.42rem;
    height: 1.42rem;
    border-radius: 50%;
    background-color: var(--green-0);
    color: var(--white-0);
  }
}
