.TeamMemberForm {
  padding: 0 1.71rem;
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 0.5rem;
  gap: var(--24px) 0.5rem;

  &-title {
    font-family: var(--helvetica700);
    margin-top: var(--16px);
    text-transform: uppercase;
  }

  &.update {
    & > div:nth-child(n + 3) {
      grid-column: 1/-1;
    }

    .InputField,
    .Datepicker {
      margin-bottom: 0;
    }

    .CheckBoxGroupe {
      gap: 0 0.4rem;
    }
  }

  &.invite {
    & > div:nth-child(n + 5) {
      grid-column: 1/-1;
    }
  }
  & > .TeamMemberDatePicker {
    grid-column: 1/1 !important;
  }
}
