.AccountsList {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  &-item {
    display: flex;
    align-items: center;
    gap: 0 0.3rem;
    max-height: 20px;
    max-width: 250px;
    word-break: break-all;
  }
  
  &-link {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    word-wrap: break-word;
    max-height: 20px;
  }
}