.Resizer {
  position: absolute;
  top: 0;
  right: -4px;
  height: 100%;
  width: 6px;
  cursor: col-resize;
  z-index: 12;

  // background-color: var(--blue-10);

  & > div {
    position: absolute;
    top: 0;
    right: 2px;
    width: 2px;
    background-color: var(--blue-10);
    display: none;

    // display: block;
  }

  &:hover {
    background-color: var(--blue-10);

    & > div {
      display: block;
    }
  }
}
