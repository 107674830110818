.EstimateSidebar {
  box-shadow: var(--right-line);

  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--16px);
  padding-bottom: var(--16px);

  .EstimateListWrap {
    min-height: 20%;
  }

  .ToggleEstimateDetailsButton {
    position: sticky;
    top: 0;
    background-color: var(--white-0);
    width: calc(100% - 1px); // for showing shadow
    z-index: 1;
  }

  &-wrapBottomPart {
    margin-top: auto;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    gap: var(--16px);

    &:not(.withTooltip) {
      overflow-y: auto;
    }
  }

  &.showDetails {
    .EstimateSidebar-wrapBottomPart {
      padding-bottom: 8px;
      max-height: calc(100% - 130px);
      flex-shrink: 0;
    }

    .EstimateListWrap {
      min-height: 130px;
    }
  }
}
