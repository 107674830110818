.EstimateJobEdit {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  gap: 0 4.57rem;

  .label {
    font-weight: 700;
    font-size: var(--fSize13);
    letter-spacing: 0.02rem;
    color: var(--black-0);
  }

  &-creative {
    .input {
      min-height: 249px;
    }
  }

  &-client {
    margin-bottom: 1.71rem;

    .row {
      display: flex;
      gap: 0 2.14rem;
      & > div {
        width: 30%;
      }
    }
  }

  &-job {
    .InputField,
    .DatepickerRangeSingle,
    .Datepicker {
      flex-direction: row;
      margin-bottom: 0.57rem;
    }
    .input {
      &:disabled {
        background-color: var(--white-0);
        border: 1px solid var(--gray-30);
        color: var(--black-0);
      }
    }
    .label {
      max-width: 170px;
    }
  }

  &-jobLabels {
    display: grid;
    grid-template-columns: 154px 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0 1.14rem;
    margin-bottom: 0.57rem;
  }
}
