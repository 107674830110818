.AgendaView {
  position: relative;
  padding: 0 2.28rem;
  min-height: 400px;

  &-row {
    position: relative;
    padding: 1.14rem 0;
    border-bottom: 1px solid var(--gray-40);
  }

  &-emptyState,
  &-loading {
    width: 100%;
    max-width: 170px;
    padding: 0 1rem 0 1.5rem;
    position: relative;
    font-weight: 700;
    font-size: var(--fSize13);
    text-transform: uppercase;
  }

  &-todayLine {
    position: absolute;
    display: block;
    height: 2px;
    background-color: var(--black-0);
    width: calc(100% - 144px);
    right: 0;
  }

  &-event {
    display: flex;
    align-items: center;
    min-height: 2.5rem;
    cursor: pointer;
  }

  &-date {
    display: flex;
    align-items: center;
    padding-right: 2.5rem;
    min-width: 145px;
    &-number {
      font-family: var(--helvetica700);
      font-size: var(--fSize26);
      min-width: 44px;
      text-align: center;
    }
    &-day {
      font-size: var(--fSize13);
      color: var(--gray-10);
      text-transform: uppercase;
      font-family: var(--helvetica400);
    }
  }

  &-time {
    width: 100%;
    max-width: 170px;
    padding: 0 1rem 0 1.5rem;
    position: relative;

    color: var(--gray-10);
    font-weight: 700;
    font-size: var(--fSize13);
    text-transform: uppercase;

    &:before {
      content: "";
      position: absolute;
      height: 12px;
      width: 12px;
      display: block;
      border-radius: 50%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &.events {
      &:before {
        background-color: var(--black-0);
      }
    }
    &.holds {
      &:before {
        background-color: var(--yellow-10);
      }
    }
    &.tasks {
      &:before {
        background-color: var(--gray-30);
      }
    }
  }
  &-name {
    font-weight: 700;
    font-size: var(--fSize13);
    text-transform: uppercase;
  }
}
