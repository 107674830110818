@import "src/assets/styles/placeholders/input";

.LocationInput {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr) auto;
  grid-template-rows: 1fr;
  gap: 0 8px;

  .Button {
    max-height: 40px;
  }

  .ReactSelect.error .ReactSelect-select__control {
    border-color: var(--red-0);
  }

  .ReactSelect-select__menu {
    top: auto;
  }

  &-error {
    @extend %error;
  }
}
