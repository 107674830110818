.NotificationsItem {
  position: relative;
  padding: 1.14rem;
  display: flex;
  flex-direction: column;
  transition: var(--transition);

  .Tooltip {
    margin-right: 0;
  }

  &:after {
    content: "";
    position: absolute;
    width: calc(100% - 1.14rem);
    bottom: 0;
    height: 1px;
    background: var(--gray-40);
  }

  &:hover {
    background: var(--gray-40);

    .avatar {
      border: 1px solid var(--white-0);
    }
  }

  &-period {
    font-size: var(--fSize13);
    margin: 0.6rem 0 1.14rem;
    color: var(--black-0);
    font-family: var(--helvetica500);
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &-button-action {
    height: 8px;
    width: 8px;
    background: var(--gray-20);
    border-radius: 50%;
    margin-right: 4px;
    display: flex;
  }

  &-info {
    color: var(--gray-10);
    font-size: var(--fSize13);
  }

  &-title {
    color: var(--black-10);
    font-family: var(--helvetica500);
    margin-bottom: 0.25rem;
  }

  &-subtitle {
    color: var(--gray-10);
  }

  &-footer {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
  }

  &-avatar {
    display: block;
    border-radius: 50%;
    object-fit: cover;
    width: 1.73rem;
    height: 1.73rem;
    margin-right: 0.5rem;
    border: 1px solid transparent;
  }

  &-icon {
    display: block;
    font-size: 1.71rem;
    width: 1.71rem;
    margin-right: 0.5rem;
  }
}
