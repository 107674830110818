.ConfirmWindow {
  &-title {
    text-transform: uppercase;
    margin-bottom: 1.14rem;
    text-align: center;
    font-family: var(--helvetica700);
    font-weight: 700;
  }

  &-subtitle {
    text-align: center;
    font-family: var(--helvetica400);
    font-weight: 400;
    font-size: var(--fSize14);
    margin-bottom: 1.14rem;

    word-wrap: break-word;
  }

  &-footer {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 8px;
    margin-top: 2.85rem;

    .Button {
      width: 50%;
    }
  }

  &-error {
    text-align: center;
    font-family: var(--helvetica400);
    font-weight: 400;
    font-size: var(--fSize14);
    margin-bottom: 1.14rem;
    color: var(--red-0);
  }
}
