.JobSettingsTemplatesHeader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.71rem 0;
  background-color: var(--black-10);

  &-buttonsGroup {
    display: flex;
    align-items: center;
    gap: 0 8px;
    .Button {
      max-height: 32px;
      &.save {
        min-width: 120px;
      }
    }
  }
}
