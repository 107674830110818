.SelectWithButton {
  margin-bottom: 1.14rem;

  .MenuListItem {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: var(--fSize13);
    height: 35px;
    padding-left: 0.88rem;
    color: var(--black-10);
    border-top: 1px solid var(--gray-40);
  }

  .is-empty {
    .ReactSelect-select__menu-notice {
      padding: 0;
    }
    .MenuListItem {
      border-top: none;
    }
  }

  .ReactSelect-select__menu-list {
    max-height: 175px;
  }
  .ReactSelect-select__menu-notice--no-options {
    display: none;
  }
}
