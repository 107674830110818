.CategoryHeader {
  padding: 0 32px;
  height: 56px;

  display: flex;
  align-items: center;

  border-top: 1px solid var(--gray-30);
  border-bottom: 1px solid var(--gray-40);

  & > .TextComponent {
    position: sticky;
    left: 32px;

    display: flex;
    align-items: center;
    // cursor: pointer;
  }
}
