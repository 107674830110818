@import "src/assets/styles/index";

.JobArtistField {
  width: 100%;
  margin-bottom: 1.14rem;

  &-error {
    color: var(--red-0);
    display: inline-block;
    padding-top: 0.25rem;
    font-size: var(--fSize13);
  }

  .title {
    width: 100%;
    display: inline-block;
    font-size: var(--fSize13);
    font-weight: 700;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    margin-bottom: 1.14rem;
  }
  .Button {
    width: 100%;
    height: 40px;
    margin-top: 2rem;
  }
}
