%btn {
  font-family: var(--helvetica700);
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  box-shadow: none;
  cursor: pointer;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 8px; //for icons

  transition: background-color 0.3s ease, color 0.3s ease;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;
    background-color: var(--gray-70) !important;
    color: var(--black-0) !important;
  }
}

//sizes lg, md, sm
%btnLg {
  font-size: var(--fSize17);
  padding: 1.25rem 2.85rem;
}

%btnMd {
  font-size: var(--fSize12);
  letter-spacing: 0.5px;
  padding: 0.9rem 1rem;
}

%btnSm {
  font-size: var(--fSize10);
  letter-spacing: 0.5px;
  padding: 0.7rem 0.9rem;
}

// colors light, dark, red, outlined
%btnLight {
  background-color: var(--gray-40);

  &:hover,
  &:focus {
    background-color: var(--black-10);
    color: var(--white-0);
  }
}

%btnDark {
  background-color: var(--gray-0);
  color: var(--white-0);

  &:hover,
  &:focus {
    background-color: var(--black-10);
  }
}

%btnRed {
  background-color: var(--red-0);
  color: var(--white-0);

  &:hover,
  &:focus {
    background-color: var(--red-10);
  }
}

%btnOutline {
  background-color: var(--white-0);
  border: 1px solid var(--gray-30);
  color: var(--black-10);

  &:hover,
  &:focus,
  &:active {
    color: var(--white-0);
    background-color: var(--black-10);
  }
}

%btnLoading {
  pointer-events: none;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--gray-20);
    opacity: 0.8;
  }

  &:after {
    content: "";
    width: 1rem;
    height: 1rem;
    position: absolute;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff #fff #fff transparent;

    left: calc(50% - 0.5rem);

    animation-name: loading-spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.Button {
  @extend %btn;

  &.horizontal {
    padding-left: 0;
    padding-right: 0;
  }

  &.lg {
    @extend %btnLg;
  }

  &.md {
    @extend %btnMd;
  }

  &.sm {
    @extend %btnSm;
  }

  &.light {
    @extend %btnLight;
  }

  &.lightHoverLight {
    background-color: var(--gray-40);
    opacity: 0.8;

    &:hover,
    &:focus {
      opacity: 1;
      background-color: var(--gray-40) !important;
      color: var(--black-0) !important;
    }
  }

  &.fullWidth {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &.dark {
    @extend %btnDark;
  }

  &.red {
    @extend %btnRed;
  }

  &.outline {
    @extend %btnOutline;
  }

  &.loading {
    @extend %btnLoading;
  }

  &.text {
    //for dropdown
    font-family: var(--helvetica500);
    font-weight: 500;
    color: var(--black-10);

    &:disabled {
      background-color: transparent !important;
      color: var(--gray-10) !important;

      & > * {
        color: var(--gray-10) !important;
      }
    }
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.justifyContentStart {
    display: flex;
    justify-content: flex-start;
  }

  &.justifyContentEnd {
    display: flex;
    justify-content: flex-end;
  }
}

.button-sort {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  &:active {
    transform: none;
  }
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
