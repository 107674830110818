.ExpenseListViewOptions {
  box-shadow: var(--dark-shadow);
  border-radius: 5px;
  background: var(--white-0);
  padding: 1.71rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 350px;

  &-switcher {
    display: flex;
    align-items: center;
    gap: 8px;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    & > .SwitchInput-label-text {
      margin-bottom: 0;

      font-family: var(--helvetica500);
      font-weight: 500;
      font-size: var(--fSize10);
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: var(--black-10);
    }
  }

  &-button {
    width: 100%;
    margin-top: 8px;
    grid-column: 1/-1;
  }
}
