.CallSheetPdfTemplate-template {
  width: 100%;
  &.for-downloading {
    height: 100%;
    width: 100%;
    top: 0;
    background-color: var(--white-0);
    z-index: -3;
    position: absolute;
  }
}
