.JobFilesList {
  min-height: calc(100% - 101px);
  position: relative;
  padding: 0 2rem;

  &-item {
    display: flex;
    align-items: center;
    padding: 0.45rem 0.7rem;
    box-shadow: var(--bottom-line);
    text-transform: uppercase;
    font-size: var(--fSize16);
    font-weight: 500;
    letter-spacing: 0.015rem;
    cursor: pointer;
    min-height: 39px;
  }
}
