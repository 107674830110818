.BudgetOverlayPageLayout {
  margin: 2.85rem auto;
  padding: 5.71rem 5.71rem 4rem;
  width: 100%;
  max-width: 1190px;
  box-shadow: var(--light-shadow);
  border: 1px solid var(--gray-30);
  background-color: var(--white-0);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-body {
    background-color: var(--white-0);
  }
}
