.AdvanceSelection {
  margin-left: 1.14rem;

  & > .Button {
    max-height: 32px;
    min-width: 194px;
    justify-content: space-between;
  }
}

.AdvanceSelection-dropdown {
  .body {
    min-width: 194px;
    overflow: visible;
  }
  .Button {
    display: flex;
    justify-content: space-between;
    transition: var(--transition);
    position: relative;
    max-height: 32px;

    &:hover {
      background-color: var(--gray-40);
      & > .submenu {
        display: block;
      }
    }
  }

  .submenu {
    display: none;
    position: absolute;
    top: 0;
    left: -119px;
    z-index: 2;
    overflow: visible;
    background-color: var(--white-0);
    box-shadow: var(--light-shadow);
  }
}
