@import "src/assets/styles/_spacings.scss";

button.TextComponent {
  &.buttonFlex {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &:active {
    transform: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
}

.TextComponent {
  &.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: inherit;
    max-width: inherit;
  }

  @include make-space(margin);
  @include make-space(padding);

  &.center {
    text-align: center;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.lowercase {
    text-transform: lowercase;
  }

  &.nowrap {
    white-space: nowrap;
  }

  &.h100 {
    height: 100%;
  }

  &.w100 {
    width: 100%;
  }

  &.size {
    &-26 {
      font-size: var(--fSize26);
    }

    &-20 {
      font-size: var(--fSize20);
    }

    &-17 {
      font-size: var(--fSize17);
    }

    &-15 {
      font-size: var(--fSize15);
    }

    &-14 {
      font-size: var(--fSize14);
    }

    &-13 {
      font-size: var(--fSize13);
    }

    &-11 {
      font-size: var(--fSize11);
    }

    &-10 {
      font-size: var(--fSize10);
    }
  }

  &.weight {
    &-700 {
      font-family: var(--helvetica700);
    }
    &-500 {
      font-family: var(--helvetica500);
    }
    &-400 {
      font-family: var(--helvetica400);
    }
    &-300 {
      font-family: var(--helvetica300);
    }
  }

  &.color {
    &-green-30 {
      color: var(--green-30);
    }

    &-black-10 {
      color: var(--black-10);
    }

    &-gray-0 {
      color: var(--gray-0);
    }

    &-gray-10 {
      color: var(--gray-10);
    }

    &-gray-20 {
      color: var(--gray-20);
    }

    &-red {
      color: var(--red-0);
    }

    &-white-0 {
      color: var(--white-0);
    }

    &-green-0 {
      color: var(--green-0);
    }

    &-tangerine-0 {
      color: var(--tangerine-0);
    }
  }

  &.text-align {
    &-center {
      text-align: center;
    }
    &-left {
      text-align: left;
    }
    &-right {
      text-align: right;
    }
  }
}
