.RowStatus {
  &:hover,
  &.open {
    .RowStatus-placeholder {
      opacity: 1;
    }
  }

  &-placeholder {
    color: var(--gray-10);
    opacity: 0;
    transition: opacity 0.25s;
  }
}
