.JobsDetailsLayout {
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  overflow-y: auto;

  &-body {
    display: grid;
    position: relative;
  }
}
