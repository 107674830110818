@font-face {
  font-family: "icomoon";
  src: url("../../assets/fonts/icons/icomoon.eot");
  src: url("../../assets/fonts/icons/icomoon.eot") format("embedded-opentype"),
    url("../../assets/fonts/icons/icomoon.ttf") format("truetype"),
    url("../../assets/fonts/icons/icomoon.woff") format("woff"),
    url("../../assets/fonts/icons/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  color: inherit;

  &__xxsm {
    font-size: var(--fSize8);
  }
  &__xsm {
    font-size: var(--fSize12);
  }
  &__14 {
    font-size: var(--fSize14);
  }
  &__sm {
    font-size: var(--fSize16);
  }
  &__md {
    font-size: var(--fSize24);
  }
  &__lg {
    font-size: var(--fSize32);
  }
  &__xlg {
    font-size: var(--fSize64);
  }
  &__xxlg {
    font-size: var(--fSize128);
  }
  &__xxxlg {
    font-size: var(--fSize32);
  }

  &.gray {
    color: var(--gray-20);
  }
  &.dark-gray {
    color: var(--gray-10);
  }
  &.light-gray {
    color: var(--gray-30);
  }
  &.black {
    color: var(--black-10);
  }
  &.white {
    color: var(--white-10);
  }
  &.yellow {
    color: var(--yellow-0);
  }
  &.red {
    color: var(--red-0);
  }
  &.green {
    color: var(--green-0);
  }
  &.orange {
    color: var(--orange-0);
  }
  &.tangerine {
    color: var(--tangerine-0);
  }

  &-minus {
    display: flex;
    justify-content: center;
  }
}

.icon-sort {
  display: flex;
  flex-direction: column;
  grid-gap: 2px;

  &:before,
  &:after {
    font-size: 6px;
    color: var(--gray-20);
    display: block;
  }

  &:before {
    content: "\e915";
  }

  &:after {
    content: "\e914";
  }

  &.asc {
    &:before {
      color: var(--black-10);
    }
    &:after {
      color: var(--gray-20);
    }
  }
  &.desc {
    &:before {
      color: var(--gray-20);
    }
    &:after {
      color: var(--black-10);
    }
  }
}
.icon-hide:before {
  content: "\e900";
}
.icon-info:before {
  content: "\e901";
}
.icon-Instagram:before {
  content: "\e902";
}
.icon-jobs:before {
  content: "\e903";
}
.icon-landscape-image:before {
  content: "\e904";
}
.icon-location:before {
  content: "\e905";
}
.icon-lock:before {
  content: "\e906";
}
.icon-logout:before {
  content: "\e907";
}
.icon-menu:before {
  content: "\e908";
}
.icon-notifications:before {
  content: "\e909";
}
.icon-overdue:before {
  content: "\e90a";
}
.icon-password:before {
  content: "\e90b";
}
.icon-person-default:before {
  content: "\e90c";
}
.icon-profile:before {
  content: "\e90d";
}
.icon-reactivate:before {
  content: "\e90e";
}
.icon-reminder:before {
  content: "\e90f";
}
.icon-resend:before {
  content: "\e910";
}
.icon-search:before {
  content: "\e911";
}
.icon-send:before {
  content: "\e912";
}
.icon-sent:before {
  content: "\e913";
}
.icon-star:before {
  content: "\e916";
}
.icon-success:before {
  content: "\e917";
}
.icon-team:before {
  content: "\e918";
}
.icon-triangle-down:before {
  content: "\e919";
}
.icon-triangle-left:before {
  content: "\e91a";
}
.icon-triangle-right:before {
  content: "\e91b";
}
.icon-triangle-up:before {
  content: "\e91c";
}
.icon-unlink:before {
  content: "\e91d";
}
.icon-warning:before {
  content: "\e91e";
}
.icon-account:before {
  content: "\e91f";
}
.icon-add:before {
  content: "\e920";
}
.icon-arrow-back:before {
  content: "\e921";
}
.icon-arrow-down:before {
  content: "\e922";
}
.icon-arrow-left:before {
  content: "\e923";
}
.icon-arrow-right:before {
  content: "\e924";
}
.icon-arrow-up:before {
  content: "\e925";
}
.icon-calendar:before {
  content: "\e926";
}
.icon-check:before {
  content: "\e927";
}
.icon-close:before {
  content: "\e928";
}
.icon-close-lg:before {
  content: "\e929";
}
.icon-close-sm:before {
  content: "\e92a";
}
.icon-collapse:before {
  content: "\e92b";
}
.icon-contact:before {
  content: "\e92c";
}
.icon-dashboard:before {
  content: "\e92d";
}
.icon-delete:before {
  content: "\e92e";
}
.icon-drag:before {
  content: "\e92f";
}
.icon-dropbox:before {
  content: "\e930";
}
.icon-edit:before {
  content: "\e931";
}
.icon-error:before {
  content: "\e932";
}
.icon-expand:before {
  content: "\e933";
}
.icon-export:before {
  content: "\e934";
}
.icon-export-secondary:before {
  content: "\e935";
}
.icon-external:before {
  content: "\e936";
}
.icon-eye:before {
  content: "\e937";
}
.icon-Facebook:before {
  content: "\e938";
}
