.EventDetailsModal {
  .container {
    max-width: 528px;
  }
}

.EventDetails {
  padding: 1.14rem 1.71rem 2.28rem;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  &-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 0 1.5rem;
    margin-bottom: 0.5rem;
  }
  &-title {
    font-size: var(--fSize20);
    text-transform: uppercase;
    margin-bottom: 1.71rem;

    word-break: break-word;
  }
  &-time {
    margin-bottom: 1.71rem;
    font-size: var(--fSize17);
  }
  &-description {
    margin-bottom: 1.71rem;
  }

  &-delete {
    margin-left: 1rem;
  }
}

.EventDetailsDeleteConfirm {
  text-align: center;
  .error-message {
    color: var(--red-0);
  }
  p {
    margin-bottom: 1.14rem;
  }
}
