.CurrencyRatesInputRow {
  td.err {
    box-shadow: inset 0 0 0 1px var(--red-0);
  }

  .InputField {
    margin-bottom: 0;
    &.text-right {
      .input {
        text-align: right;
      }
    }
    &-input {
      border: none;
      padding: 0;
      min-height: auto;
    }
  }
}
