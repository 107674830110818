.CategorizedHeader {
  padding: 0 32px;
  min-height: 56px;

  display: flex;
  align-items: center;
  gap: 8px;

  & > .Button:nth-child(2) {
    margin-left: auto;
  }

  width: calc(100vw - 20px);
  position: sticky;
  left: 0;
}
