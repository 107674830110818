.JobSettings {
  & > .Button {
    min-width: 202px;
  }
  .DropdownMenu-body {
    min-width: 202px;
  }
  .NestedMenu {
    display: none;
    position: absolute;
    top: 0;
    background-color: var(--white-0);
    box-shadow: var(--light-shadow);
    border: 1px solid var(--gray-30);
    border-radius: 3px;
    &.tags {
      right: -90px;
    }
    &.templates {
      right: -100px;
    }
  }
  .menu-item {
    max-height: 32px;
    position: relative;
    &-nested {
      justify-content: space-between;
      display: flex;
    }

    &:hover {
      background-color: var(--gray-40);

      .NestedMenu {
        display: block;
      }
    }
  }
}
