.TableTrackWA {
  overflow: auto;
  height: 100%;
}

.TrackWaTable {
  border: none;

  .Table-body2 {
    position: sticky;
    bottom: 40px;
    z-index: 9;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 10;

    th {
      &:first-child {
        background: var(--gray-40);
        position: sticky;
        left: 0;
      }
    }

    .qty,
    .cost,
    .time {
      box-shadow: none;
    }

    .difference {
      padding-left: 3rem;
    }

    .w8,
    .w9,
    .ach,
    .wire,
    .working-total,
    .actual-total {
      text-align: center;
    }

    .w8,
    .w9,
    .ach,
    .wire {
      padding: 0;
    }
  }

  tbody {
    tr {
      vertical-align: top;

      &:first-child {
        box-shadow: none;
      }
    }
  }

  td {
    height: 40px;

    &:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
    }
  }

  tfoot {
    position: sticky;
    bottom: 0;
    z-index: 9;
  }
}
