.JobPipeline {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.65rem;
  background-color: var(--white-10);
  border: 1px solid var(--gray-40);
  margin-bottom: 4px;
	min-height: 44px;
  &--name-wrapper {
    display: flex;
    grid-gap: 0.3rem;
  }
  &--id {
    color: var(--gray-10);
    font-family: var(--helvetica400);
    font-size: var(--fSize14);
  }
  &--name {
    max-width: 8.15rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &--status-wrapper {
    display: flex;
    flex-direction: column;
  }
  &--status {
    color: var(--gray-10);
    font-size: var(--fSize10);
    text-transform: uppercase;
    text-align: right;
    font-family: var(--helvetica500);
		line-height: 1.2;
  }
  &--progress {
    color: var(--gray-10);
    font-size: var(--fSize10);
    text-transform: uppercase;
    text-align: right;
    font-family: var(--helvetica500);
		line-height: 1.2;
    &.overdue {
      color: var(--red-10);
    }
  }
}
