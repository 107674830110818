.PeopleTableRow {
  box-shadow: var(--bottom-line);
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    background: var(--gray-40);
  }

  &-cell {
    padding: 0.71rem 0.5rem;
    font-size: var(--fSize13);
    font-weight: 500;

    &-phone {
      position: relative;

      &:hover {
        .button-clipboard-copy {
          opacity: 1;
        }
      }

      .button-clipboard-copy {
        position: absolute;
        left: 6rem;
        padding: 0.25rem;
        background: rgba(255, 255, 255, 0.3);
        transition: var(--transition);
        opacity: 0;
      }
    }

    .icon-favorites {
      color: var(--gray-30);

      &.favorites {
        color: var(--yellow-0);
      }
    }

    .tag,
    .tag-link {
      margin-right: 4px;
    }

    &-avatar {
      width: 2.28rem;
      height: 2.28rem;
      margin-right: 0.57rem;
      border-radius: 50%;
      vertical-align: middle;
      object-fit: cover;

      &-wrapper {
        display: flex;
        align-items: center;
      }
    }

    &-name {
      display: flex;

      &-value {
        display: inline-block;
        max-width: var(--text-max-width);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: var(--helvetica500);
      }
    }

    &-companies {
      display: flex;
      align-items: center;
      grid-gap: 0.25rem;

      .Tag {
        border-radius: 50%;
        height: max-content;
      }
    }

    &-link {
      display: inline-block;
      max-width: var(--text-max-width);
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover > .ButtonCopyClipboard {
        opacity: 1;
      }
    }
  }
}
