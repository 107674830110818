.JobsTableRow {
  transition: var(--transition);
  border: 1px solid var(--gray-40);
  cursor: pointer;

  &:hover {
    background: var(--gray-40);
  }

  &.active {
    background: var(--gray-40);
    border-top-color: var(--gray-30);
    border-left-color: var(--gray-30);
    border-right-color: var(--gray-30);
  }

  &-cell {
    padding: 4px;

    .counting {
      width: 16px;
      height: 16px;
      flex: 0 0 16px;
      background-color: var(--white-0);
      border-radius: 50%;
      font-size: var(--fSize8);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
    }

    &.favorite {
      text-align: center;
    }

    &.job-name {
      font-family: var(--helvetica500);
      max-width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.dates {
      white-space: nowrap;
    }

    .showDetailsButton {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      border-radius: 5px;
      border: 1px solid var(--gray-40);
      background-color: var(--white-0);
    }

    &-empty {
      margin-left: 1.5rem;
      width: 100%;
    }
  }

  .Tag,
  .TabButton {
    margin: 4px;
  }

  .TagButton {
    width: auto;
    white-space: nowrap;
  }

  .TagsRow {
    flex-wrap: nowrap;
  }
}

.JobDetailsModalWrapper {
  position: relative;
}
