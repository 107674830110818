.BudgetOverlayFooter {
  display: flex;
  justify-content: space-between;

  box-shadow: var(--top-line);
  margin-top: 2.85rem;
  padding-top: 0.57rem;

  color: var(--gray-10);
  font-size: var(--fSize13);
  & > div {
    width: 50%;
  }

  &-rightSide {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
}
