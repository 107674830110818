.EstimateListWrap {
  overflow-y: auto;

  &-title {
    padding: var(--16px) var(--32px);
    background-color: var(--white-0);

    margin-right: 1px; //for border
    position: sticky;
    top: 0;
  }

  .EstimateList {
    padding: 0 var(--24px) var(--16px);

    .EstimateListItem {
      gap: 0 0.286rem;
      cursor: pointer;

      display: flex;
      & > div:nth-child(1) {
        width: 3rem;
      }
      & > div:nth-child(2) {
        &.name-lg {
          width: 17rem;
        }
        width: 11.429rem;
      }
      & > div:nth-child(3) {
        width: 5.571rem;
      }

      padding: 0.143rem var(--8px);
      margin-bottom: 0.286rem;
      text-align: left;

      &.active {
        background-color: var(--white-40);
        border: 1px solid var(--gray-40);
        border-radius: 1px;
      }

      .AppTooltipsWrap {
        position: absolute;
        z-index: 9;

        .AppTooltips {
          top: 8px;
        }
      }
    }
  }
}
