@import "src/assets/styles/placeholders/label";

.TeamMemberForms {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-y: auto;
  height: calc(100vh - 160px);
  min-height: 100%;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    padding: 1.42rem 1.71rem;
    box-shadow: var(--bottom-line);
    background: var(--white-40);
    border-radius: 5px 5px 0 0;
    .h2 {
      font-family: var(--helvetica700);
    }
    .account-tag {
      font-size: var(--fSize11);
      border-radius: 2px;
      background: var(--gray-30);
      opacity: 0.4;
      font-weight: 700;
      padding: 0.71rem 1.14rem;
    }
  }

  &-body {
    overflow-y: auto;
    padding: 1.71rem 0;
    position: relative; //for loader
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.85rem 1.41rem;
    box-shadow: var(--top-line);
    bottom: 0;
    width: 100%;
    gap: 1rem;

    & > .Button {
      min-width: 9.14rem;
      &:not(:last-child) {
        font-family: var(--helvetica300);
        color: var(--gray-10);
      }
    }
  }
}
