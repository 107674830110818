.EventEdit {
  .container {
    max-width: 528px;
  }

  .DateRangePicker {
    margin-top: 1.71rem;
    &-inputGroup {
      gap: 0 4px;
    }

    .TimeField-control {
      padding-left: 0;
    }
  }

  &-checkbox {
    margin-bottom: 1.41rem;
    .Checkbox-label {
      border: none;
      padding: 0.65rem 0.85em 0.65em 1.75rem;
    }

    .Checkbox-input:checked ~ .Checkbox-label {
      background-color: transparent;
    }
    .Checkbox-label:before {
      left: 0;
    }
    .Checkbox-label:after {
      left: 0.6rem;
    }
  }
}
