.EstimateRowExpanded {
  box-shadow: var(--bottom-line);
  padding: 16px 48px 16px 136px;

  &-inputsWrap {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 8px;
  }

  &-btnWrap {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    & > .Button {
      width: 118px;
    }
  }

  &.isApproved {
    .TextareaField-input {
      pointer-events: none;

      &:disabled {
        background-color: var(--gray-40);
      }
    }
  }
}
