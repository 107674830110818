/**
  Small size is default
 */

.tag-link {
  font-family: var(--helvetica500);
  width: max-content;
  border-radius: 12rem;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: var(--fSize10);
  padding: 0.57rem 0.85rem;

  &-style {
    &-primary.tag-link {
      color: var(--white-0);
      background: var(--gray-60);
    }

    &-secondary.tag-link {
      color: var(--black-0);
      background: var(--gray-70);
    }

    &-tertiary.tag-link {
      color: var(--black-0);
      background: var(--white-20);
    }
  }

  &__company {
    color: var(--white-0);
    background: var(--gray-60);
  }

  &__contact {
    color: var(--black-0);
    background: var(--gray-70);
  }

  &__location {
    color: var(--black-0);
    background: var(--white-20);
  }

  &__client {
    color: var(--white-0);
    background: var(--gray-60);
  }

  &__artist {
    color: var(--black-0);
    background: var(--gray-70);
  }

  &__md.tag-link {
    font-size: var(--fSize11);
    padding: 0.5rem 1.14rem;
  }

  &__lg.tag-link {
    font-size: var(--fSize11);
    padding: 0.9rem 1.14rem;
  }
}