.CallSheetTemplateLocation {
  display: grid;
  grid-template-columns: 1fr 640px;
  gap: var(--24px);

  margin-bottom: 3.42rem;

  &-locationItem {
    margin-bottom: var(--32px);

    & > *:first-child {
      margin-bottom: 0.4rem;
    }

    & > *:not(&:first-child) {
      line-height: 1.2;
    }
  }

  &-upload {
    min-height: 480px;
    min-width: 100%;
    position: relative;

    & > .uploadImage {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &.image {
      max-width: 640px;
      max-height: 480px;
      height: auto;

      .uploadImage {
        display: block;
        z-index: 2;
      }
    }
    &.placeholder {
      background-color: var(--gray-40);
      & > .uploadImage {
        display: none;
      }
    }
  }
}
