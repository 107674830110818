.Table {
  --bdr: 3px;
  width: 100%;
  border-spacing: 0;
  border: 1px solid var(--gray-40);
  border-radius: var(--bdr);
  position: relative;

  &-header-row {
    background: var(--gray-40);
    box-shadow: var(--bottom-line);

    & > th {
      box-shadow: inset -1px 0px 0px #e7e7e8;
      white-space: nowrap;
      padding: 0.5rem;
      font-size: var(--fSize13);
      color: var(--black-10);
      font-family: var(--helvetica500);
      font-weight: 500;
      text-align: left;
      letter-spacing: 0.005em;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: var(--text-max-width);

      &.TableHeadItem {
        &.withResize {
          position: relative;
          overflow: visible;
        }
      }

      &:first-child {
        border-top-left-radius: var(--bdr);
      }

      &:last-child {
        border-top-right-radius: var(--bdr);
        box-shadow: none;
      }

      &.favorite {
        text-align: center;
        width: 36px;
        position: relative;
        box-shadow: none;

        & > .icon {
          position: absolute;
          top: 50%;
          left: 0.5rem;
          transform: translateY(-50%);
        }
      }

      & > button {
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
      }
    }
  }

  &-body {
    tr {
      box-shadow: var(--bottom-line);

      &:last-child {
        box-shadow: none;
      }
    }
  }

  .TagsRow {
    max-width: 200px;
    flex-wrap: wrap;
  }
}

.TableWrapper {
  overflow: auto;
}
