.tab {
  display: flex;
  padding: 0 1.71rem;
  box-shadow: var(--bottom-line);

  &-item {
    position: relative;
    margin-right: 2.5rem;
    color: var(--gray-10);
    font-size: var(--fSize12);
    text-transform: uppercase;
    font-weight: 700;
    transition: var(--transition);
    display: flex;
    align-items: center;
    gap: 0.3rem;

    &>.icon {
      font-size: var(--fSize14);
    }

    &.active {
      color: var(--black-10);

      &:after,
      &:before {
        width: 50%;
      }
    }

    &:hover {

      &:after,
      &:before {
        width: 50%;
      }
    }

    &:after,
    &:before {
      content: "";
      position: absolute;
      height: 3px;
      width: 0;
      bottom: 0;
      background: var(--black-10);
      transition: var(--transition);
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }
}