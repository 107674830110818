.ModalInfoSection {
  display: grid;
  gap: 0.85rem 4rem;
  margin: 1.14rem 0;
  padding-bottom: 1.14rem;
  box-shadow: var(--bottom-line);
  grid-column-gap: 4px;

  &.labelSize {
    &-md {
      grid-template-columns: 160px 3fr;
    }
    &-sm {
      grid-template-columns: 72px 3fr;
    }
  }

  & > .title {
    font-family: var(--helvetica700);
    font-size: var(--fSize15);
    margin: 1rem 0 0.2rem;
    grid-column: 1/-1;
  }

  & > .label {
    align-self: flex-start;
    color: var(--gray-0);
    font-family: var(--helvetica400);
    font-size: var(--fSize11);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > .value {
    align-self: flex-start;
    word-break: break-word;

    & > * {
      word-break: break-word;
    }

    &.empty {
      color: var(--gray-20);
    }
  }
}
