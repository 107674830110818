
.FavRow {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0.75em 1em;
  font-size: var(--fSize13);
  text-transform: uppercase;
  color: var(--gray-20);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: var(--gray-80);
    color: var(--white-0);
  }
}
