@import "src/assets/styles/placeholders/label";

.CompanyDetails {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  padding: 1.75rem;

  &-Accordion {
    .Accordion-header {
      justify-content: flex-end;
      flex-direction: row-reverse;
      padding-left: 0;
      box-shadow: none;
      gap: 8px;
    }
  }

  .TagsGroup {
    display: flex;
    flex-wrap: wrap;

    &.companies {
      .TagButton {
        cursor: unset;
        transform: none;
      }
    }
  }
}