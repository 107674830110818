.AdvanceEmptyState {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-image {
    margin-bottom: 4rem;
  }

  &-form {
    min-width: 320px;
    .Select,
    .InputField {
      margin-bottom: 1.71rem;
    }

    .Button {
      margin-top: 2.85rem;
    }
  }
}
