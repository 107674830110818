.Sidebar {
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 100vh;
  background: var(--black-0);
  color: var(--white-0);
  transition: var(--transition);
  padding-bottom: 1rem;
  z-index: 4;
  overflow-x: hidden;

  &-nav {
    &-item {
      padding: 1rem;
      text-transform: uppercase;
      font-family: var(--helvetica700);
      border-radius: 2px;
      transition: var(--transition);
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      background: inherit;
      width: 100%;
      color: var(--white-0);
      opacity: 0.7;
      height: 4rem;

      &.active,
      &:hover {
        background-color: var(--gray-80);
        color: var(--white-0);
        opacity: 1;
      }

      &-icon {
        line-height: 1;
      }
    }

    &-text {
      line-height: 1.42;
      margin-left: 0.85rem;
      transition: var(--transition);
    }
  }

  &-button-collapse {
    position: absolute;
    top: 1.75rem;
    right: 0;
    background-color: #383536;
    width: 24px;
    height: 24px;
    border-radius: 20px 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;

    &-icon {
      color: var(--gray-10);

      &.collapsed {
        transform: rotate(180deg);
      }
    }
  }

  &-logo {
    padding: 2rem 2.85rem 2rem 1.21rem;
    min-height: 72px;
  }

  &-header,
  &-footer {
    width: 100%;
    position: relative;
    padding: 0 0.5rem;
  }

  &-header {
    font-size: var(--fSize17);
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: padding var(--transition);
  }

  &-footer {
    font-size: var(--fSize15);

    .Sidebar-nav-item {
      width: 100%;
      font-size: var(--fSize15);

      &:hover {
        background: var(--gray-80);
        color: var(--white-0);
      }
    }
  }

  // sidebar collapsed
  &-collapsed {
    width: 56px;
    padding: 0 0 1rem 0;

    .Sidebar-nav-text,
    .Favorites-btn-text {
      opacity: 0;
    }

    .Sidebar-header,
    .Sidebar-footer {
      padding: 0;
    }

    .Sidebar-logo {
      visibility: hidden;
    }

    .Sidebar-button-collapse {
      width: 24px;
      border-radius: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
