.CSContact {
  color: var(--black-10);

  &.hidden {
    color: var(--gray-20);

    .TimeCell-input,
    .LocationCell-input {
      color: var(--gray-20) !important;
    }
  }

  &.showTooltip {
    position: relative;

    .AppTooltips {
      left: -12px;
    }
  }

  &-checkbox {
    .Checkbox-input:checked ~ .Checkbox-label {
      background-color: transparent;
    }

    .Checkbox-label {
      border: none;
      padding: 0 0 0 var(--24px);
      font-size: var(--fSize17);
      font-family: var(--helvetica700);
      text-transform: uppercase;

      &::before {
        left: 0px;
      }
      &::after {
        left: 7px;
      }
    }
  }

  & > .phones,
  & > .emails {
    display: flex;
    align-items: center;
    gap: 1rem;

    &.hidden {
      color: var(--gray-20);
    }

    &.showTooltip {
      position: relative;

      .AppTooltips {
        transform: translate(9%, 10%);
      }
    }

    .Button {
      padding: 3px 6px;
    }

    & > .Tooltip {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &:hover {
        .icon {
          color: var(--black-10);
        }
      }
    }

    .icon {
      &:hover {
        color: var(--black-10);
      }
    }
  }
}
