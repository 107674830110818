.SenderRecipientToolbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1.14rem 0;

  &-checkbox {
    margin-right: 1.14rem;
  }

  &-left {
    display: flex;
  }

  .Checkbox-label {
    border: none;
    padding-left: 0;
    width: 16px;
  }

  .Checkbox-input:checked ~ .Checkbox-label {
    background-color: unset;
  }

  &-button {
    text-transform: uppercase;
    font-weight: 700;
    font-size: var(--fSize10);
    letter-spacing: 0.05rem;
    color: var(--gray-10);
  }
}
