.Overview {
  .TableToolbar {
    &-title {
      display: flex;
      align-items: center;
      grid-gap: 1.7rem;
      font-family: var(--helvetica700);
      font-size: var(--fSize20);

      .jobs-number {
        font-size: var(--fSize13);
      }
    }
  }
}
