.ChapterHeader {
  margin-bottom: 1.14rem;
  .TextComponent {
    padding-bottom: 0.2rem;
  }

  &.with-line {
    border-bottom: 2px solid var(--black-0);
  }
}
