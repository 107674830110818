.InvoiceProductionCosts {
  &-row {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 86px 1fr auto;
    grid-template-rows: 1fr;
    gap: 0 0;
    grid-template-areas: ". . .";
    &-name {
      font-family: var(--helvetica500);
      text-transform: capitalize;
    }
    &-total {
      text-align: right;
    }
  }
  &-totalRow {
    font-family: var(--helvetica700);
    font-size: var(--fSize15);
    text-transform: uppercase;
    display: flex;
    justify-content: flex-end;
    &-total {
      width: 170px;
      text-align: right;
    }
  }
}
