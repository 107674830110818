.CustomReactSelectWrap {
  margin-bottom: 1.14rem;

  .CustomReactSelectLabel {
    color: var(--gray-0);
    font-family: var(--helvetica400);
    margin-bottom: 0.25rem;
    font-size: var(--fSize11);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .CustomReactSelectError {
    color: var(--red-0);
    padding-top: 0.25rem;
    font-size: var(--fSize13);
    text-align: left;
  }

  &.error {
    .CustomReactSelectLabel {
      color: var(--red-0);
    }
  }
}
