.FilterLayout {
  box-shadow: var(--dark-shadow);
  border-radius: 5px;
  background: var(--white-0);
  padding: 1.71rem;
  min-width: 340px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem 1.5rem;

  & > .label {
    font-size: var(--fSize10);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.01em;
    align-self: center;
  }

  .Select,
  .TagsField {
    margin-bottom: 0;
  }

  & > .Button {
    width: 100%;
    grid-column: 1/-1;
    margin-top: 8px;
  }
}
