.DropdownMenu {
  // display: none;
  position: fixed;
  z-index: 9999;
  inset: 0;

  &.btnHover {
    .DropdownMenu-body > .Button {
      width: 100%;
      justify-content: flex-start;

      &:hover {
        background-color: var(--gray-40);
      }
    }

    .DropdownSubmenu {
      .submenu {
        & > .Button {
          width: 100%;
          justify-content: flex-start;
          &:hover {
            background-color: var(--gray-40);
          }
        }
      }
    }
  }

  &.withSubmenu {
    z-index: 9999;
    .body {
      overflow: visible;
    }
  }

  &-backdrop {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &-body {
    position: absolute;
    overflow: hidden auto;
    width: fit-content;
    height: fit-content;
    max-width: calc(100% - 32px);
    max-height: calc(100% - 32px);
    outline: 0;

    border: 1px solid var(--gray-30);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background-color: var(--white-0);
    opacity: 1;

    // TODO remove
    // max-height: 200px;

    & > hr {
      border-top: 1px solid var(--gray-40);
    }

    & > .Button {
      &:disabled {
        background-color: var(--white) !important;
      }
    }

    .divider {
      border-top: 0;
      border-bottom: 1px solid var(--gray-30);
    }
  }
}
