.AdvanceInvoicePreview {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-y: auto;
  margin: 2.85rem 0;

  iframe {
    width: 100%;
    max-width: 1155px;
    min-height: 670px;
    background-color: var(--white-0);
  }
}
