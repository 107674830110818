.TrackNavigation {
  &.ButtonGroup {
    overflow: visible;
    position: relative;
    z-index: 12;

    .AppTooltips {
      top: 150%;
      left: 0;
      transform: translateX(-76%);
    }
  }
}
