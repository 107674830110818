.EstimateRowName {
  // display: grid !important;
  // grid-template-columns: 24px 24px 48px 276px;

  display: flex;
  padding-left: 32px;

  & > .Button {
    height: 100%;
    width: 24px;
    flex-shrink: 0;
  }

  & > .number {
    padding-right: 8px;
    width: 48px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: inherit;
    max-width: inherit;
  }

  & > .sortIcon {
    cursor: grab;
  }

  & > .EstimateRowName-wrapDescriptions {
    width: 276px;
  }

  &:not(:hover) {
    & > .sortIcon {
      opacity: 0;
    }
  }

  position: relative;

  .EstimateRowINput {
    justify-content: flex-start;

    .TextComponent {
      text-align: left;
      padding: 0;
      font-family: var(--helvetica500);
    }

    &-input {
      width: 100%;
      text-align: left;
      height: 40px;
    }
  }
}
