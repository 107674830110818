.WrapOverlay {
  padding: 2.85rem 0;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;

  &-document {
    max-width: 597px;
    margin: 0 auto;
  }
}
