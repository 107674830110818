$baseWidthSize: 1em;
$baseHeightSize: 2em;

.Loading {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 9999;
  font-size: 1rem;
  background-color: var(--gray-30);
  top: 0;
  left: 0;

  &.lg {
    font-size: 1.5em;
  }

  &.transparent {
    background-color: rgb(0, 0, 0, 46%);
  }

  &.grey-40 {
    background-color: var(--gray-40);
    opacity: 0.95;
  }

  &.dark {
    background-color: var(--black_0);
  }

  &.inBlock {
    position: absolute;
    left: 0;
    top: 0;
  }

  &-block {
    position: relative;
    width: $baseWidthSize * 4;
    height: $baseHeightSize + $baseHeightSize;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    div {
      position: absolute;
      width: $baseWidthSize;
      background-color: var(--white-0);
      animation: loading-animation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

      &:nth-child(1) {
        left: 0;
        animation-delay: -0.24s;
        background-color: var(--gray-0);
      }

      &:nth-child(2) {
        left: $baseWidthSize + $baseWidthSize/2;
        animation-delay: -0.12s;
        background-color: #7d7d7d;
      }

      &:nth-child(3) {
        left: $baseWidthSize * 3;
        animation-delay: 0s;
        background-color: var(--gray-20);
      }
    }
  }
  &.is-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  &-progressBar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid var(--gray-40);
    position: absolute;
    text-align: center;

    &-text {
      font-size: var(--fSize17);
      font-weight: 700;
      bottom: -2rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .circle .mask,
    .circle .fill {
      width: 150px;
      height: 150px;
      position: absolute;
      border-radius: 50%;
    }

    .circle .mask {
      clip: rect(0px, 150px, 150px, 75px);
    }
    .mask .fill {
      clip: rect(0px, 75px, 150px, 0px);
      background-color: var(--gray-10);
    }

    .inside-circle {
      width: 122px;
      height: 122px;
      border-radius: 50%;
      background-color: var(--gray-40);
      line-height: 120px;
      text-align: center;
      margin-top: 14px;
      margin-left: 14px;
      color: var(--black-10);
      position: absolute;
      z-index: 100;
      font-weight: 700;
      font-size: 2em;
    }
  }

  @keyframes loading-animation {
    0% {
      top: 0;
      height: $baseHeightSize + $baseHeightSize;
    }

    50%,
    100% {
      top: $baseWidthSize;
      height: $baseHeightSize;
    }
  }
}
