.LinkBlock {
  &:hover {
    cursor: pointer;
  }

  display: grid;
  gap: 0.5rem;
  align-items: center;
  grid-template-columns: 1fr 20px 26px;

  padding: 8px 12px;
  border: 1px solid var(--gray-40);

  font-family: var(--helvetica400);
  font-size: var(--fSize14);
  letter-spacing: 0.0025em;
  color: var(--black-10);
  font-weight: 400;

  &-url {
    color: var(--gray-10);
    font-size: var(--fSize11);
  }

  &-close,
  &-lock {
    &:hover .icon {
      &:before {
        color: var(--black-0);
      }
    }
  }

  .icon-lock {
    margin-bottom: 4px;
  }

  & > .removeTooltip {
    grid-column: 3/4;
  }

  &-lock {
    & > .icon::before {
      font-size: var(--fSize11);
    }
  }
}
