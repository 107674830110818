.ModalPreviewPdf {
  overflow-y: scroll;
  height: 100%;
  align-items: unset;
  background-color: rgba(0, 0, 0, 0.65);
  top: 0;
  margin-bottom: 2.28rem;
  // 614px
  .ModalPopUp-close {
    position: fixed;
    right: calc(50% - 362px);
    top: 2.5rem;
    z-index: 3;
  }

  .content {
    background-color: transparent;
    box-shadow: none;
    padding-bottom: 2.28rem;
  }

  .container {
    z-index: 2;
    max-width: 750px;
  }

  .backdrop {
    background-color: transparent;
  }

  &-toolbar {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 0 1.14rem;
    margin: 2.28rem 0;
    .disabled {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}
