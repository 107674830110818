.ExpenseTableWrap {
  overflow: auto;
  height: 100%;
}

.ExpenseTable {
  border: none;

  & > thead {
    position: sticky;
    top: 0;
    z-index: 1;

    .TableHeadItem {
      position: relative;

      &.w8,
      &.w9,
      &.ach,
      &.wire,
      &.qp {
        & > * {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .Table-footer {
    position: sticky;
    bottom: 0;
    z-index: 10;
  }

  .Table-header-row {
    .TableHeadItem {
      background: var(--gray-40);

      &:first-child {
        box-shadow: none;
      }
    }
  }

  .ExpenseTableRow {
    .ExpenseTableRow-cell {
      cursor: default;

      &:first-child {
        box-shadow: none;
      }
    }
  }

  .ExpenseTableRowFooter {
    background-color: var(--black-10);
    color: var(--white-0);

    &-cell {
      height: 40px;
      text-align: right;
      padding: 0 12px;

      .AppTooltips {
        transform: translate(-38%, -134%);
      }
    }
  }

  .ExpenseTableRow {
    box-shadow: var(--bottom-line);
    transition: var(--transition);
    cursor: pointer;

    &:hover {
      background-color: var(--blue-30);
    }

    &-cell {
      font-size: var(--fSize13);
      font-family: var(--helvetica400);
      font-weight: 500;
      box-shadow: var(--right-line);
      height: 40px;

      overflow: hidden;

      & > .ETName {
        padding-right: 12px;
      }

      & > .ETEstimate {
        padding: 0 12px;
      }

      .AttachFileWrap {
        width: 48px;
      }

      & > .ETWorkingTotal,
      & > .ETActualTotal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 184px;
        position: relative;
        padding-right: 12px;
        min-height: 40px;
      }

      & > .ETAlert {
        width: 32px;
        height: 40px;

        .icon {
          transform: rotate(180deg);
        }

        &.placeholder {
          .icon {
            opacity: 0;
          }

          &:hover {
            .icon {
              opacity: 1;
            }
          }
        }

        &.loading {
          &:after {
            width: 0.35rem;
            height: 0.35rem;
          }
        }
      }

      & > .ETGreen {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        min-height: 40px;
        margin: 0 auto;
      }

      & > .ETQuickPay {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;

        .QuickPay-checkbox {
          padding-left: 10px;
        }
      }

      & > .ETSelectCell {
        height: 100%;
        display: flex;
        align-items: center;
        min-width: 112px;
      }

      & > .ETAdditionalFiles,
      & > .ETActions {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
