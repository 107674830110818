.Deactivate {
  &-footer {
    padding: 0 1.71rem;
  }

  &-info {
    text-align: center;
  }

  &-form {
    margin: 1rem auto 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
		& > * {
			margin-bottom: 0;
		}
  }
}