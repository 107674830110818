.noscroll {
  overflow: hidden;
}

.ModalPopUp {
  &-container {
    position: relative;
    width: 100%;

    &.fullScreen {
      height: 100vh;
      .content {
        height: 100%;
        border-radius: 0;
      }
    }

    &.w-1230 {
      max-width: var(--modal-width-1230);
    }

    &.xlg {
      max-width: var(--modal-width-xlg);
    }

    &.lg {
      max-width: var(--modal-width-lg);
    }

    &.md {
      max-width: var(--modal-width-md);
    }

    &.w-528 {
      max-width: var(--modal-width-528);
    }

    &.sm {
      max-width: var(--modal-width-sm);
    }
  }

  &-backdrop {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    transition: opacity 0.4s ease;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
  }

  &-portal {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-content {
    padding: 2.85rem 1.71rem 1.71rem;
    transition: opacity 0.4s ease;
    background-color: var(--white-0);
    box-shadow: var(--dark-shadow);
    border-radius: 5px;
    z-index: 2;
    min-width: 300px;
    position: relative;

    &.withoutPaddings {
      padding: 0;
    }
  }

  &-close {
    position: absolute;
    right: -3rem;
    top: -5px;
    border: none;
    background: none;
    box-shadow: none;
    z-index: 2;

    &:focus,
    &:hover {
      transform: scale(1.1);
    }
  }
}
