.JobsTableFilter {
  padding: 1.71rem;
  background-color: var(--white-0);
  box-shadow: var(--dark-shadow);
  width: 340px; // !!!important!!! don't touch this

  &-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px 0;
  }

  &-label {
    width: 33%;
  }

  &-value {
    width: 66%;
    margin-bottom: 0;
  }

  .TagsField {
    margin-bottom: 0;
  }

  .RadioField-fields {
    flex-wrap: nowrap;
  }

  .RadioField-label {
    padding: 0.65rem 0.65em 0.65em;
  }

  &-button {
    width: 100%;
    background-color: var(--gray-40);
    letter-spacing: 0.5px;
    color: var(--black-10);
    margin-top: 1rem;
  }
}