@import "src/assets/styles/placeholders/label";

.ImportModal {
  &-body {
    margin-bottom: 2.85rem;
    text-align: center;
  }

  &-fields-group {
    display: flex;
    justify-content: center;
    margin-top: 2.85rem;
    margin-bottom: 1rem;
    &-label {
      @extend %label;
      margin-top: 1.71rem;
      text-align: center;
      justify-content: center;
    }
  }
  &-message {
    margin: 1.5rem auto 0.25rem;

    &-success {
      color: var(--green-0);
      font-family: var(--helvetica500);
    }
    &-error {
      color: var(--red-0);
      overflow: auto;
      max-height: 300px;
      padding-right: 5px;
    }
  }
}
