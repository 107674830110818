.TeamTableRow {
  --text-max-length: 180px;
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    background: var(--gray-40);
  }

  &.inactive {
    background: var(--white-10);
    opacity: 0.5;
  }

  &.pending {
    background: var(--white-10);
  }

  &-cell {
    padding: 0.71rem 0.5rem;
    font-size: var(--fSize13);
    font-weight: 500;
    position: relative;

    &.active {
      color: var(--green-10);
    }

    &.pending {
      color: var(--orange-0);
    }

    &.inactive {
      color: var(--gray-10);
    }

    .DepartmentList {
      flex-wrap: nowrap;
    }

    &.name {
      display: flex;
      align-items: center;
      gap: 0.57rem;
      font-weight: 500;

      & > .text {
        text-transform: capitalize;
        max-width: var(--text-max-length);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-family: var(--helvetica500);
        font-weight: 500;
      }
    }

    .title {
      display: inline-block;
      max-width: var(--text-max-length);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .email {
      display: inline-block;
      max-width: var(--text-max-length);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.phone {
      & > .number {
        white-space: nowrap;
      }

      .ButtonCopyClipboard {
        right: 0;
      }

      &:hover {
        .ButtonCopyClipboard {
          opacity: 1;
        }
      }
    }
  }
}
