.CrewTableGroupHeaderRow,
.CrewTableGroupHeaderRowTogglerWrap {
  background-color: var(--white-0);
  padding: 14px 0 var(--8px) 0;
  border-bottom: 1px solid var(--gray-100);
}

.CrewTableGroupHeaderRowTogglerWrap {
  grid-column: 1/2;
  position: sticky;
  left: var(--16px);
  margin-left: var(--16px);

  padding-top: 24px;

  .togglerContactsBtn {
    gap: var(--16px);

    &.hideIcon {
      cursor: default;

      .icon {
        visibility: hidden;
      }
    }
  }
}

.CrewTableGroupHeaderRow {
  grid-column: 2/-1;

  display: flex;

  &-btnWrap {
    display: flex;
    margin-left: auto;
    position: sticky;
    right: 0;
    padding-right: 2rem;
    gap: var(--8px);
  }
}
