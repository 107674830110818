.CallSheetTemplateContacts {
  display: grid;
  // grid-template-columns: repeat(4, 1fr) max-content max-content;
  grid-template-columns: 1fr 1fr 1fr repeat(2, max-content) 1fr;
  gap: 0 1rem;
  position: relative;
  align-items: center;

  & > .CallSheetTemplateContactsHeader {
    grid-column: 1/-1;

    &:not(:first-child) {
      margin-top: 3.7rem;
    }
  }

  & > .CSContact {
    padding-bottom: 1.4rem;
    font-size: var(--fSize14);
    font-family: var(--helvetica400);

    &.name {
      font-family: serif;
      font-family: var(--helvetica700);
      font-size: var(--fSize17);
      text-transform: uppercase;
      word-break: break-word;

      &.agent {
        font-size: var(--fSize15);
        padding-left: 0.5rem;
      }
    }

    &.time,
    &.location {
      font-family: var(--helvetica500);
    }
  }
}
