@import "src/assets/styles/partials/tag";
@import "src/assets/styles/placeholders/label";
@import "src/assets/styles/placeholders/input";

.SearchButtonField {
  position: relative;

  &-button {
    width: 100%;
    height: 38px;
    &.hidden {
      display: none;
    }
  }

  .SearchButtonField {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }
  &-container-show {
    transition: 0ms;
    .SearchButtonField {
      opacity: 1;
      z-index: unset;
      position: relative;
    }
  }

  &-label {
    @extend %label;
  }

  .SearchButtonField-select__indicators {
    display: none;
  }

  .SearchButtonField-select__control {
    @extend %input;
    padding: 0;
    box-shadow: none;
    text-align: left;
    &--is-focused {
      border: 1px solid var(--gray-20) !important;
    }
    &:hover {
      border: 1px solid var(--gray-20) !important;
    }
  }

  .SearchButtonField-select__option {
    cursor: pointer;
    text-align: left;
    z-index: 12;
    background-color: var(--white-0);
    color: var(--black-0);
    &--is-focused {
      background-color: var(--gray-40);
    }
    &:active {
      background-color: var(--gray-40);
    }
  }
  .SearchButtonField-select__menu-notice {
    text-align: left;
    background-color: var(--gray-40);
    color: var(--black-0);
    font-weight: 500;
  }
  .SearchButtonField-select__menu-list {
    z-index: 4;
    padding-top: 0;
    padding-bottom: 0;
  }
  &-AddButton {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
  }
}
