.ExpenseRowDifference {
  padding: 0 !important;

  & > .differenceItem {
    border-right: 1px solid var(--gray-40);
    height: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    &.DropdownCellWrap {
      width: 120px;
      & > * {
        //hotfix heed to improve table
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: inherit;
        max-width: inherit;
      }
    }

    &.QuickPay,
    &.Green {
      padding: 0 12px;
    }

    &.AdditionalFiles,
    &.Actions {
      width: 40px;
      padding: 0 12px;
    }

    &.Note {
      width: 300px;
      // cursor: pointer;
      // padding: 0 12px;
    }
  }
}
