.ButtonCopyClipboard {
  position: absolute;
  right: -2rem;
  transform: translateY(-20%);
  padding: 0.4rem;
  font-family: var(--helvetica700);
  font-size: var(--fSize12);
  transition: var(--transition);
  background-color: var(--gray-90);
  opacity: 0;
  z-index: 2;
}
