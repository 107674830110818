@import "src/assets/styles/placeholders/label";

.RadioField {
  display: flex;
  flex-wrap: wrap;
  gap: 0 8px;
  &.disabled {
    .RadioField-label {
      background-color: var(--gray-40);
      color: var(--gray-10);
    }
  }
  &-fields {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
  }
  &-input {
    opacity: 0;
    position: fixed;
    left: -100%;
  }

  &-input:checked {
    ~ .RadioField-label {
      background-color: var(--gray-40);
      .RadioField-flag:after {
        content: "";
        background: var(--black-10);
        width: 9px;
        height: 9px;
        border-radius: 50%;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &-title {
    @extend %label;
  }

  &-label {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    font-size: var(--fSize14);
    padding: 0.65rem 0.85em 0.65em;
    width: fit-content;
    border: 1px solid var(--gray-40);
    border-radius: 0.142rem;
  }

  &-flag {
    position: relative;
    border: 1px solid var(--gray-20);
    width: 16px;
    height: 16px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50%;
    background: var(--white-0);
  }

  &-error-message {
    color: var(--red-0);
    padding-top: 0.25rem;
    font-size: var(--fSize13);
    text-align: left;
  }
}
