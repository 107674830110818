.CallSheetHeader {
  min-height: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 2.28rem;
  box-shadow: var(--bottom-line);

  &-rightSide {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-sheetsMenu.Button {
    margin-left: 1.71rem;
    margin-right: 8px;
    min-width: 200px;
    font-weight: 700;
    justify-content: space-between;
    &:hover,
    &:focus,
    &:active {
      border-color: var(--black-0);
    }
  }

  &-title {
    font-family: var(--helvetica700);
  }

  .ButtonGroup {
    .disabled {
      border-color: var(--gray-50);
    }
  }
}

.CallSheetHeader {
  &-dropdown {
    .DropdownMenu-body {
      overflow: visible;
      min-width: 200px;
    }

    .Button {
      width: 100%;
      justify-content: space-between;
      font-weight: 500;

      &:hover {
        background-color: var(--gray-40);
        font-weight: 700;
        border-radius: 0;
      }

      &.active {
        font-weight: 700;
      }
    }
  }
  &-submenuWrap {
    position: relative;

    &:hover {
      & > .submenu {
        display: block;
      }
    }

    .submenu {
      display: none;
      position: absolute;
      left: -127px;
      top: 0;
      background-color: var(--white-0);
      box-shadow: var(--dark-shadow);
    }
  }
}
