.AttachFileWrap {
  padding: 0;
  display: flex;
  justify-content: center;
  height: 100%;

  width: 40px;

  & > .showModalBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}
