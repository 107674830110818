.CurrencyRatesTable {
  width: 100%;
  border-collapse: collapse;

  table,
  td,
  th {
    border: 1px solid var(--gray-40);
  }

  th,
  td {
    padding: 0.71rem 0.57rem;
  }

  &-head {
    th {
      color: var(--gray-10);
      font-weight: 500;
      font-size: var(--fSize10);
      text-transform: uppercase;
      background-color: var(--white-10);
    }
  }

  &-row {
    td {
      font-size: var(--fSize13);
      height: 40px;

      &.CurrencyNameCell {
        display: flex;
        display: flex;
        justify-content: space-between;
        gap: 0.3rem;
        align-items: center;

        .icon {
          opacity: 0;
        }

        &:hover {
          .icon {
            opacity: 1;
          }
        }
      }
    }
  }

  .text-right {
    text-align: right;
  }

  &-addButton {
    margin-top: 0.57rem;

    &:disabled {
      background-color: transparent !important;
      color: var(--gray-10) !important;
    }
  }
}
