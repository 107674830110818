.EstimateTotalCard {
  margin: 0 var(--16px) 0;
  padding: var(--16px);

  background-color: var(--white-40);
  border: 1px solid var(--gray-40);
  border-radius: var(--8px);

  margin-top: auto;

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.286rem;
  }

  &-hiddenItems {
    display: flex;
    gap: var(--8px);

    & > .togglerBtn {
      color: var(--gray-20);
      letter-spacing: 0.5px;
      font-size: var(--fSize10);
    }
  }

  &-detailsInfo {
    margin-top: 0.93rem;
    display: flex;
    align-items: center;
    gap: 0.286rem;

    & > div:last-child {
      margin-left: auto;
    }
  }

  &.approved {
    border: 1px solid var(--green-30);
    position: relative;
    padding-bottom: 3.429rem;

    .approvedFooter {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: var(--green-30);
      border-bottom-right-radius: calc(var(--8px) / 2);
      border-bottom-left-radius: calc(var(--8px) / 2);
      padding: var(--8px) var(--16px);
      overflow: hidden;

      display: flex;
      align-items: center;
      gap: var(--8px);

      & > div:last-child {
        margin-left: auto;
      }

      & > .iconWrap {
        width: var(--16px);
        height: var(--16px);
        background-color: var(--white-0);
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          line-height: var(--16px);
        }
      }
    }
  }
}
