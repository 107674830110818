.ListLineLayout {
  display: flex;
  height: 40px;
  align-items: center;
  box-shadow: var(--bottom-line);
  transition: var(--transition);

  &:hover {
    font-weight: 700;
  }

  & > *:first-child {
    flex-grow: 1;
  }

  &:hover {
    & > button {
      opacity: 1;
      flex-shrink: 0;
    }
  }

  & > button {
    opacity: 0;
    width: 30px;

    &:hover {
      .icon {
        color: var(--black-0);

        svg path {
          fill: var(--black-0);
        }
      }
    }
  }
}
