.Departments {
  display: flex;
  justify-content: center;
  gap: 1rem;

  &-error {
    color: var(--red-0);
    margin-top: 0.6rem;
  }

  .Department {
    &:hover {
      .label {
        opacity: 1;
      }
    }

    & > .input {
      display: none;

      &:checked {
        & + label {
          border-color: inherit;
          opacity: 1;
        }
      }
    }

    .label {
      opacity: 0.6;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.57rem;
      border: 1px solid var(--gray-40);
      border-radius: 0.36rem;
      width: 10.7rem;
      height: 7.42rem;

      &-abbr {
        width: 2.85rem;
        height: 2.85rem;
        text-align: center;
        line-height: 2.85rem;
        color: var(--white-0);
        border-radius: 50%;
      }

      &-title {
        font-family: var(--helvetica700);
        font-size: var(--fSize13);
        letter-spacing: 0.02rem;
        text-transform: uppercase;
      }
    }
  }
}
