.PdfViewer {
  &-page {
    margin-bottom: 1.71rem;
    box-shadow: var(--light-shadow);
    // border: 1px solid var(--gray-30);
  }

  canvas {
    margin: 0 auto;
  }
}
