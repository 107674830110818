.FieldSetup {
  .err {
    color: var(--red-0);
    margin: 0 0 0.5rem;
  }

  &-body {
    display: grid;
    gap: 1.14rem;
    grid-template-columns: 1fr;
    margin-bottom: 1.14rem;
    position: relative;
  }
  &-row {
    display: flex;
    align-items: flex-start;
    .InputField {
      margin: 0;
    }
  }
  &-button-ordering {
    width: 1.14rem;
    display: flex;
    margin-top: 0.7rem;

    &-line {
      display: block;
      border-bottom: 1px solid var(--gray-10);
      margin: 3px 0;
      width: 75%;
      &:after,
      &:before {
        content: "";
        border-bottom: 1px solid var(--gray-10);
        width: 100%;
        display: block;
        margin: 3px auto;
      }
    }
  }
  &-remove-button {
    width: 1.71rem;
    height: 1.71rem;
    margin: 0.7rem 0 0 0;
  }
  .button__add {
    max-height: 32px;
    min-width: 238px;
    margin: 0 auto;
  }
}
