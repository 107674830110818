@import "src/assets/styles/placeholders/_input.scss";

.InputField {
  position: relative;
  width: 100%;
  margin-bottom: 1.14rem;
  display: flex;
  flex-direction: column;

  &.error {
    .label {
      color: var(--red-0);
    }
  }
  &-label {
    @extend %label;
  }

  &-input {
    @extend %input;
    font-size: var(--fSize13);
  }

  &-error-message {
    color: var(--red-0);
    padding-top: 0.25rem;
    font-size: var(--fSize13);
    text-align: left;
  }
  &-note {
    margin-top: 0.25rem;
    font-size: 0.75em;
    color: var(--gray-10);
  }
  &.error .InputField-input {
    border-color: var(--red-0);
  }

  //uses in Budget
  &.horizontalLabel {
    display: grid;
    grid-template-columns: 1fr 80px;

    & > .InputField-label,
    & > .InputField-input {
      font-size: var(--fSize13);
      font-family: var(--helvetica500);
      color: var(--black-10);
      text-transform: capitalize;
    }
  }

  & > .inputWrap {
    position: relative;
    overflow: hidden;

    border: 1px solid var(--gray-30);
    border-radius: 3px;
    &.prefix {
      .inputShadow {
        width: 100%;
      }
    }

    .inputShadow {
      position: absolute;
      top: 0;
      left: 0;
      font-size: var(--fSize13);
      color: var(--black-10);
      text-transform: capitalize;
      z-index: 0;

      padding: 0.75rem 0.86rem;
    }

    .InputField-input {
      border: none;
      color: transparent !important;
      background-color: transparent !important;
      caret-color: var(--black-10);
    }
  }
}
