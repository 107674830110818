@import "src/assets/styles/placeholders/input";
@import "src/assets/styles/placeholders/label";

.TextareaField {
  position: relative;
  width: 100%;
  margin-bottom: 1.14rem;
  display: flex;
  flex-direction: column;

  &.error {
    .label {
      color: var(--red-0);
    }

    .input {
      border-color: var(--red-0);
    }
  }
  &-input {
    @extend %input;
    min-width: 100%;
    min-height: 120px;
  }

  &-label {
    @extend %label;
  }

  &-error-message {
    padding-top: 0.25rem;
    color: var(--red-0);
    font-size: var(--fSize13);
    text-align: left;
  }
}
