.Tag {
  display: flex;
  align-items: center;
  border: 1px solid var(--gray-40);
  font-family: var(--helvetica500);
  color: var(--black-0);
  width: max-content;
  border-radius: 2px;
  text-transform: uppercase;
  letter-spacing: 0.01rem;

  &-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-button {
    display: flex;
    align-items: center;
    margin-left: 4px;
  }

  &.lg {
    padding-left: 8px;
    padding-right: 8px;
    font-size: var(--fSize11);
    min-height: 32px;
  }

  &.sm {
    padding-left: 6px;
    padding-right: 6px;
    font-size: var(--fSize10);
    min-height: 20px;
  }

  &.primary {
    background: var(--white-10);
    border: 1px solid #ededf0;
  }

  &.outline {
    background: var(--white-0);
  }
}
