.LocationCard {
  background-color: var(--white-0);
  border: 1px solid var(--gray-40);
  border-radius: 5px;
  padding-bottom: 12px;
  text-align: center;
  overflow: hidden;

  cursor: pointer;

  & > .imageWrap {
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;

    & > .image {
      object-fit: cover;
      width: 100%;
      height: 100%;

      &.empty {
        object-fit: contain;
        height: 90%;
      }
    }
  }

  & > .Title {
    margin-bottom: 0;
    padding: 0 0.5rem;
  }
}
