.EstimateSidebarActions {
  display: flex;
  gap: var(--8px);

  margin: 0 var(--16px);
}

.ApproveEstimateForm {
  text-align: center;

  &-date {
    width: 120px;
    margin-left: auto;
    margin-right: auto;
  }
}
