.EmptyPageState {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;

  &-image {
    margin-top: auto;
    margin-bottom: 4rem;
  }

  &-text {
    font-size: var(--fSize20);
    font-family: var(--helvetica300);
    margin-bottom: 2.85rem;
  }

  &-buttons {
    display: flex;
    gap: 0 32px;
    .Button {
      min-width: 200px;
    }
  }

  &-remove.Button {
    font-weight: 500;
    text-transform: uppercase;
    color: var(--gray-10);
    font-size: var(--fSize10);
    margin-top: auto;
    margin-bottom: 2.85rem;
  }
}
