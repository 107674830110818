.EditJobDetails {
  .content {
    padding: 0;
  }

  &-form {
    height: calc(100vh - 160px);
    display: grid;
  }

  &-header {
    padding: 1.42rem 1.71rem;
    background-color: var(--white-40);
    text-transform: uppercase;
    box-shadow: var(--bottom-line);
    border-radius: 5px 5px 0 0;
    h2 {
      font-family: var(--helvetica700);
    }
  }
  &-body {
    padding: 2.28rem 1.71rem;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 0.57rem 0;

    .title {
      font-family: var(--helvetica700);
    }
  }
  &-row {
    display: flex;
    gap: 0 8px;
    width: 100%;
    .job-number {
      max-width: 120px;
    }
  }
  &-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    box-shadow: var(--top-line);
    padding: 0.85rem 1.71rem;
    .Button {
      min-width: 128px;
      &:not(:last-child) {
        font-family: var(--helvetica300);
        color: var(--gray-10);
      }
    }
  }

  .SearchField-contacts {
    .ReactSelect-select__value-container {
      padding-left: 2px;
    }
  }

  &-controlRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 3rem 0 0;

    .date {
      color: var(--gray-10);
    }
    .delete {
      color: var(--gray-20);
    }
  }
}
