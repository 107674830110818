.Accordion {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.42rem 1.71rem;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: var(--bottom-line);
    grid-gap: 0.5rem;

    &-title {
      line-height: 1;
      font-family: var(--helvetica700);
      color: var(--black-10);
    }

    &-icon {
      transition: transform 0.15s ease-in;

      &.active {
        transform: rotate(90deg);
      }
    }
  }

  &-body {
    height: 0;
    overflow: hidden;
  }

  &-input {
    position: fixed;
    opacity: 0;
    left: -100%;

    &:checked~.Accordion-body {
      height: auto;
      overflow: visible;
      box-shadow: var(--bottom-line);
    }
  }
}