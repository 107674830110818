.ManageTagsForm {
  &-title {
		font-family: var(--helvetica700);
    font-size: var(--fSize20);
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
  }

  &-body {
    margin-bottom: 2.5rem;
    p {
      margin-bottom: 2rem;
    }
    .InputField-input {
      &::placeholder {
        text-transform: capitalize;
      }
    }
  }

  &-buttons {
    display: flex;
    grid-gap: 0.5rem;

    .Button {
      flex: 1;

      &.border {
        border: 1px solid var(--gray-30);
      }
    }
  }
}
