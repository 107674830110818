@import "src/assets/styles/placeholders/label";

.PeopleDetails {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  padding: 1.75rem;

  &-align-self-center {
    align-self: center;
  }

  &-Accordion {
    box-shadow: var(--bottom-line);

    .Accordion-header {
      justify-content: flex-end;
      flex-direction: row-reverse;
      padding-left: 0;
      box-shadow: none;
      gap: 0.5rem;
    }

    .TagsGroup {
      flex-wrap: wrap;

      &.team,
      &.locations {
        .TagButton {
          cursor: unset;
          transform: none;
        }
      }
    }
  }

  .TagButtonList {
    .Tooltip {
      margin-right: 0;
    }
  }
}
