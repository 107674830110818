.JobTaskTable {
  overflow: auto;
  height: 100%;
  z-index: 0;

  & > Table {
    border: none;

    .DragAndDropWrap {
      opacity: 1;
    }

    & > thead {
      position: sticky;
      top: 0;
      z-index: 1;

      .task-name {
        padding-left: 60px;
      }
    }
  }

  &-group {
    height: 72px;
    padding: 0 32px 0 16px;

    display: flex;
    align-items: center;

    .groupToggleBtn {
      padding: 16px;
    }

    .groupEditBtn {
      margin-left: auto;
    }
  }
}
