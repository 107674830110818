.JobTeamTag {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 1.14rem;

  .TagButton {
    min-height: 40px;
    padding: 2px 1.14rem 2px 2px;
    &-child {
      display: flex;
      align-items: center;
    }
  }

  &-avatar {
    width: 32px;
    height: 32px;
    margin-right: 0.5rem;
    border-radius: 50%;
  }
}
