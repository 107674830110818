:root {
  --scale-factor: 1;
}

.Viewer {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr;
  background-color: var(--white-0);

  &-pagesWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &-imgWrap {
    height: 100%;
    overflow: auto;

    &.placeholder {
      display: flex;
      align-items: center;
      justify-content: center;

      & > img {
        max-width: 50%;
      }
    }
  }

  &-img {
    width: 100%;
    object-fit: contain;
    transform-origin: top left;
  }
}
