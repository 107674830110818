@import "src/assets/styles/partials/tag";
@import "src/assets/styles/placeholders/label";
@import "src/assets/styles/placeholders/input";

.ContactFieldItem {
  &-row {
    display: flex;
    gap: 8px;
    margin-bottom: 1rem;
    align-items: flex-start;
  }
  &-relationship {
    width: 60%;
  }

  &-name {
    padding: 0.92rem 1.42rem;
    border-radius: 1.42rem;
    text-transform: uppercase;
    background-color: var(--gray-30);
    font-size: var(--fSize11);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    .icon {
      margin-left: 1rem;
      cursor: pointer;
    }
  }

  &-error {
    color: var(--red-0);
    padding-top: 0.25rem;
    font-size: var(--fSize13);
    text-align: left;
  }

  &-label {
    @extend %label;
  }

  &-select {
    &__control {
      @extend %input;
      padding: 0;
      box-shadow: none !important;
      border-color: var(--gray-30);
      text-align: left;
      min-height: 40px;
      &--is-focused {
        border-color: var(--gray-20) !important;
      }
    }

    &__option {
      text-align: left;
      z-index: 12;
      background-color: var(--white-0);
      color: var(--black-0);
      &--is-focused {
        background-color: var(--gray-40) !important;
      }

      &--is-selected {
        background-color: var(--gray-30) !important;
        color: var(--black-0) !important;
      }
    }

    &__menu-notice {
      text-align: left !important;
      background-color: var(--gray-40);
      color: var(--black-0);
      font-weight: 500;
    }
    &__menu-list {
      z-index: 4;
      padding-top: 0;
      padding-bottom: 0;
      max-height: 200px !important;
    }
  }

  .ContactFieldItem-select__indicator-separator {
    display: none;
  }
  .ContactFieldItem-select__dropdown-indicator {
    display: none;
  }
}
