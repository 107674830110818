.TableTaskRow {
  box-shadow: none;
  border-top: 1px solid var(--gray-100);

  .TableTaskRow-cell {
    height: 48px;
    border-right: 1px solid var(--gray-100);
    border-bottom: 1px solid var(--gray-100);

    & > * {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &.name {
      width: 475px;
      min-width: 475px;
    }

    &.rowInitName {
      padding-left: 42px;
    }

    &.assignee {
      width: 160px;
      min-width: 160px;
    }

    &.date {
      width: 112px;
      min-width: 112px;
    }

    &.status {
      width: 112px;
      min-width: 112px;
      background-color: var(--gray-30);
    }

    &.note {
      width: 340px;
      min-width: 340px;
    }

    &.reminder {
      width: 128px;
      min-width: 128px;
    }

    &.file,
    &.actions {
      width: 48px;
      min-width: 48px;
    }
  }
}
