.CallSheetBuildLocations {
  display: grid;
  grid-template-columns: 1fr 640px;
  gap: var(--24px);

  margin-bottom: 3.42rem;

  &-locationItem {
    margin-bottom: var(--32px);

    & > *:first-child {
      margin-bottom: 0.4rem;
    }

    & > *:not(&:first-child) {
      line-height: 1.2;
    }
  }

  &-upload {
    max-height: 480px;
    width: 100%;
    max-width: 640px;

    position: relative;
    display: flex;
    justify-content: center;

    & > .uploadImage {
      max-width: 640px;
      max-height: 480px;
      height: auto;
      // width: auto;
    }

    .LocationForm-upload-button,
    .Upload-btn {
      background: var(--gray-40) !important;
      top: 0.85rem;
      right: 0.85rem;
    }

    &.placeholder {
      background-color: var(--gray-20);

      & > .uploadImage {
        display: none;
      }

      .LocationForm-upload-button,
      .Upload-btn {
        top: 50% !important;
        right: 50% !important;
        transform: translate(50%, -50%) !important;
      }
    }
  }
}
