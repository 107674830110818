.LoadFromTemplate {
  & * .emptyBlock {
    position: relative;
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
