@import "src/assets/styles/index";

.JobTagsField {
  width: 100%;
  margin-bottom: 1.14rem;

  &.bottom {
    display: flex;
    flex-direction: column;
    margin-top: 0;

    .ReactSelect {
      order: -1;
      margin-bottom: 1.14rem;
    }
  }

  .label {
    font-family: var(--helvetica700);
    width: 100%;
    display: inline-block;
    font-size: var(--fSize13);
    font-weight: 700;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    margin-bottom: 1.14rem;
  }

  .Button {
    width: 100%;
    height: 40px;
  }

  &-error {
    padding-top: 0.25rem;
    color: var(--red-0);
    font-size: var(--fSize13);
    text-align: left;
  }
  &-MultiValue-placeholder {
    color: hsl(0, 0%, 50%);
    position: absolute;
    left: 9px;
    top: 50%;
    transform: translateY(-50%);
  }
}
