.TagsButtonRow {
  &-detailsList {
    display: flex;
    flex-direction: column;
    background-color: var(--white-40);
    border-radius: 12px;

    .TagButton {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 3px;
      }

      &-child {
        width: 100%;
      }
    }
  }

  .TagButton-child {
    display: flex;
    align-items: center;
		span {
			overflow: hidden;
			text-overflow: ellipsis;
		}
  }
}