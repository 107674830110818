@import "../../../components/shared/Button/Button.scss";

.FormWrap {
  &.withScroll {
    max-height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;

    .FormWrap-header {
      flex-shrink: 0;
    }

    .FormWrap-main {
      overflow-y: auto;
    }

    .FormWrap-footer {
      position: sticky;
      bottom: 0;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    text-transform: uppercase;
    box-shadow: var(--bottom-line);
    padding: 0 24px; // 18px 24px
    background-color: var(--white-40);
    border-radius: 5px;
    height: 4.57rem;
    &-title {
      font-family: var(--helvetica700);
      font-size: var(--fSize20);
      color: var(--black-10);
    }
    &-subtitle {
      font-family: var(--helvetica500);
      font-size: var(--fSize10);
      letter-spacing: 0.01rem;
      color: var(--gray-10);
    }
    &-button {
      position: absolute;
      left: 16px;
    }
  }

  &-main {
    padding: 32px 24px;
  }

  &-footer {
    box-shadow: var(--top-line);
    padding: 12px 24px;

    display: flex;
    justify-content: flex-end;
    gap: 10px;

    & > .Button {
      min-width: 128px;
      &:not(:last-child) {
        font-family: var(--helvetica300);
        color: var(--gray-10);
      }
    }
  }

  &.titleCenterAndOneBtn {
    .FormWrap {
      &-header {
        text-align: center;
      }

      &-footer {
        box-shadow: none;
        padding: 8px 24px 24px;

        justify-content: center;

        & > .Button {
          width: 100%;
          @extend %btnDark;
        }
      }
    }
  }

  &.titleCenterAndTwoBtn {
    .FormWrap {
      &-header {
        text-align: center;
      }

      &-footer {
        box-shadow: none;
        padding: 8px 24px 24px;

        justify-content: space-between;

        & > .Button:first-child {
          width: 100%;
          @extend %btnLight;
        }

        & > .Button:last-child {
          width: 100%;
          @extend %btnDark;
        }
      }
    }
  }
}
