.UploadFileButton {
  border: 1px solid var(--gray-30);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 32px;
  letter-spacing: 0.5px;
  cursor: pointer;
  padding: 0 12px;

  & > input {
    display: none;
  }

  &:hover {
    background-color: var(--black-10);
    color: var(--white-0);
  }
}
