.DepartmentList {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  &.stacked {
    flex-wrap: nowrap;

    .item {
      &:not(:first-child) {
        margin-left: -14px;
      }
    }
  }

  &-item {
    width: 1.72rem;
    height: 1.72rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--white-0);
    text-transform: uppercase;
    font-size: var(--fSize10);
    font-family: var(--helvetica700);

    &.Production {
      background-color: var(--orange-0);
    }

    &.Post {
      background-color: var(--purple-0);
    }

    &.Equipment {
      background-color: var(--blue-0);
    }

    &.Accounting {
      background-color: var(--grey-0);
    }

    &.Office {
      background-color: var(--grey-0);
    }

    &.Marketing {
      background-color: var(--grey-0);
    }
  }
}
