.EstimateSidebarDetails {
  padding: 0 var(--16px);

  & > div {
    padding-right: var(--16px);
    padding-left: var(--16px);

    display: flex;
    flex-direction: column;
    gap: var(--8px);

    & > .total {
      margin-top: var(--8px);
      display: flex;
      justify-content: space-between;
    }

    &:first-child {
      box-shadow: var(--bottom-line);
      padding-bottom: var(--16px);
      margin-bottom: var(--16px);
    }

    .InputField.horizontalLabel {
      grid-template-columns: 1fr 72px;
    }
  }

  .GlobalPercentWrap {
    position: relative;

    .AppTooltips {
      transform: translateX(80%);
      z-index: 10;
      top: 90%;
    }
  }
}
