@import "src/assets/styles/placeholders/label";

.PeopleFormModal {
  .content {
    padding: 0;
  }
}

.PeopleForm {
  overflow: hidden;

  .tag-link {
    min-height: 2.85rem;
  }

  &-col-2 {
    display: flex;
    grid-gap: 0.5rem;
    margin-bottom: 1.14rem;

    & > div {
      flex: 1;
    }

    .InputField {
      margin-bottom: 0;
    }
  }

  &-col-3 {
    display: flex;
    grid-gap: 0.5rem;

    & > div {
      flex: 1;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--delete-button {
      text-transform: uppercase;
      font-family: var(--helvetica700);
      color: var(--gray-20);
    }
  }

  &-info {
    color: var(--gray-10);
  }

  &-section {
    margin-bottom: 2rem;

    &-title {
      font-family: var(--helvetica700);
      text-transform: uppercase;
      text-align: left;
      margin-bottom: 1.14rem;
    }

    &-checkbox {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.57rem;

      .Checkbox {
        margin-right: 0.57rem;
      }
    }
  }

  .billingDefault {
    margin-bottom: 1.5rem;
  }

  &-button-next {
    width: 100%;
    font-size: var(--button-font-sm);
    padding: var(--button-size-md);
    margin-top: auto;
  }

  .PeopleFormAddress,
  .PeopleFormOther,
  .PeopleFormMain,
  .PeopleFormWork {
    padding: 1.71rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  // Main Tab styles
  .PeopleFormMain {
    &__PhotoField {
      margin: 0 auto 1.71rem;
    }
  }

  .PeopleFormOther {
    &-select {
      width: 50%;
      margin-bottom: 1.71rem;
    }
    .TextareaField,
    .PeopleForm-section-checkbox {
      margin-bottom: 1.71rem;
    }
    .CheckBoxGroupe {
      gap: 0;
    }
  }

  // Work Tab styles
  .PeopleFormWork {
    .tag-link {
      font-size: var(--fSize12);
      padding: 0.15rem 0.85rem;
    }

    .button__add {
      min-height: 40px;
      display: flex;
      align-items: center;
      grid-gap: 0.5rem;
    }
  }

  &-confirmWindow {
    &-body {
      margin-bottom: 2.85rem;
      text-align: center;
    }

    &-warning {
      color: var(--red-0);
      margin-top: 1.14rem;
    }
  }
}
