.Title {
  font-family: var(--helvetica700);
  font-size: var(--fSize17);
  text-transform: uppercase;
  color: var(--black-10);
  align-self: center;

  margin-bottom: 16px;

  &.sm {
    font-family: var(--helvetica400);
    font-size: var(--fSize11);
    text-transform: uppercase;
    letter-spacing: 0.02em;
    color: var(--gray-0);
    font-weight: 400;
  }

  &.fullWidth {
    grid-column: 1/-1;
  }
}
