.TaskNameCell {
  &:hover {
    .TaskNameCell-sortIcon {
      opacity: 1;
    }
  }

  &-sortIcon {
    opacity: 0;
    margin-left: 28px;
  }

  .InputCell-text {
    padding-left: 8px;
  }
}
