@import "src/assets/styles/placeholders/label";

.TimeField {
  &-control {
    font-family: var(--helvetica400);
    background-color: var(--white-0);
    min-height: 40px;
    border: 1px solid var(--gray-30);
    border-radius: 3px;
    outline: none;
    position: relative;
    width: 100%;
    font-size: 1rem;
    padding: 0 0 0 0.86rem;
    box-sizing: border-box;
    resize: none;
    transition: var(--animation-timing);
    color: var(--black-10);
    display: flex;
    max-height: 40px;
  }

  &.disabled {
    .control {
      background-color: var(--gray-40);
      border-color: var(--gray-40);
      .InputField-input,
      .TimeField-timezone,
      .TimeField-separator,
      .Select-input {
        color: var(--gray-10);
      }
    }
  }
  &.error {
    .control {
      border-color: var(--red-0);
    }
  }

  .input {
    padding: 0;
    border-color: transparent;
    margin-bottom: 0;

    background-color: transparent;
  }

  .InputField {
    margin-bottom: 0;
    flex-direction: unset;
  }

  &-dayPart {
    margin-bottom: 0;
  }

  &-separator {
    display: flex;
    align-items: center;
  }

  .Select {
    margin-bottom: 0;
    &-icon {
      display: none;
    }
  }

  &-minutes {
    padding: 0;
    max-width: 21px;
  }

  &-hours {
    .input {
      text-align: right;
      height: 40px;
    }
  }

  &-timezone {
    display: flex;
    align-items: center;
    margin-left: 4px;
    margin-top: 3px;
  }

  &-zone {
    .Select-input {
      height: 100%;
    }
  }

  &-label {
    @extend %label;
  }
}
