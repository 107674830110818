.CrewTableGroupHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-100);
  height: 53px;
  padding-bottom: 7px;

  position: sticky;
  left: 0;
  width: calc(100vw - 20px);
  background-color: var(--white-0);

  &-togglerBtn {
    margin-left: var(--16px);
    &.hideIcon {
      cursor: default;

      .icon {
        visibility: hidden;
      }
    }
  }

  &-menu {
    display: flex;
    margin-left: auto;
    position: sticky;
    right: 0;
    padding-right: 2rem;
    gap: var(--8px);
  }
}
