@import "src/assets/styles/index";

.JobContactsField {
  width: 100%;
  margin-bottom: 1.14rem;

  &-error {
    color: var(--red-0);
    display: inline-block;
    padding-top: 0.25rem;
    font-size: var(--fSize13);

    &:nth-last-of-type(1) {
      margin-bottom: 0.67rem;
    }
  }

  .title {
    width: 100%;
    display: inline-block;
    font-size: var(--fSize13);
    font-weight: 700;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    margin-bottom: 1.14rem;
  }

  .Button {
    width: 100%;
    height: 40px;
  }
  &-MultiValue-placeholder {
    color: hsl(0, 0%, 50%);
    position: absolute;
    left: 9px;
    top: 50%;
    transform: translateY(-50%);
  }
}
