.InputWithReload {
  // max-width: 166px;
  position: relative;
  &-button {
    position: absolute;
    z-index: 1;
    margin-left: 1rem;
    top: 0.6rem;
  }
  .InputField-input {
    text-align: right;
  }

  .inputShadow {
    padding-left: 46px !important;
    white-space: nowrap;
  }
}
