.PipelineSelection {
  position: relative;
  padding: 0.85rem 1.14rem;
  border-radius: 4px;
  box-shadow: var(--dark-shadow);
  background-color: var(--white-0);

  display: flex;
  gap: 2px 4px;

  font-weight: 500;
  font-size: var(--fSize10);
  text-transform: uppercase;

  min-width: 345px;
  min-height: 235px;

  &-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 2px 4px;
    font-weight: 500;
    font-size: var(--fSize10);
    text-transform: uppercase;
    min-width: max-content;

    &:nth-of-type(1) {
      .PipelineSelection-item {
        background-color: var(--gray-110);
      }
    }
    &:nth-of-type(2) {
      .PipelineSelection-item {
        background-color: var(--yellow-0);
      }
    }
    &:nth-of-type(3) {
      .PipelineSelection-item {
        background-color: var(--blue-10);
      }
    }
    &:nth-of-type(4) {
      .PipelineSelection-item {
        background-color: var(--green-10);
        &:nth-last-of-type(1) {
          opacity: 0.6;
        }
      }
    }
  }

  &-item {
    padding: 0.71rem 0.57rem;
    font-weight: 500;
    font-size: var(--fSize10);
    text-transform: uppercase;
    display: flex;
    min-width: max-content;
    position: relative;
  }
}
