.EstimateRowTotal {
  background-color: var(--white-40);
  padding: 0 10px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.hidden {
    color: var(--gray-20);
  }

  & > .Button {
    opacity: 0;
    padding: 0 5px;
    transform: none;
  }

  &:hover {
    & > .Button {
      opacity: 1;
    }
  }
}
