.AssigneeDropdownItem {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  &-img {
    height: 32px;
    width: 32px;
    border: 1px solid var(--gray-100);
    border-radius: 50%;
    flex-shrink: 0;
  }

  .pseudoCheckBox {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gray-40);
    margin-left: auto;
    flex-shrink: 0;

    .icon {
      font-size: 16px;
    }
  }
}
