.EstimateStatusLine {
  display: flex;
  align-items: center;
  gap: 0 0.85rem;

  &-approved {
    color: var(--green-0);
    font-size: var(--fSize17);
    text-transform: uppercase;
    font-weight: 700;
  }
  &-date {
    color: var(--gray-10);
    font-size: var(--fSize13);
  }

  .approved-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.42rem;
    height: 1.42rem;
    border-radius: 50%;
    background-color: var(--green-0);
    color: var(--white-0);
  }
}
