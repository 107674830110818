.AdvanceStatusLine {
  display: flex;
  align-items: center;
  gap: 0 0.85rem;

  &-sent {
    color: var(--tangerine-0);
    font-size: var(--fSize17);
    text-transform: uppercase;
    font-weight: 700;
  }
  &-paid {
    color: var(--green-0);
    font-size: var(--fSize17);
    text-transform: uppercase;
    font-weight: 700;
  }
  &-date {
    color: var(--gray-10);
    font-size: var(--fSize13);
  }

  .paid-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.42rem;
    height: 1.42rem;
    border-radius: 50%;
    background-color: var(--green-0);
    color: var(--white-0);
  }
}

.AdvanceStatusLine-markMenu {
  .body {
    min-width: 97px;
  }
  .Button {
    transition: var(--transition);
    &:hover {
      background-color: var(--gray-40);
    }
  }
}
