.LocationsTableFilter {
  box-shadow: var(--dark-shadow);
  border-radius: 5px;
  background: var(--white-0);
  padding: 1.71rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 340px;

  &-label {
    font-size: var(--fSize10);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.01em;
  }

  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;

    &-col-2 {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr 1fr;
      grid-auto-rows: 1fr 1fr;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: 1fr;
      gap: 0 3px;
      grid-template-areas: ". .";
      align-content: center;
    }

    .InputField,
    .TagsField {
      margin-bottom: 0;
    }
  }

  &-button {
    width: 100%;
    margin-top: 8px;
  }
}
