.AccountProfile {
  &-section {
    padding: 1.42rem 1.71rem;
  }

  &-group {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr 1fr;
    grid-auto-rows: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0 8px;
    justify-content: center;
    align-content: center;

    &-triple {
      @extend .AccountProfile-group;
      grid-template-areas: ". . .";
      grid-template-columns: 1fr 1fr 1fr;
    }

    &-triple-address {
      @extend .AccountProfile-group;
      grid-template-columns: 5fr 1fr 2fr;
      grid-template-areas: ". . .";
    }

    &-double {
      @extend .AccountProfile-group;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: ". .";
    }

    &-double-address {
      @extend .AccountProfile-group;
      grid-template-columns: 3fr 1fr;
      grid-template-areas: ". .";
    }

    &-double-close {
      @extend .AccountProfile-group;
      grid-template-areas: ". . .";
      grid-template-columns: 1fr 1fr 2rem;
    }
  }
}