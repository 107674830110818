.InvoicePreviewOverlay {
  position: relative;
  height: 100%;
  .PdfViewer {
    padding: 2.85rem 0;

    &-page {
      width: 595px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
