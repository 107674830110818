.EstimateExportBuilder {
  overflow-y: auto;
  &-twoColumn {
    display: flex;
    justify-content: space-between;
    gap: 0 4.57rem;
    & > div {
      width: 50%;
    }
  }
}
