@import "src/assets/styles/placeholders/label";

.PeopleDetailsGeneralInfo {
   display: flex;
   box-shadow: var(--bottom-line);

   &-empty {
      color: var(--gray-20);
      margin-left: 1rem;
      font-size: var(--fSize14);
      text-transform: none;
      font-weight: normal;
   }

   &-section {
      text-align: left;
      width: 50%;
      margin: 1.15rem 0;

      &:nth-of-type(2) {
         padding-left: 1.15rem;
         border-left: 1px solid var(--gray-30);
      }
   }

   &-group {
      &:nth-of-type(1) {
         margin-bottom: 1.15rem;
      }

      &-wrapper {
         display: flex;
				 align-items: center;
         margin-bottom: 0.25rem;
      }
   }

   &-label {
      @extend %label;
      width: 25%;
      display: flex;
      align-items: flex-start;
      margin-bottom: 0;

      &.empty {
         align-items: center;
      }
   }

   &-value {
      width: 75%;
      line-height: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 0.25rem 0.5rem;

      &.empty {
         color: var(--gray-20);
      }

      .tag {
         margin-right: 0.25rem;
         margin-bottom: 0.25rem;
      }
   }

   &-title {
			font-family: var(--helvetica700);
      text-transform: uppercase;
      margin-bottom: 0.57rem;
   }
}