:root {
  --black-0: #000000; // Black 00
  --black-10: #221f20; // VS Black

  --white-0: #ffffff; // White
  --white-10: rgba(250, 250, 252, 0.39); // Off White
  --white-20: #e8e8eb; // tag-link-tertiary, tag-link-location
  --white-30: #ffffffcc; // Large Button/Light
  --white-40: #fafafc; // white off
  --white-50: #fafafd;

  --gray-0: #4c4c4d; // Very Dark Gray
  --gray-10: #7e7e80; // Dark Gray
  --gray-20: #bdbdbf; // Gray
  --gray-30: #d4d4d6; // Light Gray
  --gray-40: #ededf0; // Very Light Gray
  --gray-50: rgba(166, 166, 166, 0.4); // Pipeline/Gray
  --gray-60: #9d9d9e; // tag-link-primary, tag-link-company
  --gray-70: #d7d7d8; // tag-link-secondary, tag-link-contact
  --gray-80: #393939;
  --gray-90: rgba(237, 237, 240, 0.8);
  --gray-100: #e6e6e6;
  --gray-110: #a6a6a6; // Pipeline/Gray (Bidding)
  --gray-120: #f4fbff; // Other/Cell Highlight

  --grey-0: #909090; // Dept=Accounting

  --yellow-10: #fbf1ba; //
  --yellow-0: #f2cd00; // Pipeline/Yellow (Bill)

  --blue-0: #6699ff; // Department/Blue (Equipment)
  --blue-10: #66ccff; // Pipeline/Blue (Wrap)
  --blue-20: #0949cc;
  --blue-30: #f4fbff; //Other/Cell Highlight
  --blue-40: #294abf;
  --blue-50: #2684ff;

  --green-0: #029457; // Other/Confirmed
  --green-10: #00c875; // Pipeline/Green (Done)
  --green-20: #09a967;
  --green-30: #029457;
  --orange-0: #ff9933; // Department/Orange (Production)
  --purple-0: #9999cc; // Department/Purple (Post Production)
  --red-0: #d2232a; // Red
  --red-10: #eb272f; // Red light
  --red-20: #e2445c; //Other/Red (Warning)
  --tangerine-0: #d48c00; // Other/Pending
}
