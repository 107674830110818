.ManageFieldsList {
  .hidden {
    visibility: hidden;
  }

  &-error {
    text-align: center;
    font-size: 2em;
  }

  .content {
    padding: 1.14rem 1.71rem;
    max-height: calc(100vh - 80px);
    overflow: auto;
  }

  &-title {
    text-transform: uppercase;
    margin-bottom: 1.14rem;
  }

  &-list {
    padding-bottom: 1.14rem;
    margin-bottom: 1.14rem;
    box-shadow: var(--bottom-line);

    &-item {
      padding: 0.28rem 0;
      display: flex;
      gap: 0.57rem;

      .title {
        flex-grow: 1;
      }

      &:hover {
        .ManageFieldsList-list-btn {
          opacity: 1;
          color: var(--gray-10);
        }
      }
    }

    &-btn {
      transition: var(--transition);
      padding: 0.25rem;
      opacity: 0;

      &.icon-IconEdit,
      &.icon-IconClose {
        font-size: var(--fSize12);
      }
    }
  }

  &-add {
    width: 100%;
  }
}

.ManageField-delete-window {
  .title {
    color: var(--red-0);
  }

  .ManageFields {
    &-confirmWindow {
      &-body {
        margin-bottom: 2.85rem;
        text-align: center;
      }

      &-warning {
        font-family: var(--helvetica700);
        margin-top: 1.14rem;
      }
    }
  }
}
