.CrewCellRate {
  & > .InputCell-text {
    white-space: nowrap;
  }

  &.default {
    & > .InputCell-text {
      color: var(--gray-10);
    }
  }
}
