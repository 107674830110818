@import "src/assets/styles/placeholders/label";

.LocationForm {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  overflow: hidden;

  &-header {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 1.31rem 1.71rem;
    box-shadow: var(--bottom-line);
  }

  &-body {
    overflow-y: auto;
    padding-bottom: 1.71rem;
  }

  &-button {
    &-remove {
      color: var(--gray-20);
      font-size: var(--fSize12);
    }

    &-cancel {
      margin-left: 1.14rem;
      min-width: 9.14rem;
      color: var(--gray-20);
    }

    &-save {
      margin-left: 1.14rem;
      min-width: 9.14rem;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.85rem 1.41rem;
    box-shadow: var(--top-line);
    bottom: 0;
    width: 100%;
  }

  &-upload {
    width: 100%;
    height: 100%;
    max-height: 270px;
    min-height: 200px;
    max-width: 482px;
    margin: 2.85rem auto;
    position: relative;

    &-button {
      top: 0.85rem;
      right: 0.85rem;
      background: var(--white-0);
      color: var(--black-0);
      position: absolute;
      border: 1px solid var(--gray-30);
    }
  }

  .Upload-btn {
    top: 0.85rem;
    right: 0.85rem;
    background: var(--white-0);
    color: var(--black-0);
    position: absolute;
    border: 1px solid var(--gray-30);
  }

  &-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: var(--white-10);
    border: 1px solid var(--gray-30);

    &-contain {
      object-fit: contain;
    }
  }

  &-section {
    display: flex;
    align-items: flex-start;
    padding: 0 1.71rem;
    margin-bottom: 0.5rem;
    gap: 0 8px;

    .LocationForm-radio-group {
      width: 20%;
      min-width: 155px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1.14rem;
      justify-content: space-between;
    }

    .InputField-rate {
      width: 29%;
    }

    .InputField-name {
      width: 50%;
    }

    &-delete {
      align-items: center;
      justify-content: space-between;
    }
  }

  &-notes {
    margin-bottom: 2.85rem;
  }

  &-delete-list {
    margin-top: 1.71rem;
    text-align: left;

    &-number {
      width: 50px;
      display: inline-block;
    }
    li {
      margin-bottom: 0.57rem;
    }
  }

  &-confirmModal {
    &-body {
      margin-bottom: 2.85rem;
      text-align: center;
    }

    &-warning {
      color: var(--red-0);
      margin-top: 1.14rem;
    }
  }
}
