.Categorized {
  .ModalPopUp-container {
    max-width: 1400px !important;
  }

  .ModalPopUp-content {
    background-color: transparent;
    box-shadow: none;

    display: grid;
    // grid-template-columns: var(--modal-width-528) 620px;
    grid-template-columns: var(--modal-width-528) 830px;
    overflow-x: auto;
    align-items: center;
    gap: 40px;
  }

  .FormWrap {
    background-color: var(--white-0);
    border-radius: 5px;

    .amountCurrency {
      display: grid;
      grid-template-columns: auto 148px;
      gap: 16px;
    }
  }
}
