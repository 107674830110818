.StatusCell {
  .empty {
    opacity: 0;

    &:hover {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }
}

.StatusCellDropdown {
  .DropdownMenu-body {
    padding: 4px;
    border-radius: 5px;
    margin-top: 6px;
    overflow: visible;

    &::before {
      content: "";
      position: absolute;
      top: -2px;
      left: 50%;
      width: 10px;
      height: 10px;
      display: block;
      background-color: var(--white-0);
      transform: rotate(45deg) translateX(-50%);
      z-index: -1;
    }

    &.inverse {
      margin-top: -6px;
      &::before {
        top: auto;
        bottom: -8px;
      }
    }

    & > .TextComponent {
      height: 48px;
    }
  }
}
