.OverviewJobsPipeline {
  margin-bottom: 2rem;
  user-select: none;

  &>.loading {
    font-size: 20px;
    padding: 0 var(--content-padding);
  }

  &-text {
    padding: var(--content-padding) var(--content-padding) 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    &-title {
      font-family: var(--helvetica700);
      font-size: var(--fSize20);
      text-transform: uppercase;
    }

    &-icon {
      margin-left: 0.25rem;
      font-size: 30px;
      transition: transform 0.15s ease;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  &-columns {
    display: flex;
    grid-gap: 0.5rem;
    overflow: hidden;
    padding: 0 var(--content-padding);
  }

  &-divider {
    position: relative;
    border-top: 1px solid var(--gray-30);
    border-bottom: 1px solid var(--gray-30);
    height: 12px;
    cursor: row-resize;

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 24px;
      height: 1px;
      background-color: var(--gray-30);
      top: 3px;
    }

    &::after {
      top: 6px !important;
    }

    &>.hiddenElements {
      cursor: default;
      position: absolute;
      right: 8.2rem;
      top: -4px;
      padding: 2px 8px;
      border-radius: 100px;
      border: 1px solid var(--gray-40);
      color: var(--gray-10);
      font-family: var(--helvetica500);
      font-size: var(--fSize10);
      text-transform: uppercase;
      background-color: var(--white-0);
      z-index: 1;
    }
  }
}