.tag {
  border: 1px solid var(--gray-40);
  font-family: var(--helvetica500);
  font-size: var(--fSize10);
  color: var(--black-0);
  background: var(--white-10);
  width: max-content;
  border-radius: 2px;
  padding: 4px 6px;
  text-transform: uppercase;

  &__md {
    padding: 8px 10px;
  }
}