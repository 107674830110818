.OnboardingStep {
  padding: 56px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-header {
    background-color: var(--black-0);
    margin-bottom: 40px;
    padding: 24px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > .icon:nth-child(2) {
      margin: 0 32px;
    }

    & > .title {
      margin-left: 16px;
      color: var(--white-0);
      text-transform: uppercase;
      font-family: var(--helvetica700);
    }
  }

  &-body {
    font-family: var(--helvetica300);
    font-size: var(--fSize20);
    text-align: center;
    color: var(--gray-0);
    padding: 0 8px;
    margin-bottom: 40px;
  }

  &-changeStepBtn {
    width: 240px;
    margin-bottom: 24px;
  }

  &-stepMarkers {
    display: flex;
    align-items: center;
    gap: 4px;

    & > .dot {
      font-size: 48px;
      line-height: 0;
      color: var(--gray-60);
      &:active {
        transform: none;
      }

      &.active {
        color: var(--black-0);
      }
    }
  }
}
