.ActionsCell {
  .dots {
    height: 100%;
    width: 100%;

    color: var(--gray-30);

    &:hover {
      color: var(--black-0);
    }
  }
}
