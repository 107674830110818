.TrackLineInput {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-width: 100%;
  border: 2px solid var(--blue-40);
  border-radius: 1px;

  &-input {
    outline: none;
    padding: 0 12px;
    border: none;
    height: 100%;
    width: 100%;
    background-color: var(--blue-30);
    font-family: var(--helvetica400);
    line-height: 1.3;
    resize: none;
  }

  &.withSelect {
    display: flex;
    align-items: center;

    .Select {
      margin-bottom: 0;
      border: none;
      border-left: 1px solid var(--gray-20);
      height: 100%;
      width: 100px;

      .Select-icon-container {
        height: 100%;
      }

      .Select-input {
        border: none;
        padding: 0 8px;
        min-height: 100%;
        min-width: 70px;
      }

      .Select-icon {
        right: 0;
      }
    }
  }
}
