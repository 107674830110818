.SearchJob {
  position: relative;

  &>.icon {
    position: absolute;
    left: 10px;
    top: 4px;
    z-index: 1;

    &::before {
      font-size: var(--fSize26);
    }
  }

  .InputField-input {
    padding-left: 2.5rem;
  }

  &-list {
    border: 1px solid var(--gray-30);
    border-radius: 0.21rem;
    padding-top: 0.28rem;
    position: absolute;
    max-height: 13.5rem;
    width: 100%;
    left: 0;
    top: 100%;
    z-index: 1;
    margin-top: 0.1rem;
    background-color: var(--white-0);
    box-shadow: var(--light-shadow);

    overflow-y: auto;
    max-height: 10rem;
    padding-left: 1.14rem;
    border-bottom: 1px solid var(--gray-30);
  }

  &-listItem {
    color: var(--black-10);
    font-family: var(--helvetica400);
    font-size: var(--fSize15);
    padding: 0.57rem 0;
    cursor: pointer;
  }

  &-button {
    color: var(--gray-10);
    padding: 0.8rem 1.14rem;
  }
}