.DropboxAuthorize {
  height: calc(100% - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &-text {
    font-size: var(--fSize20);
    font-weight: 300;
  }
  &-link {
    font-weight: 700;
    text-decoration: underline;
    margin-top: 2rem;
  }
}
