.CrewHeader {
  min-height: var(--heightJobDetailsInnerPageHeader);
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: var(--white-0);
  box-shadow: var(--bottom-line);
  padding: 0 var(--32px);

  display: flex;
  align-items: center;

  & > .title {
    flex-grow: 1;
  }

  & > .addGroupBtn {
    margin: 0 var(--8px) 0 var(--24px);
    &:hover,
    &:focus {
      & > .icon {
        color: var(--white-0);

        &::before {
          color: var(--white-0);
        }
      }
    }
  }

  & > .moveToBtn {
    margin: 0 var(--8px) 0 var(--24px);
  }

  & > .closeSecondaryHeaderBtn {
    margin-left: var(--24px);
  }
}
