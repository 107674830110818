.UserUploadFile {
  --imageSize: 120px;
  position: relative;
  width: var(--imageSize);
  margin: 0 auto 6rem;

  & > img {
    width: var(--imageSize);
    height: var(--imageSize);
    object-fit: cover;
    border-radius: 50%;
  }

  & > .Button {
    border: 1px solid var(--gray-30);
    margin-top: 0.5rem;
    white-space: nowrap;
    top: auto;
    left: 0;
    right: 0;
    height: 2.29rem;
    &:hover,
    &:active {
      background: var(--gray-40);
      border-color: var(--gray-40);
    }
  }
}
