.EstimateRowINput {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  overflow: hidden;

  position: relative;

  & > div {
    cursor: pointer;
  }

  & > .TextComponent {
    padding: 0 10px;
    width: 100%;
    text-align: right;
    cursor: pointer;
  }

  &-input {
    position: absolute;
    top: 0;
    left: 0;

    padding: 0 10px;
    width: 100%;
    height: 100%;
    border: none;
    text-align: right;
    outline: none;
    background-color: var(--gray-120);
  }

  &.inputStatic {
    .EstimateRowINput-input {
      position: static;
    }
  }
}
