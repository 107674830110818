.ExpenseRowName {
  height: 100%;

  &-sortIconWrap {
    padding-left: 32px;
    display: flex;
    align-items: center;
    height: 100%;
    background-color: var(--white-0);
  }

  &-sortIcon {
    width: 32px;
    opacity: 0;
  }

  &:hover {
    .ExpenseRowName-sortIcon {
      opacity: 1;
    }
  }

  &-name {
    border-bottom: 1px solid var(--gray-40);
    border-left: 1px solid var(--gray-40);
    padding-left: 0 !important;
    // padding-left: 12px;
  }
}
