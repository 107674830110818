$events: var(--black-0);
$holds: var(--yellow-10);
$tasks: var(--gray-40);

.JobScheduler {
  width: 100%;
  height: 100%;
  position: relative;

  &.week {
    .dhx_scheduler_week {
      .dhx_cal_data {
        border-left: none;
        border-right: none;
        height: calc(100vh - 335px) !important;
      }
    }
  }

  &-data {
    width: 100%;
    height: 100%;
    padding-bottom: 40px;
    //  padding-right: 4px;
    &.hide {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
    }
  }

  .dhx_cal_tab {
    display: none;
  }

  &-loader {
    // height: calc(100vh - 143px);
    // width: 100vw;
  }

  &-calendar {
    width: 100%;
    height: 100%;
    max-width: calc(100% - 64px);
    margin: 0 auto;

    .dhx_cal_data {
      border-top: none;
      border-left: 1px solid var(--gray-40);
      border-right: 1px solid var(--gray-40);
    }

    &.dhx_cal_container {
      font-family: var(--helvetica400);
    }
    .dhx_cal_container .dhx_multi_day {
      position: relative;
      &:before {
        position: absolute;
        left: 50px;
        height: 100%;
        border-left: 1px solid gray;
      }
    }
    .is-weekend-day {
      .dhx_month_head,
      .dhx_month_body {
        background: var(--white-10);
      }
    }

    .dhx_cal_scale_placeholder {
      box-shadow: none;
    }

    .dhx_multi_day_icon_small,
    .dhx_multi_day_icon {
      display: none;
    }

    .dhx_cal_event_line.dhx_cal_event_line_start.dhx_cal_event_line_end.dhx_cal_event_drag {
      background-color: $events;
    }

    &.dhx_scheduler_month {
      border-right: 1px solid var(--gray-40);
    }

    .dhx_agenda_area div[role="row"] {
      background: none;
      border-bottom: 1px solid var(--gray-40);
    }
    &.dhx_scheduler_agenda {
      opacity: 0;
      z-index: -2;
    }
    .dhx_cal_data div.dhx_scale_hour {
      color: var(--black-0);
      line-height: inherit;
    }
    .week-hours-scale {
      font-family: var(--helvetica500);
      font-size: var(--fSize10);
      letter-spacing: 0.01em;
      display: flex;
    }
    .dhx_scale_holder {
      border-right: 1px solid var(--gray-40);
    }
    .dhx_scale_holder_now {
      background-color: var(--white-10);
      border-right: 1px solid var(--gray-40);
    }
    .dhx_cal_event.dhx_cal_select_menu,
    .dhx_cal_event.dhx_cal_editor {
      display: none;
    }
    .dhx_cal_event {
      border-radius: 4px;
      .dhx_title {
        border-radius: 4px 4px 0 0;
      }
      .dhx_body {
        border-radius: 0 0 4px 4px;
      }
      .dhx_body,
      .dhx_title,
      .dhx_footer,
      .dhx_header {
        background-color: $events;
      }
      &.events {
        .dhx_body,
        .dhx_title,
        .dhx_footer,
        .dhx_header {
          background-color: $events;
        }
      }
      &.holds {
        .dhx_body,
        .dhx_title,
        .dhx_footer,
        .dhx_header {
          background-color: $holds;
          color: var(--black-0);
        }
      }
      &.tasks {
        .dhx_body,
        .dhx_title,
        .dhx_footer,
        .dhx_header {
          background-color: $tasks;
          color: var(--black-0);
        }
      }
    }

    .dhx_cal_event .dhx_event_resize.dhx_footer {
      display: block;
      background-color: transparent;
      margin-top: -5px;
      background-image: none;

      &:after {
        content: "";
        height: 1px;
        width: 42px;
        background-color: var(--white-10);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 4px;
      }
    }
    .dhx_event_icon.icon_details {
      display: none;
    }
    .dhx_in_move.dhx_cal_event_drag {
      background-color: var(--gray-30);
    }
    .dhx_cal_data div.dhx_cal_event_clear {
      color: $events;
      overflow: visible;
      .event-bar-text {
        display: none;
      }
      &.events {
        .event-bar-time-status {
          background-color: $events;
        }
      }
      &.holds {
        .event-bar-time-status {
          background-color: $holds;
        }
      }
      &.tasks {
        .event-bar-time-status {
          background-color: $tasks;
        }
      }
    }
    .event-weekBar-text {
      font-size: var(--fSize10);
      font-weight: 500;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: var(--gray-20);
    }

    .event-bar-time {
      font-size: var(--fSize10);
      font-weight: 500;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 0 4px;
      overflow: visible;
      &-status {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: flex;
      }
      &-time {
        color: var(--gray-10);
      }
      &-text {
        color: var(--black-0);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .dhx_cal_data {
      .dhx_now {
        .dhx_month_head {
          background-color: unset;
          font-weight: 700;
          text-decoration: underline;
        }
        .dhx_month_body {
          background-color: unset;
        }
      }
    }
    .dhx_cal_header {
      border-left: 1px solid var(--gray-40);
      border-right: 1px solid var(--gray-40);
    }
    .dhx_cal_header .dhx_scale_bar {
      border: 1px solid var(--gray-40);
      border-bottom: 1px solid var(--gray-40);
      text-align: left;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--black-0);
      padding: 0;
      .month-scale-bar {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 1px 0 1px 8px;
      }
      .week-scale-bar {
        font-family: var(--helvetica700);
        display: flex;
        width: 100%;
        height: 100%;
        padding: 1px 0 1px 8px;
        &.today {
          background-color: var(--white-10);
        }
      }
    }
    .dhx_cal_data table * {
      position: relative;
    }
    .dhx_month_head {
      border-right: 1px solid var(--gray-40);
      text-align: left;
      font-size: var(--fSize17);
      color: var(--black-0);
      padding: 0 0.5rem;
      font-weight: 400;
      font-family: var(--helvetica400);
    }
    .dhx_cal_event_line {
      border-radius: 4px;
      padding: 0.1rem 0.55rem;
      height: auto;

      font-size: var(--fSize10);
      text-transform: uppercase;
      letter-spacing: 0.01em;

      &.events {
        color: var(--white-0);
        background-color: $events;
      }
      &.holds {
        background-color: $holds;
        color: var(--black-0);
      }
      &.tasks {
        background-color: $tasks;
        color: var(--black-0);
      }
    }
    .dhx_after .dhx_month_head,
    .dhx_before .dhx_month_head {
      color: var(--gray-10);
    }
    .dhx_month_body {
      border-right: 1px solid var(--gray-40);
      border-bottom: 1px solid var(--gray-40);
    }

    // Navigation styles
    .dhx_cal_navline {
      padding: 0;
      .dhx_cal_navbar_row {
        padding: 0;
        &.dhx_cal_navbar_button_group {
          padding: 0;
        }
      }
      .dhx_cal_navbar_rows_container {
        align-items: flex-start;
      }
    }
  }

  &-loading {
    position: absolute;
    color: var(--black-10);
    background-color: rgba(0, 0, 0, 0.001);
    z-index: 1;
  }

  &-toolbarRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 2.28rem;
    padding-right: 2.28rem;
    &.ActionsRow {
      box-shadow: var(--bottom-line);
    }
    &.ControlRow {
      padding-top: 1.71rem;
      padding-bottom: 1.14rem;
      height: 72px;
    }
  }

  .Locked {
    background-color: var(--gray-40);
    padding-top: 0.85rem;
    padding-bottom: 0.85rem;
    &Warning {
      display: flex;
      align-items: center;
      gap: 0 12px;
      &-text {
        color: var(--red-0);
        font-size: var(--fSize13);
        font-weight: 700;
        letter-spacing: 0.02em;
        text-transform: uppercase;
      }
    }
    &Advice {
      color: var(--gray-0);
    }
  }

  &-title {
    font-family: var(--helvetica700);
    grid-area: title;
    font-size: var(--fSize20);
    text-transform: uppercase;
    align-items: center;
    display: flex;
  }

  .JobSchedulerFilters {
    position: relative;

    .AppTooltips {
      transform: translate(-77%, 12%);
      z-index: 5;
    }
  }

  &-filter {
    grid-area: filter;
    display: flex;
    justify-content: flex-end;
    gap: 0 8px;

    .Checkbox-label {
      max-height: 32px;
      border-radius: 2px;
    }

    .Checkbox {
      &:nth-of-type(1) {
        .Checkbox-input:checked ~ .Checkbox-label,
        .Checkbox-input:checked ~ .Checkbox-label:before {
          background-color: $events;
          color: var(--white-0);
        }
        .Checkbox-input:checked ~ .Checkbox-label:before {
          background-color: var(--gray-40);
        }
      }
      &:nth-of-type(2) {
        .Checkbox-input:checked ~ .Checkbox-label,
        .Checkbox-input:checked ~ .Checkbox-label:before {
          background-color: $holds;
        }
        .Checkbox-input:checked ~ .Checkbox-label:before {
          background-color: var(--gray-40);
        }
      }
      &:nth-of-type(3) {
        .Checkbox-input:checked ~ .Checkbox-label,
        .Checkbox-input:checked ~ .Checkbox-label:before {
          background-color: $tasks;
        }
      }
    }
  }

  &-controls {
    grid-area: nav;
    display: flex;
  }
  &-nav {
    display: flex;
  }

  &-views {
    display: flex;
    margin-left: 1.71rem;
    &-button {
      text-transform: uppercase;
      font-weight: 500;
      font-size: var(--fSize10);
      padding: 0.24rem 0.85rem;
      border: 1px solid var(--gray-40);
      transition: var(--transition);
      &:nth-of-type(1) {
        border-radius: 2px 0 0 2px;
        border-right: none;
      }
      &:nth-of-type(3) {
        border-radius: 0 2px 2px 0;
        border-left: 0;
      }
      &:hover {
        background-color: var(--gray-40);
      }
      &.active {
        background-color: var(--gray-40);
      }
    }
  }

  .CheckBoxGroupe-label {
    color: var(--gray-10);
    font-weight: 500;
    font-size: var(--fSize10);
    text-transform: uppercase;
    display: inline-block;
    width: auto;
    min-width: 40px;
  }
  &-filter-label {
    color: var(--gray-10);
    font-weight: 500;
    font-size: var(--fSize10);
    text-transform: uppercase;
    min-width: 40px;
  }

  &-nav-today {
    max-height: 32px;
  }
  &-nav-button-prev {
    border-radius: 3px;
    border: 1px solid var(--gray-40);
    width: 36px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../../../assets/svg/arrow-left.svg");
    margin-right: 0.5rem;
    display: flex;
  }

  &-nav-button-next {
    border-radius: 3px;
    border: 1px solid var(--gray-40);
    width: 36px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../../../assets/svg/arrow-right.svg");
    margin-left: 0.5rem;
    display: flex;
  }

  .DepartmentList-item {
    height: 1.14rem;
    width: 1.14rem;
    font-size: var(--fSize8);
    align-items: unset;
  }
}

.dhtmlXTooltip {
  &.tooltip {
    background: var(--black-0);
    color: var(--white-0);
    font-size: var(--fSize13);
    padding: 0.15rem 0.75rem;
    border-radius: 3px;
  }
}
