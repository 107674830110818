.RowActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--8px);
  height: 100%;
  width: 100%;

  .Tooltip {
    cursor: pointer;

    &:hover {
      .icon {
        color: var(--black-10);
      }

      .linkIcon {
        path {
          fill: var(--black-10);
        }
      }
    }
  }
}
