.NewCalendarFromTemplate {
  &-label {
    text-align: center;
    margin-bottom: 1.14rem;
    letter-spacing: 0.0025em;
  }

  .Select,
  .InputField {
    margin-bottom: 2.28rem;
  }
}
