.BudgetEstimateForm {
  &-listWrap {
    columns: 2;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &-listItem {
    display: grid;
    grid-template-columns: 56px 1fr;
    padding-bottom: 4px;
  }

  &-form {
    .label {
      text-align: center;
      margin-bottom: 1.14rem;
    }
  }
}
