.EstimateRowCost {
  & > .EstimateRowINput {
    .TextComponent {
      &:first-letter {
        color: transparent;
      }
    }

    &.placeholder {
      .TextComponent {
        &:first-letter {
          color: inherit;
        }
      }
    }
  }
}
