.CrewTableRowInactive {
  > td {
    height: 32px;
    background-color: var(--white-40);
    border-bottom: 1px solid var(--gray-100);
  }

  &-text {
    padding-left: 42px;
    position: sticky;
    left: 0;
    width: calc(100vw - 20px);
  }
}
