.AdvanceModalFormList {
  &-header {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white-10);
    box-shadow: var(--bottom-line);
  }

  &-body {
    padding: 1.71rem;
  }

  &-list {
    margin-bottom: 1.14rem;
  }

  &-listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1.14rem;
    box-shadow: var(--bottom-line);
    min-height: 40px;
    &:hover {
      .AdvanceModalFormList-listButton {
        display: unset;
      }
    }
  }

  &-listButton {
    display: none;
    &:nth-of-type(2) {
      margin-left: 0.71rem;
    }
  }
}
