.Popover {
  position: fixed;
  z-index: 9999;
  inset: 0;

  &-backdrop {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &-body {
    position: absolute;
    overflow: hidden auto;
    width: fit-content;
    height: fit-content;
    max-width: calc(100% - 32px);
    max-height: calc(100% - 32px);
    outline: 0;

    border: 1px solid var(--gray-30);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background-color: var(--white-0);
    opacity: 1;
  }
}
