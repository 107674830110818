.HeaderFees {
  background-color: var(--gray-40);
  height: 56px;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 1;

  &-innerWrap {
    display: flex;
    align-items: center;
    gap: 8px;
    position: sticky;
    left: 32px;

    & > .Tooltip {
      display: flex;
      align-items: center;

      .Tooltip-text {
        max-width: 256px;
      }
    }
  }
}
