.CallSheetMsgWrap {
  background-color: var(--white-0);
  max-width: 1184px;
  margin: 0 auto var(--8px);

  &-header {
    padding: 32px;
    box-shadow: var(--bottom-line);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    & > .title {
      text-transform: uppercase;
      color: var(--black-10);
      font-size: var(--fSize17);
      font-family: var(--helvetica700);
    }

    & > .btn {
      font-size: var(--fSize13);
      padding: 1.14rem 2.85rem;
      background-color: var(--green-20);
      border-radius: 0.21rem;
      color: var(--white-0);
    }

    &.notificationHeader {
      & > .iconWrap {
        background-color: #fff;
        background-color: var(--green-20);
        width: 41px;
        height: 41px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          color: var(--white-0);
        }
      }

      & > .Button {
        margin-left: auto;
      }
    }
  }
}
