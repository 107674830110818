.SelectCell {
  height: 100%;
  position: relative;

  &-showMenuBtn.Button {
    padding: 0 var(--16px);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &.blockOpenMenu {
    .SelectCell-showMenuBtn.Button {
      cursor: default;
      &:active {
        transform: none;
      }
    }
  }
}
