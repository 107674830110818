.CostValueRow {
  &-cell {
    padding: 0 12px 0 12px;
    display: flex;
    align-items: center;
    background-color: var(--white-40);
    min-height: 40px;
    border-bottom: 1px solid var(--gray-40);

    height: 100%;

    &.alignRight {
      display: flex;
      justify-content: flex-end;
    }

    &.borderRight {
      border-right: 1px solid var(--gray-40);
    }
  }

  &.firstRow {
    & > td {
      &:first-child {
        z-index: 2;
      }
    }
  }
}
