.DropdownSubmenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: var(--transition);
  position: relative;
  max-height: 32px;
  padding: 0.7rem 0.9rem;
  color: var(--black-0);
  gap: 8px;
  cursor: pointer;
  border-radius: 3px;
  width: 100%;

  hr {
    border-top: 1px solid var(--gray-40);
  }

  &:hover {
    background-color: var(--gray-40);
    & > .submenuWrap {
      display: block;
      z-index: 1;
    }
  }

  & > .submenuWrap {
    display: none;
    position: absolute;
    width: max-content;
    top: 0;
    z-index: -1;
    overflow: visible;

    & > .submenu {
      background-color: var(--white-0);
      box-shadow: var(--light-shadow);
    }

    &.right {
      left: 100%;
      // .submenu {
      //   //  margin-left: 8px;
      // }
    }

    &.left {
      left: 0;
      transform: translateX(-100%);
      // .submenu {
      //   //  margin-right: 8px;
      // }
    }
  }
}
