@import "src/components/shared/Button/Button.scss";

.TextEditor {
  .tox.tox-tinymce {
    max-height: 270px;
    border-radius: 3px;
    border: 1px solid var(--gray-30);
  }

  &.error {
    .tox.tox-tinymce {
      border-color: var(--red-0);
    }
  }

  .error-message {
    color: var(--red-0);
    padding-top: 0.25rem;
    font-size: var(--fSize13);
    text-align: left;
  }
  .tox .tox-statusbar {
    display: none;
  }
}
.tox-tinymce-aux {
  z-index: 10000 !important;
}
.tox .tox-button {
  font-family: var(--helvetica700) !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
  border-radius: 3px !important;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0 8px !important; //for icons

  transition: background-color 0.3s ease, color 0.3s ease !important;
  white-space: nowrap;
  font-size: var(--fSize12) !important;
  letter-spacing: 0.5px !important;
  padding: 0.4rem 1rem !important;

  &:disabled {
    cursor: not-allowed;
    background-color: var(--gray-70) !important;
    color: var(--black-0) !important;
  }
}

.tox .tox-dialog__footer .tox-button {
  background-color: var(--gray-0) !important;
  color: var(--white-0) !important;

  &:hover,
  &:focus {
    background-color: var(--black-10) !important;
  }
}

.tox .tox-button.tox-button--secondary {
  background-color: var(--white-0) !important;
  border: 1px solid var(--gray-30) !important;
  color: var(--black-10) !important;

  &:hover,
  &:focus,
  &:active {
    color: var(--white-0) !important;
    background-color: var(--black-10) !important;
  }
}

.tox .tox-listboxfield .tox-listbox--select:focus,
.tox .tox-textarea:focus,
.tox .tox-textfield:focus {
  border: 1px solid var(--gray-30) !important;
  box-shadow: none !important;
}

.tox .tox-form__group {
  margin-bottom: 1.14rem !important;
}

.tox .tox-dialog {
  border-radius: 3px !important;
}

.tox .tox-label,
.tox .tox-toolbar-label {
  color: var(--gray-0) !important;
  font-family: var(--helvetica400);
  margin-bottom: 0.25rem;
  font-size: var(--fSize11) !important;
  letter-spacing: 0.02em;
  text-transform: uppercase !important;
  text-align: left;
  width: 100%;
  display: flex !important;
  align-items: center;
}

.tox .tox-listboxfield .tox-listbox--select,
.tox .tox-textarea,
.tox .tox-textfield,
.tox .tox-toolbar-textfield {
  font-family: var(--helvetica400) !important;
  background-color: var(--white-0) !important;
  height: 40px !important;
  border: 1px solid var(--gray-30);
  border-radius: 3px !important;
  outline: none !important;
  position: relative;
  width: 100% !important;
  font-size: 1rem !important;
  padding: 0 0.86rem !important;
  box-sizing: border-box !important;
  resize: none !important;
}
