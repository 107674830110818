.PeopleDetailsHeader {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 1.15rem;
  box-shadow: var(--bottom-line);
  gap: 8px;
  &-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1.15rem;
  }

  &-main {
    width: 100%;

    &-subtitle {
      font-size: var(--fSize17);
      color: var(--gray-10);
    }

    &-title {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-family: var(--helvetica700);

      word-break: break-word;
      & > span {
        white-space: nowrap;
      }
    }
  }

  &-buttons {
    .Button {
      padding: var(--button-size-sm);
      min-width: 114px;
      margin-bottom: 0.5rem;
      justify-content: center;
      height: 32px;
    }
  }

  &-status {
    margin-left: 1rem;
    text-transform: uppercase;
    font-size: var(--fSize10);
    color: var(--gray-0);
    font-weight: 500;
    padding: 0.15rem 0.75rem;
    border-radius: 0.5rem;
    background: var(--gray-40);
  }
}
