.JobSections {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 7rem;

  &-form {
    max-width: 480px;
    margin: 0 auto;

    & > .title {
      font-family: var(--helvetica700);
      font-size: var(--fSize26);
      text-align: center;
      text-transform: uppercase;
      color: var(--black-10);
    }

    & > .subTitle {
      font-size: var(--fSize20);
      font-family: var(--helvetica300);
      text-align: center;
      color: var(--black-10);
      margin: 1.142rem auto 3.4rem;
    }

    & > .loaderWrap {
      position: relative;
      min-height: 50px;
    }

    & > .sectionCheckboxes {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: repeat(3, 1fr);
      max-width: 480px;
      margin: 0 auto;

      .Checkbox {
        &-label {
          width: 100%;
          white-space: nowrap;
        }
      }
    }

    & > .Button {
      margin: 48px auto 24px;
      width: 100%;
    }

    & > .info {
      font-size: var(--fSize13);
      font-family: var(--helvetica400);
      color: var(--gray-10);
      text-align: center;
    }
  }
}
