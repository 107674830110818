.InvoiceEmptyState {
  height: 100%;

  &-form {
    min-width: 320px;
  }

  .Select {
    margin-bottom: 2.82rem;
  }
}
