.SchedulerDashboard {
  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;
  padding: var(--content-padding);

  &-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 3;
  }

  &-calendar {
    overflow: visible;
    width: 100%;
    min-height: 720px;
    margin-bottom: 5%;
    //height: 100%;

    .SchedulerEventBar {
      display: flex;

      &-pipeline {
        margin-right: 0.5rem;

        .DepartmentList {
          gap: 0.25rem;
        }
      }

      &-text {
        font-size: var(--fSize10);
        text-transform: uppercase;
        color: var(--black-0);
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .is-weekend-day {
      .dhx_month_head,
      .dhx_month_body {
        background: var(--white-10);
      }
    }

    .dhx_cal_scale_placeholder {
      box-shadow: none;
    }

    .dhx_cal_data {
      border-top: 1px solid var(--gray-40);
      border-left: 1px solid var(--gray-40);

      .dhx_now {
        .dhx_month_head {
          background-color: unset;
          font-weight: 700;
          text-decoration: underline;
        }

        .dhx_month_body {
          background-color: unset;
        }
      }
      table {
        border-top: 1px solid var(--gray-40);
      }
    }

    .dhx_cal_header {
      border-left: 1px solid var(--gray-40);
      border-right: 1px solid var(--gray-40);
      z-index: 1;
    }

    .dhx_cal_header .dhx_scale_bar {
      border: 1px solid var(--gray-40);
      border-bottom: 1px solid var(--gray-40);
      text-align: left;
      padding: 0.25rem 0.5rem;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--black-0);
    }

    .dhx_cal_event_clear {
      color: var(--black-0);
    }

    .dhx_month_head {
      border-right: 1px solid var(--gray-40);
      text-align: left;
      font-size: var(--fSize17);
      color: var(--black-0);
      padding: 0 0.5rem;
      font-weight: 400;
      font-family: var(--helvetica400);
    }

    .dhx_cal_event_line {
      border-radius: 4px;
      padding: 0.1rem 0.55rem;
      height: auto;
      background-color: rgba(128, 128, 128, 0.4);
      .dhx_event_resize {
        display: none;
      }
      &.Production {
        background-color: rgba(0, 200, 117, 0.4);
      }

      &.Post {
        background-color: rgba(242, 205, 0, 0.4);
      }

      &.Equipment {
        background-color: rgba(102, 204, 255, 0.4);
      }

      &.Accounting {
        background-color: #a6a6a666;
      }

      &.Office {
        background-color: #a6a6a666;
      }

      &.Marketing {
        background-color: #a6a6a666;
      }
    }

    .dhx_after .dhx_month_head,
    .dhx_before .dhx_month_head {
      color: var(--gray-10);
    }

    .dhx_month_body {
      border-right: 1px solid var(--gray-40);
      border-bottom: 1px solid var(--gray-40);
    }

    // Navigation styles
    .dhx_cal_navline {
      padding: 0;

      .dhx_cal_navbar_row {
        padding: 0;

        &.dhx_cal_navbar_button_group {
          padding: 0;
        }
      }

      .dhx_cal_navbar_rows_container {
        align-items: flex-start;
      }
    }
  }

  &-toolbar {
    margin-bottom: 1.14rem;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: 1fr 1fr;
    gap: 0 0;
    grid-template-areas:
      "title filter"
      "nav filter";
  }

  &-title {
    font-family: var(--helvetica700);
    grid-area: title;
    font-size: var(--fSize20);
    font-weight: 700;
    text-transform: uppercase;
  }

  &-filter {
    grid-area: filter;
  }

  &-nav {
    grid-area: nav;
    display: flex;
    align-items: flex-end;
  }

  &-filter-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    gap: 0.5rem;

    .RadioField-label {
      height: 2.29rem;
    }

    .Checkbox-label {
      height: 2.29rem;
    }

    &:nth-last-of-type(1) {
      gap: 0 0.5rem;
      margin-bottom: 0;
    }
  }

  .CheckBoxGroupe-label {
    color: var(--gray-10);
    font-weight: 500;
    font-size: var(--fSize10);
    text-transform: uppercase;
    display: inline-block;
    width: auto;
    min-width: 40px;
  }

  &-filter-label {
    color: var(--gray-10);
    font-weight: 500;
    font-size: var(--fSize10);
    text-transform: uppercase;
    min-width: 40px;
  }

  &-nav-today {
    background: var(--gray-40);
    border-radius: 3px;
    font-size: 10px;
    color: var(--black-0);
    padding: 10px 12px;
    font-family: var(--helvetica700);
    text-transform: uppercase;
  }

  &-nav-button-prev {
    border-radius: 3px;
    border: 1px solid var(--gray-40);
    width: 36px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../assets/svg/arrow-left.svg");
    margin-right: 0.5rem;
    display: flex;
  }

  &-nav-button-next {
    border-radius: 3px;
    border: 1px solid var(--gray-40);
    width: 36px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../assets/svg/arrow-right.svg");
    margin-left: 0.5rem;
    display: flex;
  }

  .DepartmentList-item {
    height: 1.14rem;
    width: 1.14rem;
    font-size: var(--fSize8);
    align-items: unset;
  }

  .dhx_cal_container.dhx_scheduler_month .dhx_cal_data {
    min-height: 100%;
    overflow: visible;
  }
}

.dhtmlXTooltip {
  &.tooltip {
    background: var(--black-0);
    color: var(--white-0);
    font-size: var(--fSize13);
    padding: 0.15rem 0.75rem;
    border-radius: 3px;
  }
}
