.InputCell {
  position: relative;
  word-break: break-word;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  &.truncate {
    min-width: 100% !important;

    .InputCell-text {
      max-height: 44px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  & > * {
    padding: 6px var(--12px);
  }

  &.showPlaceholder {
    .InputCell-placeholder {
      opacity: 1;
    }
  }

  &:hover {
    .InputCell-placeholder {
      opacity: 1;
    }
  }

  &-placeholder {
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.25s;
    white-space: nowrap;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &-text {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &-input {
    position: absolute;
    top: 0;
    left: 0;

    border: none;
    resize: none;
    height: 100%;
    width: 100%;

    outline: none;
    background-color: var(--gray-120);
    font-family: var(--helvetica400);

    font-size: var(--fSize13);
    // line-height: 1.38;

    &.withBorder {
      border: 2px solid var(--blue-40);
    }
  }
}
