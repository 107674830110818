.Search {
  position: relative;
  box-shadow: var(--dark-shadow);
  z-index: 2;

  margin-top: 80px;
  max-width: 640px;
  max-height: calc(100vh - 160px);
  overflow-y: hidden;

  &-modal {
    align-items: flex-start;

    .container {
      max-width: 640px;
    }

    .content {
      padding: 0;
    }
  }

  &-control {
    display: flex;
    border-bottom: 1px solid var(--gray-40);
    background: var(--white-0);
    border-radius: 3px;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &-select {
    margin-bottom: 0;
    max-width: 144px;
    height: 56px;
    width: 100%;
    position: relative;

    .Select-icon-container {
      height: 100%;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 40px;
      width: 1px;
      background: var(--gray-40);
      z-index: 1;
    }

    .icon {
      color: var(--gray-10);
    }

    .input {
      height: 100%;
      border: none;
      border-radius: 0 3px 3px 0;
      font-size: var(--fSize15);
      color: var(--black-10);
      position: relative;
    }
  }

  &-result {
    max-height: calc(100vh - 226px);
    overflow-y: auto;
  }

  &-cancel-button {
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--fSize13);
    color: var(--gray-20);

    &:active {
      transform: translateY(-50%) scale(1);
    }
  }

  &-input {
    width: 90%;
    height: 56px;
    border-radius: 3px 0 0 3px;
    font-size: var(--fSize20);
    color: var(--black-10);
    padding-left: 4rem;
    font-style: normal;
    outline: none;
    border: none;

    &::placeholder {
      font-family: var(--helvetica300);
      color: var(--gray-10);
    }

    &-group {
      position: relative;
      width: 100%;

      &-icon-search {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: var(--gray-10);
        font-size: var(--fSize24);
      }

      &-icon-close {
        color: var(--gray-20);
      }
    }
  }

  &-chapter {
    padding: 1.14rem 0;
    background: var(--white-0);
    margin: 0 1.14rem;

    &:not(:last-child) {
      box-shadow: var(--bottom-line);
    }

    .icon {
      margin-right: 0.5rem;
    }

    &-title {
      font-weight: 700;
      font-size: var(--fSize13);
      text-transform: uppercase;
      text-align: left;
      margin-bottom: 1.14rem;
      display: flex;
      align-items: center;
    }
  }

  &-empty {
    text-align: center;
    padding: 1rem;
  }

  .Row {
    margin: 0.4rem 0.4rem 0.4rem 0;
    display: flex;
    align-items: center;
    padding: 0.5rem 0.25rem 0.5rem 0;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: var(--gray-40);
    }

    &-cell {
      flex: 0 0 33%;
      padding: 0 0.5rem;

      &.entry {
        font-family: var(--helvetica500);
        max-width: 180px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &.column {
        font-family: var(--helvetica500);
        text-transform: uppercase;
        color: var(--gray-10);
        font-size: var(--fSize10);
        max-width: 180px;
      }

      &.text {
        word-break: break-all;
        flex: 1 0 33%;
      }

      .highlight {
        font-weight: 700;
      }
    }
  }
}
