.InvoiceSend {
  .Datepicker {
    max-width: 140px;
    margin: 0 auto 1.14rem;
  }
  .Datepicker-inputWrap.wrap {
    display: flex;
    justify-content: center;
  }
}
