@import "src/assets/styles/index";

.JobArtistItem {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0 8px;
  align-items: flex-end;
  position: relative;
  grid-template-areas:
    "A A A"
    ". . ."
    ". . .";
  margin-bottom: 1.14rem;

  &-item {
    &.error {
      .label {
        color: var(--red-0);
      }
      .ReactSelect-select__control {
        border-color: var(--red-0);
      }
    }
  }
  &-error {
    position: absolute;
    color: var(--red-0);
    display: inline-block;
    padding-top: 0.25rem;
    font-size: var(--fSize13);
  }

  .label {
    @extend %label;
    width: 100%;
  }

  .TagButton {
    max-height: 40px;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1.14rem;
    grid-area: A;
    transform: none;
  }

  .ReactSelect .ReactSelect-select__dropdown-indicator {
    display: flex;
  }
}
