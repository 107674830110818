.AccountSidebar {
  min-height: 620px;
  min-width: 292px;
  padding-top: 2.85rem;
  background: var(--black-0);
  display: flex;
  flex-direction: column;

  &-header {
    color: var(--white-0);
    margin-bottom: 2.85rem;

    &-avatar {
      display: block;
      margin: 0 auto 1.42rem;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover;
      border: 1px solid var(--white-0);
    }

    &-name {
      font-family: var(--helvetica700);
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      text-align: center;
    }

    &-job {
      font-size: var(--fSize15);
      font-weight: 400;
      text-align: center;
      padding: 0 1rem;
    }

    &-stage {
      font-size: var(--fSize10);
      font-weight: 500;
      color: var(--gray-30);
      text-transform: uppercase;
      text-align: center;
      padding: 0 1rem;
    }
  }

  &-icon {
    margin-right: 1rem;

    &.icon-settings {
      display: flex;
      align-items: center;
      margin-left: 6px;

      svg path {
        fill: var(--white-10);
      }
    }
  }

  &-body {
    padding-left: 1.71rem;
  }

  &-footer {
    margin-top: auto;
    padding-left: 1.71rem;
  }

  &-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    color: var(--gray-20);
    background: transparent;
    font-weight: 600;
    font-size: var(--fSize15);
    padding: 1.57rem 1.14rem 1.57rem 0;
    transition: var(--transition);
    width: 100%;

    &:hover .AccountSidebar-icon {
      transition: color 0.3s ease-in-out;
      color: var(--white-0);

      & > svg path {
        fill: var(--white-0);
      }
    }

    &:not(.AccountSidebar-button-logout) {
      box-shadow: inset 0px -0.5px 0px #bfbfbf;

      &:after {
        position: absolute;
        font-family: "icomoon";
        content: "\e924";
        font-size: var(--fSize24);
        top: 50%;
        transform: translate(0, -50%);
        opacity: 0;
      }

      &:hover {
        &:after {
          right: 1.75rem;
          opacity: 1;
          transition: all 0.3s ease-in-out;
          animation: show-button-arrow 0.4s;
        }
      }
    }

    &.active,
    &:hover {
      color: var(--white-0);

      & .AccountSidebar-icon {
        color: var(--white-0);

        & > svg path {
          fill: var(--white-0);
        }
      }
    }
  }
}

@keyframes show-button-arrow {
  0% {
    top: 50%;
    transform: translate(-100%, -50%);
  }

  100% {
    top: 50%;
    transform: translate(0, -50%);
  }
}
