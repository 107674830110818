.TeamMemberModal {
  .content {
    padding: 0;
    min-height: 150px;
  }
}

.TeamMemberInviteModal {
  .content {
    padding: 0;
  }
}

.TeamMemberDetails {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  padding: 1.71rem;
}

.TeamMemberPending {
  padding: 1.71rem;
}