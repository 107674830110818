.ToastMessage {
  box-sizing: border-box;
  position: fixed;
  z-index: 19999;

  &.top-center {
    left: 50%;
    transform: translate(-50%, 0);
    top: 1.14rem;
  }

  &-toast {
    width: fit-content;
    color: var(--white-0);
    padding: 0.85rem 1.42rem;
    display: flex;
    align-items: center;
    border-radius: 3px;
    box-shadow: var(--dark-shadow);
    background: var(--black-10);
    margin-bottom: 1.71rem;
    z-index: 999;

    &.top-center {
      margin: 0 auto 1.71rem;
      transition: all 0.6s ease-in-out;
      animation: toast-from-top 0.7s;
    }
  }

  &-close {
    color: var(--gray-20);
    position: relative;
    font-weight: 700;
    outline: none;
    border: none;
    line-height: 1;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    margin-left: 1.21rem;

    &.icon-IconClose {
      font-size: var(--fSize12);
    }
  }
}

.top-right {
  top: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.bottom-right {
  bottom: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.top-left {
  top: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.notification {
  background: var(--white-0);
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 15px;
  padding: 30px;
  width: 300px;
  max-height: 100px;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 0 10px var(--gray-50);
  color: var(--black-0);
  opacity: 0.9;
  background-position: 15px;
  background-repeat: no-repeat;
}

.notification:hover {
  box-shadow: 0 0 12px var(--white-0);
  opacity: 1;
  cursor: pointer;
}

.ToastMessage-title {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 6px;
  width: 300px;
  height: 18px;
}

.ToastMessage-text {
  margin: 0 0 0 -1px;
  text-align: left;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--fSize14);
  font-weight: 500;
}

.ToastMessage-image {
  margin-right: 15px;
  display: flex;
  align-items: center;
}

@keyframes toast-from-top {
  from {
    transform: translateY(-100vh);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}
