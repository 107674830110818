.SenderRecipientsGroup {
  padding-bottom: 1.14rem;

  &:not(:last-child) {
    box-shadow: var(--bottom-line);
  }

  .Checkbox-label {
    font-family: var(--helvetica700);
    padding-top: 0;
    padding-bottom: 0;
  }
}
