.InvoiceTotals {
  &-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    .InputField {
      margin-bottom: 4px;
    }

    &.pl-20 {
      padding-left: 1.7rem;
    }

    &.mb-14 {
      margin-bottom: 1rem;
    }
    &.invoice-total {
      margin-bottom: 2rem;
      // max-width: 380px;
      // margin-left: auto;

      justify-content: flex-end;
      gap: 4rem;
    }
  }

  &-label {
    font-family: var(--helvetica500);
    color: var(--black-10);
  }
  .prefix .inputShadow {
    text-align: right;
  }
  .AdjustmentField {
    &.InputField {
      // max-width: 166px;
      max-width: 196px;
    }
    .InputField-input {
      text-align: right;
    }

    .inputShadow {
      white-space: nowrap;
    }
  }

  .LatePaymentFee {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0.5rem;
    &-wrap {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }

    &.disabled {
      .TextComponent {
        color: var(--gray-10);
      }
      .LatePaymentFee-value {
        z-index: -1;
      }
    }
    &-input {
      display: flex;
      align-items: center;
      width: 70%;
      gap: 0 1rem;
      .InputField {
        width: 48px;
        margin-bottom: 0;
        &-input {
          text-align: center;
        }
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
