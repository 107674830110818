.SchedulerActions {
  grid-area: actions;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0 4px;
  padding: 12px 0;

  .Button {
    max-height: 32px;
    justify-content: space-between;
  }
}

.CalendarActionsMenu {
  .DropdownMenu-body {
    overflow: visible;
    min-width: 188px;
  }
  .Button {
    justify-content: space-between;
    width: 100%;
    font-family: var(--helvetica500);
    font-weight: 500;
    max-height: 32px;
    position: relative;

    &.active {
      font-family: var(--helvetica700);
      font-weight: 700;
    }
    &:hover {
      background-color: var(--gray-40);
      & > .submenu {
        display: block;
        position: absolute;
        top: 0;
        left: -115px;
        &.CalendarManagement {
          left: -127px;
        }
      }
    }
  }

  &-openButton {
    justify-content: space-between;
    min-width: 188px;
  }

  .submenu {
    display: none;
    border: 1px solid var(--gray-30);
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), 0px 2px 8px rgb(0 0 0 / 8%);
    border-radius: 3px;
    background-color: var(--white-0);
  }
}
