.JobCalendar {
  position: relative;
}
.JobCalendar-creatingModal {
  .ModalPopUp {
    &-container {
      max-width: 528px;
    }
    &-content {
      padding: 0;
    }
  }
}
