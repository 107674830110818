.JobBudgetHeaderLayout {
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 64px;
  gap: 1rem;
  padding: 0 2.28rem;
  box-shadow: var(--bottom-line);

  &-leftSide,
  &-rightSide {
    display: flex;
    align-items: center;
  }

  &-nav {
    margin-left: 1.71rem;

    display: flex;
    z-index: 10;
  }

  &-navItem {
    padding: 0 1.14rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: var(--fSize12);
    letter-spacing: 0.5px;
    color: var(--gray-10);
    transition: var(--transition);
    &:not(:nth-of-type(1)) {
      border-left: 1px solid var(--gray-10);
    }

    .Tooltip {
      margin-right: 0;

      &-text {
        max-width: 160px;
      }
    }

    &.active,
    &:hover {
      color: var(--black-0);
    }

    &.disabled:not(:first-child) {
      color: var(--gray-10);
      cursor: default;

      &:hover {
        color: var(--gray-10);
        cursor: default;
      }
    }

    &:first-child {
      position: relative;

      .AppTooltips {
        top: 230%;
        left: 26px;
      }
    }
  }

  &-title {
    text-transform: uppercase;
  }
}
