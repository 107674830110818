.SenderRecipientHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.14rem;

  &-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 1.14rem;
  }

  .Checkbox-label {
    border: none;
    font-weight: 700;
    text-transform: uppercase;
    font-size: var(--fSize17);
    padding-left: 3rem;
  }
  .Checkbox-input:checked ~ .Checkbox-label {
    background-color: unset;
  }
}
