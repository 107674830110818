.RowDates {
  height: 100%;
  align-items: center;
  display: flex;

  &:hover,
  &.open {
    .RowDates-placeholder {
      opacity: 1;
    }
  }

  & > .Button {
    font-family: var(--helvetica500);
    font-weight: 500;
    color: var(--black-10);
  }

  &-dropDown {
    .DropdownMenu-body {
      overflow: visible;
      padding: var(--8px);
      width: 21.85rem;

      & > .DateRangePicker {
        margin-bottom: 0;

        .DateRangePicker-control {
          align-items: center;
        }

        .react-datepicker__triangle {
          display: none;
        }
      }
    }
  }

  &-placeholder {
    color: var(--gray-10);
    opacity: 0;
    transition: opacity 0.25s;
    white-space: nowrap;
  }
}
