@import "src/assets/styles/index";

.SearchField {
  width: 100%;
  margin-bottom: 1.14rem;
  &-label {
    @extend %label;
  }

  &-searchIcon {
    margin-left: 0.5rem;
  }

  &.is-error {
    .label {
      color: var(--red-0);
    }
    .ReactSelect-select__control {
      border-color: var(--red-0);
      &:hover {
        border-color: var(--red-0);
      }
    }
  }
  &-error-message {
    color: var(--red-0);
    padding-top: 0.25rem;
    font-size: var(--fSize13);
  }
}
