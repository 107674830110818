:root,
::after,
::before {
  --fSize32: 2.28rem;
  --fSize31: 2.214rem;
  --fSize30: 2.14rem;
  --fSize26: 1.85rem;
  --fSize24: 1.71rem;
  --fSize20: 1.42rem;
  --fSize18: 1.28rem;
  --fSize17: 1.21rem;
  --fSize16: 1.14rem;
  --fSize15: 1.07rem;
  --fSize14: 1rem;
  --fSize13: 0.92rem;
  --fSize12: 0.85rem;
  --fSize11: 0.78rem;
  --fSize10: 0.71rem;
  --fSize8: 0.57rem;
  --fSize6: 0.43rem;

  --helvetica300: "Helvetica300";
  --helvetica400: "Helvetica400";
  --helvetica500: "Helvetica500";
  --helvetica700: "Helvetica700";

  --transition: 0.3s;

  --content-padding: 2.28rem;

  --button-font-sm: var(--fSize10);
  --button-font-md: var(--fSize12);
  --button-font-lg: var(--fSize17);

  --button-size-sm: 0.71rem 0.85rem;
  --button-size-md: 0.92rem 1.14rem;
  --button-size-lg: 1.21rem 2.85rem;

  --top-line: inset 0px 1px 0px #e6e6e6;
  --bottom-line: inset 0px -1px 0px #e6e6e6;
  --right-line: inset -1px 0 0px #ededf0;

  --light-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 8px rgba(0, 0, 0, 0.08);
  --dark-shadow: 0 1px 6px rgba(0, 0, 0, 0.09), 0 2px 32px rgba(0, 0, 0, 0.15);

  --modal-width-sm: 334px;
  --modal-width-528: 528px;
  --modal-width-md: 744px;
  --modal-width-lg: 942px;
  --modal-width-xlg: 1184px;
  --modal-width-1230: 1230px;

  --text-max-width: 175px;

  --heightJobDetailsInnerPageHeader: 56px;

  // px to rem
  --8px: 0.571rem;
  --12px: 0.857rem;
  --16px: 1.143rem;
  --18px: 1.285rem;
  --24px: 1.714rem;
  --32px: 2.286rem;
}
