.ButtonGroup {
  max-width: fit-content;
  display: flex;
  border-radius: 2px;
  overflow: hidden;

  &.EstimateHeaderBtnGroup {
    overflow: visible;
    position: relative;
    z-index: 10;

    .AppTooltips {
      top: 150%;
      left: 70px;
    }
  }

  & > button,
  & > a,
  & > div:not(.AppTooltips) {
    background-color: var(--white-0);
    border-radius: 0;
    font-family: var(--helvetica500);
    font-weight: 500;
    font-size: var(--fSize10);
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: var(--black-10);
    padding: 0.429rem 0.857rem;
    border: 1px solid var(--gray-30);
    border-right-width: 0;

    &:hover,
    &.active {
      background-color: var(--gray-40);
    }

    &:last-child {
      border-right: 1px solid var(--gray-30);
    }
  }

  & > a {
    white-space: nowrap;
  }

  .disabled {
    border-color: var(--gray-40);
    color: var(--gray-20);
    cursor: unset;
    pointer-events: none;
    &:hover {
      background-color: unset;
    }
  }
}
