.FileNavigation {
  height: 68px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.14rem;
  background-color: var(--white-10);
  box-shadow: var(--top-line);

  & > button {
    flex-shrink: 0;
    height: 32px;
    width: 36px;
    background-color: var(--white-0);
    border-radius: 3px;
    border: 1px solid var(--gray-30);
  }

  .ReactSelect {
    min-width: 240px;
  }

  .ReactSelect-select__control {
    height: 44px;
  }
}
