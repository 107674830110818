.TeamMemberDetails {
  padding: 0 1.71rem 0;
  &.inactive {
    .ModalInfoSection:nth-child(-n + 3),
    .TeamMemberHeader * :not(.Button),
		.TeamMemberHeader-avatar {
      opacity: 0.5;
    }
  }
}
