.AddJobLinkForm {
  .SearchJob {
    & > .icon {
      top: -4px;
    }
  }
}

.LinkModal {
  .FormWrap.withScroll .FormWrap-main {
    overflow: visible;
  }
}
